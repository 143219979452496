import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { some } from 'lodash';

import AddEditCustomerGeneralAddEdit from './add-edit';
import CustomerPreview from './components/customer-preview';

class AddEditCustomerGeneral extends Component {
	onChange = change => {
		this.props.onChange(change);
	};

	onCustomBlur = fieldName => {
		this.props.onCustomBlur(fieldName);
	};

	render() {
		const {
			data,
			errorMessages,
			advancedView,
			isCanadian,
			isExpanded,
			isLoading,
			requiredFields,
			blurredField,
			savedGeneral,
			renderFailedToFetch,
			customerRequiredFields,
			customDisplayLabels,
			customerHiddenFields,
			isViewOnly,
			isPreview,
		} = this.props;

		return (
			<Fragment>
				{!advancedView && (
					<Fragment>
						{renderFailedToFetch('spc--bottom--sml', 'message message--warning fullwidth')}
						{!some(['billFirstName', 'billLastName', 'billCompany'], field => data[field]) && (
							<div className="notes notes--primary spc--bottom--med flex--primary">
								<i className="icon"></i>
								<p className="type--p3">
									{`${customDisplayLabels['firstName'] || 'First Name'}, ${customDisplayLabels['lastName'] ||
										'Last Name'} or ${customDisplayLabels['company'] || 'Company'} is required.`}
								</p>
							</div>
						)}
					</Fragment>
				)}
				{errorMessages && errorMessages.length > 0 && (
					<div className="info-panel__section">
						<div className="type--validation__wrapper">
							{errorMessages.map(error => (
								<p key={error.key} className="type--validation">
									{error.message}
								</p>
							))}
						</div>
					</div>
				)}

				{isPreview ? (
					<CustomerPreview
						customDisplayLabels={customDisplayLabels}
						data={data}
						customerHiddenFields={customerHiddenFields}
						isCanadian={isCanadian}
					/>
				) : (
					<AddEditCustomerGeneralAddEdit
						data={data}
						onChange={this.onChange}
						onCustomBlur={this.onCustomBlur}
						blurredField={blurredField}
						savedGeneral={savedGeneral}
						advancedView={advancedView}
						isCanadian={isCanadian}
						isExpanded={isExpanded}
						isLoading={isLoading}
						requiredFields={requiredFields}
						customDisplayLabels={customDisplayLabels}
						customerRequiredFields={customerRequiredFields}
						customerHiddenFields={customerHiddenFields}
						isViewOnly={isViewOnly}
						isPreview={isPreview}
						isInvalidField={this.props.isInvalidField}
					/>
				)}
			</Fragment>
		);
	}
}

AddEditCustomerGeneral.propTypes = {
	data: PropTypes.object,
	errorMessages: PropTypes.arrayOf(PropTypes.string),
	onChange: PropTypes.func,
	onCustomBlur: PropTypes.func,
	advancedView: PropTypes.bool.isRequired,
	isCanadian: PropTypes.bool,
	isExpanded: PropTypes.bool,
	isViewOnly: PropTypes.bool,
	isLoading: PropTypes.bool.isRequired,
	requiredFields: PropTypes.object,
	customDisplayLabels: PropTypes.object,
	blurredField: PropTypes.any,
	savedGeneral: PropTypes.any,
	renderFailedToFetch: PropTypes.func.isRequired,
	customerRequiredFields: PropTypes.object,
	customerDisplayLabels: PropTypes.object,
	customerHiddenFields: PropTypes.object,
	isPreview: PropTypes.bool,
	isInvalidField: PropTypes.func.isRequired,
};

export default AddEditCustomerGeneral;
