import React from 'react';
import PropTypes from 'prop-types';
import ModalComponent from 'common/components/modal/modal';
import { withRouter } from 'react-router-dom';

const SuccessModal = ({ isOpen, history }) => {
	const redirectToCustomers = () => {
		history.push({ pathname: '/customers/report' });
	};
	return (
		<ModalComponent isOpen={isOpen} onClose={redirectToCustomers}>
			<div className="modal__body">
				<div className="customer--process is-success">
					<div className="icon"></div>
				</div>
				<h4 className="type--center type--wgt--regular">All Customers have been uploaded successfully!</h4>
			</div>
		</ModalComponent>
	);
};

SuccessModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	history: PropTypes.object.isRequired,
};

export default withRouter(SuccessModal);
