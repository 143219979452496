import React, { Component, Fragment, createRef } from 'react';
import { map, keys, filter, some, get, replace, camelCase, includes, size, isEmpty } from 'lodash';
import PropTypes, { object } from 'prop-types';

class AddEditCustomerGeneralAddEdit extends Component {
	constructor(props) {
		super(props);
		this.customerNumberInput = createRef();
		this.firstNameRef = createRef();
		this.state = {
			billFirstLastCompanyRequired: false,
			expandedSections: {
				billingInformation: true,
				shippingInformation: true,
				customFields: true,
			},
		};
	}

	getFields = (isBill = false, fieldsToInclude = []) => {
		const baseFields = [
			{
				key: 'shipFirstName',
				label: 'First Name',
			},
			{
				key: 'shipLastName',
				label: 'Last Name',
			},
			{
				key: 'shipCompany',
				label: 'Company',
				fullwidth: true,
			},
			{
				key: 'shipStreet',
				label: 'Address 1',
				fullwidth: true,
			},
			{
				key: 'shipStreet2',
				label: 'Address 2',
				fullwidth: true,
			},
			{
				key: 'shipCity',
				label: 'City',
				fullwidth: true,
			},
			{
				key: 'shipState',
				label: this.stateLabel,
			},
			{
				key: 'shipZip',
				label: this.zipLabel,
			},
			{
				key: 'shipPhone',
				label: 'Phone Number',
				fullwidth: true,
			},
			{
				key: 'shipMobile',
				label: 'Mobile Phone Number',
				fullwidth: true,
			},
		];

		let fields = baseFields.filter(field => fieldsToInclude.length === 0 || fieldsToInclude.includes(field.key));

		if (isBill) {
			fields = fields.map(field => ({
				...field,
				key: field.key.replace('ship', 'bill'),
			}));
		}

		return fields;
	};

	get zipLabel() {
		return this.props.isCanadian ? 'Postal Code' : 'ZIP';
	}

	get stateLabel() {
		return this.props.isCanadian ? 'Province' : 'State';
	}

	get shipFields() {
		return this.getFields();
	}

	get billFields() {
		return this.getFields(true, ['shipStreet', 'shipCity', 'shipState', 'shipZip', 'shipStreet2']);
	}

	get customerFields() {
		return this.getFields(true, ['shipCompany', 'shipFirstName', 'shipLastName']);
	}

	get phoneFields() {
		return this.getFields(true, ['shipPhone', 'shipMobile']);
	}

	componentDidMount() {
		const requiredFields = ['billFirstName', 'billLastName', 'billCompany'];
		const elementWithValue = filter(requiredFields, field => this.props.data[field]);
		if (!isEmpty(elementWithValue)) {
			this.setState({ billFirstLastCompanyRequired: false });
		} else {
			this.setState({ billFirstLastCompanyRequired: true });
		}
	}

	componentDidUpdate = prevProps => {
		const { advancedView, isLoading, isExpanded } = this.props;
		if (
			prevProps.advancedView !== advancedView ||
			prevProps.isLoading !== isLoading ||
			prevProps.isExpanded !== isExpanded
		) {
			this.shouldAutoFocusCustomerNumber();
			this.shouldAutoFocusFirstName(prevProps);
		}
	};

	shouldAutoFocusCustomerNumber = () => {
		const {
			isExpanded,
			data: { customerId },
			advancedView,
		} = this.props;
		if (isExpanded && !customerId && advancedView && this.customerNumberInput.current) {
			this.customerNumberInput.current.focus();
		}
	};

	shouldAutoFocusFirstName = prevProps => {
		const {
			data: { customerId },
			isLoading,
		} = this.props;
		if (prevProps.isLoading && !isLoading && !customerId && this.firstNameRef.current) {
			this.firstNameRef.current.focus();
		}
	};

	toggleSection = section => {
		this.setState(prevState => ({
			expandedSections: {
				...prevState.expandedSections,
				[section]: !prevState.expandedSections[section],
			},
		}));
	};

	onChange = e => {
		const { name, value, checked, type } = e.target;
		const requiredFields = ['billFirstName', 'billLastName', 'billCompany'];
		if (some(requiredFields, field => field === name)) {
			if (value) {
				this.setState({ billFirstLastCompanyRequired: false });
			} else if (!value) {
				const elementWithValue = filter(requiredFields, field => this.props.data[field]);
				if (size(elementWithValue) > 1) {
					this.setState({ billFirstLastCompanyRequired: false });
				} else {
					this.setState({ billFirstLastCompanyRequired: true });
				}
			}
		}
		if (some(['billPhone', 'billMobile', 'shipPhone', 'shipMobile'], item => item === name)) {
			if (!/[^\d()+\-\s]/g.test(value) || value === '') {
				this.props.onChange({
					key: name,
					value: type === 'checkbox' ? checked : value,
				});
			}
		} else {
			this.props.onChange({
				key: name,
				value: type === 'checkbox' ? checked : value,
			});
		}
	};

	showTooltip = (value, isCustom2, customerNumber, customerCustom02, modified, blurredField, savedGeneral, key) => {
		if (
			(!value && isCustom2 && customerNumber && modified && !customerCustom02 && blurredField === key) ||
			(savedGeneral && isCustom2)
		) {
			return true;
		}
		return false;
	};

	getInputClassName = (className, isValid) => {
		if (isValid) return className;
		return `${className} is-invalid`;
	};

	mapStateToCustomFields = () => {
		const {
			data,
			data: {
				_meta: { visibleCustomFields, modified },
			},
			customDisplayLabels,
			onCustomBlur,
			blurredField,
			savedGeneral,
			customerRequiredFields,
			customerHiddenFields,
			isViewOnly,
			className,
		} = this.props;
		const customFields = filter(keys(visibleCustomFields), key => visibleCustomFields[key]);
		return map(customFields, key => {
			const number = key.slice(-2);
			const parsedNumber = parseFloat(number);
			if (customerHiddenFields[`custom${parsedNumber}`] || parsedNumber === 1) return;
			let value = data[key] || '';
			const isCustom2 = key === 'customerCustom02';
			if (key === 'customerCustom01' && data.customerId) {
				value = data.customerId;
			} else if (!value && !modified && isCustom2 && data.customerNumber) {
				value = data.customerNumber;
			}

			const isInvalid = this.props.isInvalidField(key, 'general');
			const inputClassName = this.getInputClassName('input input--med', !isInvalid);

			const showTooltip = this.showTooltip(
				value,
				isCustom2,
				data.customerNumber,
				data.customerCustom02,
				modified,
				blurredField,
				savedGeneral,
				key,
				className
			);

			return (
				<div key={key} className="form__group">
					<div className="form__group__header">
						<label htmlFor={`${data.customerId}-${key}`} className="form__group__label">
							{customDisplayLabels[`custom${parsedNumber}`] || `Custom${number}`}{' '}
							{this.renderRequired(`custom${parsedNumber}`, customerRequiredFields)}
						</label>
						{showTooltip && (
							<i
								className="icon icon--tny icon--regulary--info"
								data-tooltip={'Custom02 will be prefilled with your Customer Number'}
							></i>
						)}
					</div>
					{showTooltip && <label htmlFor={`${data.customerId}-${key}`} className="datatooltip--no-wrap"></label>}
					<input
						type="text"
						id={`${data.customerId}-${key}`}
						name={key}
						className={inputClassName}
						placeholder={customDisplayLabels[`custom${parsedNumber}`] || `Custom${number}`}
						value={value}
						onChange={this.onChange}
						disabled={key === 'customerCustom01' || isViewOnly}
						onBlur={onCustomBlur}
					/>
				</div>
			);
		});
	};

	getProperty = (key, defaultValue, data) => {
		if (key === 'billStreet' || key === 'shipStreet') {
			key = 'address';
		}
		if (key === 'billPhone') {
			key = 'phoneNumber';
		}
		if (key === 'billMobile') {
			key = 'mobilePhoneNumber';
		}
		if (key === 'shipStreet2' || key === 'billStreet2') {
			key = 'address2';
		}

		return get(data, camelCase(replace(key, 'bill', '')), defaultValue);
	};

	renderBillAndShipInformation = (data, fields, isShip) => {
		return (
			<div className="f-row">
				{map(fields, field => {
					const id = `${data.customerId}-${field.name || field.key}`;
					return this.renderField(
						id,
						field.key,
						field.label,
						id,
						data[field.name] || data[field.key],
						isShip,
						field.fullwidth
					);
				})}
			</div>
		);
	};

	renderRequired = (key, customerRequiredFields) => {
		if (
			this.getProperty(key, false, customerRequiredFields) ||
			(includes(['billFirstName', 'billLastName', 'billCompany'], key) && this.state.billFirstLastCompanyRequired)
		) {
			return (
				<span data-tooltip="Required" className="form__group__required">
					*
				</span>
			);
		}
		return null;
	};

	renderField = (htmlFor, key, fieldLabel, id, value, isShip, fullwidth) => {
		const { customDisplayLabels, customerRequiredFields, customerHiddenFields } = this.props;
		if (this.getProperty(key, false, customerHiddenFields)) return;
		if (
			customerHiddenFields[key] ||
			customerHiddenFields[camelCase(replace(key, 'bill', ''))] ||
			customerHiddenFields[camelCase(replace(key, 'ship', ''))]
		)
			return null;
		const label = this.getProperty(key, fieldLabel, customDisplayLabels);
		// Apply validation for both shipping and billing fields
		const isInvalid = this.props.isInvalidField(key, 'general');
		const inputClassName = this.getInputClassName('input input--med', !isInvalid);

		return (
			<div className={`f-col f-col-sml-12${fullwidth === true ? '' : ' f-col-med-6'} form__group`} key={key}>
				<div className="form__group__header">
					<label htmlFor={htmlFor} className="form__group__label">
						{label} {!isShip && this.renderRequired(key, customerRequiredFields)}
					</label>
				</div>
				<input
					type="text"
					id={id}
					name={key}
					className={inputClassName}
					placeholder={label}
					value={value || ''}
					onChange={this.onChange}
					disabled={this.props.isViewOnly}
				/>
			</div>
		);
	};

	renderGeneralInformation = data => {
		const { expandedSections } = this.state;
		const customerNumberLabel = this.getProperty('customerNumber', 'Customer #', this.props.customDisplayLabels);
		const emailLabel = this.getProperty('email', 'Email Address', this.props.customDisplayLabels);
		const noteLabel = this.getProperty('note', 'Note', this.props.customDisplayLabels);
		const isInvalidNote = this.props.isInvalidField('customerNotes', 'general');
		const isInvalidCustomerNumber = this.props.isInvalidField('customerNumber', 'general');

		return (
			<Fragment>
				<button
					className="info-panel__heading__wrapper is-expandable"
					onClick={() => this.toggleSection('billingInformation')}
				>
					<h6 className="info-panel__heading">Billing information</h6>
					<i
						className={`icon icon--sml icon--chevron--${
							expandedSections.billingInformation ? 'top' : 'left'
						}--primary `}
					></i>
				</button>
				{expandedSections.billingInformation && (
					<div className="info-panel__section">
						<h5 className="spc--top--sml spc--bottom--lrg">Customer information</h5>
						{this.renderBillAndShipInformation(data, this.customerFields)}
						{!this.props.customerHiddenFields['note'] && (
							<div className="form__group">
								<div className="form__group__header">
									<label htmlFor={`${data.customerId}-customerNotes`} className="form__group__label">
										{noteLabel}
										{this.renderRequired('note', this.props.customerRequiredFields)}
									</label>
								</div>
								<textarea
									name="customerNotes"
									id={`${data.customerId}-customerNotes`}
									className={this.getInputClassName(
										'input input--textarea input--textarea--one-row input--textarea--vertical',
										!isInvalidNote
									)}
									placeholder={noteLabel}
									value={data.customerNotes || ''}
									onChange={this.onChange}
									disabled={this.props.isViewOnly}
								/>
							</div>
						)}
						{!this.props.customerHiddenFields['customerNumber'] && (
							<div className="f-row">
								<div className="f-col f-col-sml-12 form__group">
									<div className="form__group__header">
										<label htmlFor={`${data.customerId}-customerNumber`} className="form__group__label">
											{customerNumberLabel}
											{this.renderRequired('customerNumber', this.props.customerRequiredFields)}
										</label>
									</div>
									<input
										ref={this.customerNumberInput}
										type="text"
										id={`${data.customerId}-customerNumber`}
										name="customerNumber"
										className={this.getInputClassName('input input--med', !isInvalidCustomerNumber)}
										placeholder={customerNumberLabel}
										value={data.customerNumber || ''}
										onChange={this.onChange}
										disabled={this.props.isViewOnly}
									/>
								</div>
							</div>
						)}
						<h5 className="spc--top--sml spc--bottom--lrg">Address information</h5>
						{this.renderBillAndShipInformation(data, this.billFields)}
						<h5 className="spc--top--sml spc--bottom--lrg">Contact information</h5>
						<div className="f-row">
							{this.renderField(
								`${data.customerId}-email`,
								'email',
								emailLabel,
								`${data.customerId}-email`,
								data.email || '',
								false,
								true
							)}
						</div>
						{this.renderBillAndShipInformation(data, this.phoneFields)}
					</div>
				)}
			</Fragment>
		);
	};

	render = () => {
		const { data, advancedView, customerHiddenFields, isViewOnly } = this.props;
		const { expandedSections } = this.state;
		const firstNameLabel = this.getProperty('billFirstName', 'First Name', this.props.customDisplayLabels);
		const lastNameLabel = this.getProperty('billLastName', 'Last Name', this.props.customDisplayLabels);
		const companyLabel = this.getProperty('billCompany', 'Company', this.props.customDisplayLabels);
		return (
			<Fragment>
				{advancedView ? (
					<Fragment>
						{this.renderGeneralInformation(data)}

						<button
							className="info-panel__heading__wrapper is-expandable always-visible"
							onClick={() => this.toggleSection('shippingInformation')}
						>
							<h6 className="info-panel__heading">Shipping Information</h6>
							<i
								className={`icon icon--sml icon--chevron--${
									expandedSections.shippingInformation ? 'top' : 'left'
								}--primary`}
							></i>
						</button>
						{expandedSections.shippingInformation && (
							<div className="info-panel__section">
								<div className="input--check--enable-form spc--top--sml spc--bottom--sml">
									<input
										type="checkbox"
										name="_meta.sameAsBilling"
										value={data._meta.sameAsBilling || false}
										id={`${data.customerId}-_meta.sameAsBilling`}
										className="input--check"
										checked={data._meta.sameAsBilling || false}
										onChange={this.onChange}
										disabled={isViewOnly}
									/>
									<label htmlFor={`${data.customerId}-_meta.sameAsBilling`}>Same as billing</label>
								</div>
								{!data._meta.sameAsBilling && (
									<div className="spc--top--lrg">{this.renderBillAndShipInformation(data, this.shipFields, true)}</div>
								)}
							</div>
						)}

						<button
							className="info-panel__heading__wrapper is-expandable always-visible"
							onClick={() => this.toggleSection('customFields')}
						>
							<h6 className="info-panel__heading">Custom Fields</h6>
							<i
								className={`icon icon--sml icon--chevron--${expandedSections.customFields ? 'top' : 'left'}--primary`}
							></i>
						</button>
						{expandedSections.customFields && (
							<div className="info-panel__section">
								<div className="spc--top--sml">{this.mapStateToCustomFields()}</div>
							</div>
						)}
					</Fragment>
				) : (
					<div className="f-row">
						{!customerHiddenFields['firstName'] && (
							<div className="f-col f-col-sml-12 f-col-med-6 form__group">
								<div className="form__group__header">
									<label htmlFor={`${data.customerId}-billFirstName`} className="form__group__label">
										{firstNameLabel}
									</label>
									{this.renderRequired('billFirstName', this.props.customerRequiredFields)}
								</div>
								<input
									type="text"
									id={`${data.customerId}-billFirstName`}
									name="billFirstName"
									className={this.getInputClassName(
										'input input--med',
										!this.props.isInvalidField('billFirstName', 'general')
									)}
									placeholder={firstNameLabel}
									value={data.billFirstName || ''}
									onChange={this.onChange}
									ref={this.firstNameRef}
									disabled={isViewOnly}
								/>
							</div>
						)}
						{!customerHiddenFields['lastName'] && (
							<div className="f-col f-col-sml-12 f-col-med-6 form__group">
								<div className="form__group__header">
									<label htmlFor={`${data.customerId}-billLastName`} className="form__group__label">
										{lastNameLabel}
									</label>
									{this.renderRequired('billLastName', this.props.customerRequiredFields)}
								</div>
								<input
									type="text"
									id={`${data.customerId}-billLastName`}
									name="billLastName"
									className={this.getInputClassName(
										'input input--med',
										!this.props.isInvalidField('billLastName', 'general')
									)}
									placeholder={lastNameLabel}
									value={data.billLastName || ''}
									disabled={isViewOnly}
									onChange={this.onChange}
								/>
							</div>
						)}
						{!customerHiddenFields['company'] && (
							<div className="f-col f-col-sml-12 form__group">
								<div className="form__group__header">
									<label htmlFor={`${data.customerId}-billCompany`} className="form__group__label">
										{companyLabel}
									</label>
									{this.renderRequired('billCompany', this.props.customerRequiredFields)}
								</div>
								<input
									type="text"
									id={`${data.customerId}-billCompany`}
									name="billCompany"
									className={this.getInputClassName(
										'input input--med',
										!this.props.isInvalidField('billCompany', 'general')
									)}
									placeholder={companyLabel}
									value={data.billCompany || ''}
									disabled={isViewOnly}
									onChange={this.onChange}
								/>
							</div>
						)}
					</div>
				)}
			</Fragment>
		);
	};
}

AddEditCustomerGeneralAddEdit.propTypes = {
	data: PropTypes.object.isRequired,
	customDisplayLabels: object.isRequired,
	onChange: PropTypes.func.isRequired,
	onCustomBlur: PropTypes.func.isRequired,
	advancedView: PropTypes.bool.isRequired,
	isCanadian: PropTypes.bool,
	isExpanded: PropTypes.bool,
	isViewOnly: PropTypes.bool,
	isLoading: PropTypes.bool.isRequired,
	requiredFields: PropTypes.object,
	customerRequiredFields: PropTypes.object,
	customerHiddenFields: PropTypes.object,
	savedGeneral: PropTypes.any,
	blurredField: PropTypes.any,
	className: PropTypes.string,
	isInvalidField: PropTypes.func.isRequired,
};

export default AddEditCustomerGeneralAddEdit;
