import React, { Fragment } from 'react';
import { isEmpty, toLower, noop, get, join, concat } from 'lodash';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import KeyboardEventHandler from 'react-keyboard-event-handler';

import { FormErrors } from '../../Common/utilities';
import { transactionType, paymentMethod } from './constants';
import { default as TransactionDetailsFields } from './transaction-details-fields';
import { default as CustomFields } from './custom-fields';
import { NewTransaction } from './new-transaction';
import { isValid } from '../../Common/fields';
import { withCancelable } from 'common/components/cancelable';
import { withLoader } from 'common/components/loader';
import { withError } from 'common/components/error';
import { UserAccountPanel } from 'common/components/user-account-panel';
import ReceiptNotificationComponent from 'common/components/notifications/receipt-notification';
import { principalService } from 'common/services';

const handleInvalidAccountSettings = error => {
	if (error && toLower(error.message) === 'invalid account settings (g3)') {
		return {
			...error,
			isApiError: false,
			displayMessage: "This account can't process gift cards",
		};
	}
	return error;
};

class NewTransactionVirtualTerminal extends NewTransaction {
	constructor(props) {
		super(props);
		const principal = principalService.get();
		const userRole = get(principal, 'idInfo.permissions.role');
		this.setState({
			isSaveOnly: userRole === 'saveonly',
		});
	}

	get isExpanded() {
		return true;
	}

	get notificationRef() {
		return this.notification;
	}
	getWrapperClassName = () => {
		let wrapperClassName = 'virtual-terminal__footer';
		const { isSaveOnly } = this.state;
		if (isSaveOnly) {
			wrapperClassName = join(concat(wrapperClassName, 'addSaveOnly'), ' ');
		}
		return wrapperClassName;
	};

	refreshGridData = () => {
		this.setState(
			this.initialState(this.props, this.state.permissions, this.state.achEnabled, this.state.has3DS),
			this.componentDidMount
		);
	};

	renderForm = () => {
		const {
			amount,
			transactionDetails,
			customFields,
			errors,
			customerId,
			requiredFields,
			isLoading,
			customDisplayLabels,
			transactionHiddenFields,
			saveAsCustomer,
			convenienceFees,
			convenienceFee,
			salesTax,
			includeConvenience,
			includeSalesTax,
		} = this.state;
		const { invalidClassName, creditCard, hideTransactionDetails, hideCustomFields } = this.formRenderingParams;

		return (
			<Fragment>
				{isLoading ? (
					this.renderLoader()
				) : (
					<Fragment>
						<span ref={this.pageTopRef}></span>
						<form id="transaction-form" onSubmit={this.onSubmit}>
							<KeyboardEventHandler handleKeys={['enter']} onKeyEvent={() => this.onSubmit()}>
								{!isEmpty(errors) && (
									<div className="spc--bottom--med">
										<FormErrors errors={errors} />
									</div>
								)}
								<div className="virtual-terminal">
									<div className="virtual-terminal__aside">
										<div className="newtransaction__amount">
											<p className="spc--bottom--tny type--base type--color--text--regular datatooltip--h--right">
												{customDisplayLabels.amount || 'Amount'}{' '}
												<span className="form__group__required" data-tooltip="Required">
													*
												</span>
											</p>
											<NumberFormat
												getInputRef={el => {
													this.amountRef = el;
												}}
												placeholder={`${this.currencyCode}0.00`}
												thousandSeparator=","
												decimalSeparator="."
												allowNegative={false}
												decimalScale={2}
												prefix={this.currencyCode}
												onValueChange={this.handleAmountChange}
												value={amount.value}
												isAllowed={this.checkIfAmountAllowed}
												disabled={
													creditCard &&
													creditCard.transactionType === transactionType.SAVE &&
													this.state.paymentMethod === paymentMethod.CC
												}
												autoFocus={true}
												inputMode="numeric"
												className={`input newtransaction__amount__input ${(!isValid(amount) && invalidClassName) ||
													''} ${amount.value.length > 6 ? 'is-reduced' : ''}`}
											/>
											{this.renderTaxAndConvenience()}
										</div>
										{this.renderTransactionTabs()}
										{this.isAchDisabled && (
											<div className="spc--bottom--lrg type--center">
												<i className="icon icon--xlrg icon--ach-disabled" />
												<div className="type--wgt--light type--base">
													Allow your customers to pay directly to your bank account without a credit card.
												</div>
											</div>
										)}
										{this.renderSelectedCustomerPaymentMethod(
											'',
											'',
											'newtransaction__item newtransaction__item--expandable',
											'',
											'newtransaction__accordion spc--bottom--sml'
										)}
									</div>

									{this.isExpanded && (
										<div className="virtual-terminal__main">
											{this.renderBillingAndShippingInfo('form__body')}
											{!hideTransactionDetails && (
												<Fragment>
													<div className="form__header">Transaction Details</div>

													<div className="newtransaction__group">
														<TransactionDetailsFields
															data={transactionDetails}
															onChange={this.onTransactionDetailsChange}
															invalidClassName={invalidClassName}
															requiredFields={requiredFields}
															customDisplayLabels={customDisplayLabels}
															transactionHiddenFields={transactionHiddenFields}
															hideOrderId={!!customerId}
														/>
													</div>
												</Fragment>
											)}
											{!hideCustomFields && (
												<Fragment>
													<div className="form__header">Custom details</div>
													<CustomFields
														numberOfCustomFields={19}
														data={customFields}
														onChange={this.onCustomFieldsChange}
														customerId={customerId}
														invalidClassName={invalidClassName}
														requiredFields={requiredFields}
														customDisplayLabels={customDisplayLabels}
														transactionHiddenFields={transactionHiddenFields}
														saveAsCustomer={saveAsCustomer}
														convenienceFees={convenienceFees}
														originalAmount={amount.value}
														convenienceFee={convenienceFee}
														salesTax={salesTax}
														includeSalesTax={includeSalesTax}
														includeConvenience={includeConvenience}
													/>
												</Fragment>
											)}
										</div>
									)}
								</div>
							</KeyboardEventHandler>
						</form>
					</Fragment>
				)}
			</Fragment>
		);
	};

	renderPopupHeader = () => (
		<div className="header">
			<div className="header__menu">
				<UserAccountPanel />
			</div>
		</div>
	);

	renderPopupFooter = () => {
		const { isProcessing, total, amount, includeConvenience, includeSalesTax, creditCard } = this.state;

		return (
			<div className={this.getWrapperClassName()}>
				{this.isAchDisabled ? (
					<a
						className="btn btn--primary btn--med"
						rel="noopener noreferrer"
						target="_blank"
						href="https://www.cardknox.com/go"
					>
						Click here to apply for ACH
					</a>
				) : (
					<button
						type="button"
						className="btn btn--primary btn--med virtual-terminal__new-transaction__footer__process"
						disabled={isProcessing}
						onClick={this.onSubmit}
					>
						<span>
							Process (Total{' '}
							<NumberFormat
								thousandSeparator=","
								decimalSeparator="."
								disabled={true}
								decimalScale={2}
								prefix={this.currencyCode}
								value={
									((includeConvenience || includeSalesTax) &&
									creditCard &&
									creditCard.transactionType !== transactionType.SAVE
										? total
										: amount.value) || 0
								}
								displayType="text"
							/>
							)
						</span>
					</button>
				)}
			</div>
		);
	};

	render() {
		const { showPopupDetails } = this.state;
		return (
			<Fragment>
				<ReceiptNotificationComponent ref={el => (this.notification = el)} />
				<Fragment>
					{this.renderPopupHeader()}
					<div className={`l--content l--content--virtual-terminal${showPopupDetails ? ' is-visible' : ''}`}>
						<h3 className="spc--bottom--xlrg">Add new transaction</h3>
						{this.renderForm()}
						{this.renderPopupFooter()}
					</div>
				</Fragment>
			</Fragment>
		);
	}
}

NewTransactionVirtualTerminal.defaultProps = {
	refreshGridData: noop,
};

NewTransactionVirtualTerminal.propTypes = {
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
};

export default withLoader(withError(withCancelable(NewTransactionVirtualTerminal), handleInvalidAccountSettings));
