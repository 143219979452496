import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';

class StringFilterComponent extends Component {
	constructor(props) {
		super(props);
		this.inputRef = createRef();
	}

	componentDidUpdate() {
		const { noFocus } = this.props;
		if (this.inputRef.current && !noFocus) {
			this.inputRef.current.focus();
		}
	}

	handleChange = ({ target: { value } }) => {
		const {
			filter: { key },
			onFilterChanged,
		} = this.props;
		onFilterChanged({
			id: key,
			values: [{ key, value }],
			emptyValue: null,
		});
	};

	render() {
		const {
			filter: { key, name, values, disabled },
			noFocus,
			placeholder,
			injectedFilters,
			goButtonHandler,
			gridFilterSearch,
		} = this.props;

		const inputClassName = gridFilterSearch ? 'chips chips--search' : 'input input--med';

		return (
			<div className={`flex--primary flex--nowrap flex--gap--${gridFilterSearch ? 'sml' : 'med'}`}>
				<input
					type="text"
					autoComplete="off"
					value={values[key] === null ? '' : values[key]}
					placeholder={placeholder || name}
					id={key}
					onChange={this.handleChange}
					autoFocus={!noFocus}
					ref={this.inputRef}
					className={inputClassName}
					disabled={disabled && disabled(injectedFilters)}
				/>
				{goButtonHandler && (
					<button
						className={`btn${gridFilterSearch ? ' btn--sml' : ' btn--med'} btn--primary`}
						onClick={goButtonHandler}
					>
						Search
					</button>
				)}
			</div>
		);
	}
}

StringFilterComponent.propTypes = {
	filter: PropTypes.shape({
		key: PropTypes.string.isRequired,
		name: PropTypes.string,
		values: PropTypes.object.isRequired,
		disabled: PropTypes.func,
	}).isRequired,
	onFilterChanged: PropTypes.func.isRequired,
	noFocus: PropTypes.bool,
	placeholder: PropTypes.string,
	injectedFilters: PropTypes.object,
	goButtonHandler: PropTypes.func,
	gridFilterSearch: PropTypes.bool,
};

export default StringFilterComponent;
