import React, { Component } from 'react';
import PropTypes from 'prop-types';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { forOwn, cloneDeep, filter, trim } from 'lodash';
import moment from 'moment';

import { NumberFilter } from 'common/components/filters';
import { batchPredefinedDates, DatePickerPredefined, maxBatchDaysRange } from 'common/components/date-picker';

class MainFilterComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeKeys: [],
			dates: batchPredefinedDates,
		};
	}

	parseFilters = (filters, filterItem) => {
		let hasSelection = false;
		forOwn(filters.values, function(value, key) {
			const valueItem = filter(filterItem.values, { key: key });
			if (valueItem.length > 0) {
				filters.values[key] = valueItem[0].value;
				if (moment.isMoment(filters.values[key])) {
					if (!filters.values[key].isSame(filterItem.emptyValue, 'day')) {
						hasSelection = true;
					}
				} else if (
					filters.values[key] !== undefined &&
					filters.values[key] !== null &&
					trim(filters.values[key]) &&
					filters.values[key] !== filterItem.emptyValue
				) {
					hasSelection = true;
				}
			} else {
				filters.values[key] = filterItem.emptyValue;
			}
		});
		filters.hasSelection = hasSelection;
	};

	renderCustomSubMenuItemTitle = (_, key) => {
		const item = this.getActiveItem(key);
		return (
			<span>
				<span className="selected hide--to--sml rc-menu-input-text">
					{item.getSelectionText && item.getSelectionText(item.values, this.state.dates)}
				</span>
			</span>
		);
	};

	onOpenChange = activeKeys => {
		this.setState({ activeKeys: activeKeys });
	};

	getItem = key => {
		const item = filter(this.props.filters, { key: key })[0];
		return item;
	};

	getActiveItem = key => {
		const item = filter(this.props.activeFilters, { key: key })[0];
		return item;
	};

	onFilterChanged = item => {
		const filter = this.getItem(item.id);
		this.parseFilters(filter, item);
		this.props.onFiltersUpdate(this.props.filters);
	};

	onActiveFilterChanged = item => {
		const filter = this.getActiveItem(item.id);
		this.parseFilters(filter, item);
		this.setState({ activeKeys: [] });
		this.props.onFiltersUpdate(cloneDeep(this.props.activeFilters), this.props.activeFilters);
	};

	applyFilter = () => {
		this.props.onFiltersUpdate(cloneDeep(this.props.filters), cloneDeep(this.props.filters));
	};

	render() {
		return (
			<div className="flex--primary flex--gap--sml">
				<KeyboardEventHandler handleKeys={['enter']} onKeyEvent={() => this.applyFilter()}>
					<NumberFilter
						noFocus={true}
						filter={this.getItem('batch')}
						onFilterChanged={this.onFilterChanged}
						className="chips chips--search"
					/>
				</KeyboardEventHandler>
				<div className="filter__date">
					<DatePickerPredefined
						maxDaysRange={maxBatchDaysRange}
						subMenuTitle={this.renderCustomSubMenuItemTitle('Custom', 'date')}
						onOpenChange={this.onOpenChange}
						activeKeys={this.state.activeKeys}
						filter={this.getActiveItem('date')}
						onApplyFilter={this.applyFilter}
						onActiveFilterChanged={this.onActiveFilterChanged}
						onFilterChanged={this.onFilterChanged}
						predefinedDates={this.state.dates}
					/>
				</div>
			</div>
		);
	}
}

MainFilterComponent.propTypes = {
	filters: PropTypes.array,
	activeFilters: PropTypes.array,
	onFiltersUpdate: PropTypes.func,
};

MainFilterComponent.defaultProps = {
	filters: [],
	activeFilters: [],
	onFiltersUpdate: () => {
		//eslint-disable-next-line
		console.warning('Provide onFilterUpdate method');
	},
};

export default MainFilterComponent;
