import { get } from 'lodash';

export const addError = (errors, fieldErrors, id, field, message) => {
	errors.push({ key: field, message, id });
	fieldErrors.push(field);
};
export const createError = (key, message) => {
	return { key, message };
};

export const generateErrorMessage = (e, context = 'general') => {
	const uniqueKey = `${context}_${Date.now()}`;
	const message = get(e, 'message', 'An unexpected error occurred');
	const ref = get(e, 'ref');
	const fullMessage = ref ? `${message} (Ref# ${ref})` : message;

	return {
		key: uniqueKey,
		message: fullMessage,
	};
};

export const addApiError = (existingErrors, apiError, context = 'general') => {
	const errors = existingErrors || [];

	if (!apiError) {
		return errors;
	}

	const errorMessage = `${get(apiError, 'message', 'An unexpected error occurred')}${
		get(apiError, 'ref') ? ` (Ref# ${apiError.ref})` : ''
	}`;
	const errorKey = get(apiError, 'field', `${context}_error`);

	return [...errors, { key: errorKey, message: errorMessage }];
};
