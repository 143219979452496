import React, { Component, Fragment, createRef } from 'react';
import moment from 'moment';
import {
	cloneDeep,
	filter,
	find,
	each,
	map,
	unionBy,
	concat,
	includes,
	toLower,
	sortBy,
	toNumber,
	camelCase,
	isEmpty,
	has,
	some,
	startsWith,
	toUpper,
	get,
	round,
} from 'lodash';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Data } from 'react-data-grid-addons';
import { components } from 'react-select';
import { parse, stringify } from 'query-string';

import { transactionService, kvaasService, principalService } from '../../Common/services';
import {
	CurrencyMap,
	LoadMoreOptions,
	kvaasResources,
	invokeIfFunction,
	getFilterByValue,
} from '../../Common/utilities';
import { modalNames, ActionsModal } from '../../Common/components/transaction-actions';
import { MainFilterComponent } from '../../Common/components/filter';
import { disputesFilter as Filter, compileFilter } from '../../Common/components/disputes/filter/disputesFilter';
import { DisputeColumns as Columns } from '../../Common/components/disputes/column-filter/disputeColumns';
import { GridComponent } from '../../Common/components/grid';
import { withError } from '../../Common/components/error';
import { predefinedDates } from 'common/components/date-picker';
import { Select } from '../../Common/components/select';
import { Modal } from '../../Common/components/modal';
import { BreakdownGrid } from '../../Common/components/breakdown-grid';
import { accountUpdater } from '../../Common/components/user-account-panel/notification-types';
import { exportTypesByKey } from '../../Common/components/export/export-options';
import { exportService } from '../../Common/components/export/exportService';
import { ZebraRenderer } from '../../Common/components/row';
import sectionKeys from 'routing/sections';
import { withLoadMore } from 'common/components/loadmore';
import { addCustomDataType } from 'common/components/customers/popup/utils';

const {
	apiDateTimeFormat,
	apiResponseDateTimeFormat,
	displayDateTimeFormat,
	parseDateTimeFormat,
} = ApplicationSettings;

const loadMoreOptionsWithAll = concat(LoadMoreOptions, [0]);

const requestKeys = {
	FETCH: 'fetch',
	KVAAS: 'kvaas',
	LOAD_MORE: 'loadMore',
	LOAD_ALL: 'loadAll',
	UPDATER: 'accountUpdater',
};

let displayedAccountUpdaterPopup = false;

const exportTypes = [exportTypesByKey.csv];

class DisputesGrid extends Component {
	constructor(props) {
		super(props);

		const filters = unionBy(props.location.filters, Filter, 'key') || Filter;
		const activeFilters = props.activeFilters || cloneDeep(filters);
		const initialRecordsLimit = props.location.initialRecordsLimit || 20;
		const initialSortBy = props.location.initialSortBy || null;
		const allColumns = cloneDeep(Columns);

		if (window.innerWidth <= 990) {
			const nonMobileColumns = filter(allColumns, column => !column.showOnMobile);
			each(nonMobileColumns, column => {
				column.visible = false;
			});
		}

		const initiallySelectedColumns = initialSortBy ? this.removeColumnSorting(allColumns) : allColumns;
		if (initialSortBy) {
			const sortColumn = filter(initiallySelectedColumns, { key: initialSortBy.xColumn })[0];
			sortColumn.sortDirection = 'ASC';
			sortColumn.visible = true;
		}

		const principal = principalService.get();
		const permissions = (principal && principal.idInfo && principal.idInfo.permissions) || {};

		if (principal) {
			if (!principal.hasAccess[sectionKeys.goPlus]) {
				const goPlusColumns = filter(allColumns, column => column.showGoPlus);
				each(goPlusColumns, column => {
					column.visible = false;
					column.hideable = false;
				});
			}
		}

		this.state = {
			filters: filters,
			activeFilters: activeFilters,
			inlineFilters: {},
			data: null,
			filteredRows: [],
			originalData: null,
			fetchingData: true,
			fetchingAdditionalData: false,
			columns: initiallySelectedColumns,
			defaultColumns: cloneDeep(allColumns),
			loadMoreLimit: 50,
			initialRecordsLimit: initialRecordsLimit,
			initiallyOpenNewTransaction: false,
			lastApiRefNum: null,
			selectedCurrency: {},
			currencyOptions: [],
			permissions,
		};

		this.gridRef = createRef();
		this.breakdownRef = createRef();

		this.components = {
			modal: this.renderModals,
			filter: MainFilterComponent,
			rowRenderer: ZebraRenderer,
			gridFooter: this.renderGridFooter,
		};
	}

	componentDidMount = async () => {
		try {
			this.checkIfAlreadyDisplayedAccountUpdater();
			const [customDefaultColumns, order, defaultValues] = await this.props.makePendingRequest(
				kvaasService.get(
					kvaasResources.disputeReportDefaultColumns,
					kvaasResources.disputeReportOrder,
					kvaasResources.disputeReportDefaultValues
				),
				requestKeys.KVAAS
			);
			const loadMoreLimit = get(defaultValues, 'data.limit');
			const columns = this.addCustomData(this.state.columns, {
				customDefaultColumns,
				order,
			});
			const defaultColumns = this.addCustomData(this.state.defaultColumns, {
				customDefaultColumns,
				order,
			});
			let filters = this.addCustomData(this.state.filters, { defaultValues });
			let activeFilters = this.addCustomData(this.state.activeFilters, { defaultValues });
			const queryParams = parse(this.props.location.search);
			filters = this.parseQueryFilters(filters, queryParams);
			activeFilters = this.parseQueryFilters(activeFilters, queryParams);
			this.queryFilterValues(filters, this.props.location.search);
			this.setState(
				{
					columns,
					defaultColumns,
					filters,
					activeFilters,
					loadMoreLimit: loadMoreLimit ? (loadMoreLimit === 'All' ? 0 : parseInt(loadMoreLimit)) : 20,
					initialRecordsLimit: loadMoreLimit ? (loadMoreLimit === 'All' ? 0 : parseInt(loadMoreLimit)) : 20,
				},
				() => {
					if (this.gridRef.current) {
						this.gridRef.current.reset();
					}
				}
			);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.setState({
					fetchingData: false,
				});
			}
		}
	};

	componentWillUpdate = nextProps => {
		if (
			nextProps.location &&
			nextProps.location.key !== this.props.location.key &&
			!this.props.history.location.refreshPage &&
			(nextProps.location.search === this.props.location.search ||
				(!nextProps.location.search && this.props.location.search))
		) {
			if (this.gridRef.current) {
				this.gridRef.current.clearFilters();
			}
		}
		if (nextProps && nextProps.history.location.refreshPage) {
			this.parseHistoryQueriesToFilters();
		}
	};

	parseHistoryQueriesToFilters = () => {
		const { filters, activeFilters } = this.state;
		const queryParams = parse(this.props.history.location.search);
		const newFilters = this.parseQueryFilters(filters, queryParams);
		const newActiveFilters = this.parseQueryFilters(activeFilters, queryParams);
		this.queryFilterValues(newFilters, this.props.history.location.search);
		this.setState(
			{
				filters: newFilters,
				activeFilters: newActiveFilters,
			},
			() => {
				if (this.gridRef.current) {
					this.gridRef.current.reset();
				}
			}
		);
	};

	parseQueryFilters = (filters, params) => {
		if (isEmpty(params)) {
			return filters;
		}
		const newFilters = cloneDeep(filters);
		let anyChanged = false;
		each(params, (value, key) => {
			const filter = getFilterByValue(newFilters, key);
			if (filter) {
				filter.hasSelection = true;
				const milliseconds = get(filter, `props.milliseconds.${key}`);
				const date = moment(`${value}.${milliseconds}`, apiDateTimeFormat, true);
				filter.values[key] = date.isValid() ? date : value;
				anyChanged = true;
			}
		});
		return anyChanged ? newFilters : filters;
	};

	queryFilterValues = (filters, query = '') => {
		const {
			history,
			location: { search: oldSearch, openNewTransaction },
		} = this.props;
		let result = {};
		if (query) {
			result = parse(query);
		}
		each(filters, ({ values }) => {
			each(values, (value, key) => {
				if (value) {
					if (moment(value, apiDateTimeFormat, true).isValid()) {
						result[key] = value.format(parseDateTimeFormat);
					} else {
						result[key] = value;
					}
				}
			});
		});
		const search = stringify(result);
		if (`?${search}` !== oldSearch) {
			history.replace({
				search,
				openNewTransaction,
			});
		}
	};

	addCustomData = (data, { customDefaultColumns, order, defaultValues }) => {
		let newData = cloneDeep(data);
		let anyChanged = false;
		newData = addCustomDataType(newData, customDefaultColumns, (item, _, index) => {
			if (index === undefined) {
				item.visible = true;
				anyChanged = true;
				return true;
			}
		});
		newData = addCustomDataType(newData, defaultValues, (item, value) => {
			const predefinedDate = find(predefinedDates, ({ key }) => key === value);
			if (predefinedDate) {
				const { startValue, endValue } = predefinedDate;
				const startDate = moment()
					.startOf('day')
					.add(invokeIfFunction(startValue), 'days');
				const endDate = moment()
					.endOf('day')
					.add(invokeIfFunction(endValue), 'days');
				item.values = { ...item.values, key: value, startDate, endDate };
				item.defaultValues = { ...item.values };
				anyChanged = true;
			} else {
				const values = { ...item.values };
				each(defaultValues.data, (value, key) => {
					if (has(item.values, key)) {
						values[key] = value;
						item.hasSelection = true;
						item.defaultHasSelection = true;
					}
				});
				item.values = values;
				item.defaultValues = { ...values };
				anyChanged = true;
			}
			return true;
		});
		newData = addCustomDataType(
			newData,
			order,
			(item, value, index) => {
				if (index === undefined) {
					item.order = value;
					anyChanged = true;
					return true;
				}
			},
			updatedData => sortBy(updatedData, 'order')
		);
		return anyChanged ? newData : data;
	};

	handleChange = changes => {
		const newState = {};
		each(changes, ({ key, value }) => {
			if (key === 'data' || key === 'inlineFilters') {
				let filters, data;
				if (key === 'data') {
					filters = this.state.inlineFilters;
					data = value;
				} else {
					filters = value;
					data = this.state.data;
				}
				newState.filteredRows =
					data && data.xReportData
						? Data.Selectors.getRows({
								rows: data.xReportData,
								filters,
						  })
						: [];
				const { currencyOptions, selectedCurrency } = this.getTransactionAmounts(newState.filteredRows);
				newState.currencyOptions = currencyOptions;
				newState.selectedCurrency = selectedCurrency;
			}
			newState[key] = value;
		});
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	removeColumnSorting = columns => {
		return map(columns, col => {
			col.sortDirection = null;
			return col;
		});
	};

	checkIfAlreadyDisplayedAccountUpdater = async () => {
		const { makePendingRequest, handleError } = this.props;
		try {
			const [notifications] = await makePendingRequest(
				kvaasService.get(kvaasResources.notifications),
				requestKeys.UPDATER
			);
			if (get(notifications, 'data.accountUpdater', false)) {
				displayedAccountUpdaterPopup = true;
			}
		} catch (e) {
			const error = handleError(e, { delayMessage: true });
			if (error) {
				//eslint-disable-next-line
				console.error(error);
			}
		}
		return displayedAccountUpdaterPopup;
	};

	checkForDeclinedTransactions = async () => {
		if (displayedAccountUpdaterPopup) {
			return;
		}
		const { data } = this.state;
		const hasDeclinedTransaction = some(
			data && data.xReportData,
			({ xResponseError }) => toLower(xResponseError) === 'expired card'
		);
		if (!hasDeclinedTransaction) {
			return;
		}
		const checkResult = await this.checkIfAlreadyDisplayedAccountUpdater();
		if (checkResult) {
			return;
		}
		if (!has(this.gridRef, 'current.userAccountRef.current') || !this.gridRef.current.userAccountRef.current) {
			return;
		}
		this.gridRef.current.userAccountRef.current.addNotification(accountUpdater);
		displayedAccountUpdaterPopup = true;
	};

	fetchData = async (filters, filterDateFormat, maxRecords = 1000) => {
		const {
			history,
			history: {
				location: { search },
			},
		} = this.props;
		const parsedQuery = parse(search);
		this.setState({
			fetchingData: true,
			data: null,
			filteredRows: [],
			currencyOptions: [],
			lastApiRefNum: null,
		});

		try {
			const filter = await this.props.makePendingRequest(compileFilter(filters, filterDateFormat), requestKeys.FETCH);
			let data = await this.props.makePendingRequest(
				maxRecords
					? transactionService.filterTransactionsRequest(filter, maxRecords > 1000 ? 1000 : maxRecords)
					: transactionService.filterTransactionsAll(filter),
				requestKeys.FETCH
			);
			const lastApiRefNum = data.xRefNum;

			if (data && data.xReportData) {
				data.xReportData = map(data.xReportData, this.mapRow);
			}

			if (maxRecords === 0) {
				data.xRecordsReturned = data.xReportData.length;
				data.xReportingMaxTransactions = data.xReportData.length + 1; // +1 = quick fix
			}

			const formattedColumns = this.formatColumns(this.state.columns, cloneDeep(filter));
			this.mapData(data);
			const filteredRows =
				data && data.xReportData
					? Data.Selectors.getRows({
							rows: data.xReportData,
							filters: this.state.inlineFilters,
					  })
					: [];
			if (this.gridRef.current) {
				this.gridRef.current.scrollTo({ top: 0, left: 0 });
			}

			this.setState(
				{
					data,
					filteredRows,
					originalData: cloneDeep(data),
					fetchingData: false,
					columns: formattedColumns,
					lastApiRefNum: lastApiRefNum,
					...this.getTransactionAmounts(filteredRows),
				},
				() => {
					if (this.gridRef.current) {
						this.gridRef.current.handleInitialSort();
						this.gridRef.current.calculateColumnWidths();
					}
					each(parsedQuery, (_, key) => {
						if (startsWith(key, 'print')) {
							if (key === 'printAll' && this.gridRef.current && this.gridRef.current.printGridButtonRef.current) {
								this.gridRef.current.printGridButtonRef.current.triggerAll();
							} else if (
								key === 'printCurrent' &&
								this.gridRef.current &&
								this.gridRef.current.printGridButtonRef.current
							) {
								this.gridRef.current.printGridButtonRef.current.triggerCurrent();
							}
							delete parsedQuery[key];
							history.replace({ search: stringify(parsedQuery) });
						}
					});
					this.checkForDeclinedTransactions();
				}
			);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.setState({
					fetchingData: false,
				});
			}
		}
	};

	displayRowCount = () => {
		return this.state.selectedCurrency.count || 0;
	};

	mapRow = row => row;

	getTransactionAmounts = (data = this.state.filteredRows) => {
		const currencyOptions = [];
		if (data && data.length > 0) {
			each(data, reportItem => {
				if (reportItem.xRefNum) {
					const { currency, xAmount } = reportItem;
					const mappedCurrency = CurrencyMap.isoCodesMap[currency] || toUpper(currency);
					const item = find(currencyOptions, { label: mappedCurrency });
					if (item) {
						item.amount += xAmount;
						item.count++;
					} else {
						currencyOptions.push({
							label: mappedCurrency,
							symbol: CurrencyMap.resolveCurrency(mappedCurrency),
							amount: xAmount,
							count: 1,
						});
					}
				}
			});
		}
		const selectedCurrency =
			find(currencyOptions, ({ label }) => label === this.state.selectedCurrency.label) || currencyOptions[0] || {};
		return {
			currencyOptions,
			selectedCurrency,
		};
	};

	getAmountsByCardType = (data = this.state.filteredRows) => {
		const totals = {};
		each(data, ({ xAmount, xCardType, currency }) => {
			const cardType = camelCase(xCardType);
			if (find(totals, (_, key) => key === `${cardType}TotalCount`)) {
				totals[`${cardType}TotalCount`]++;
				totals[`${cardType}TotalAmount`] += round(toNumber(xAmount), 2);
			} else {
				totals[`${cardType}TotalCount`] = 1;
				totals[`${cardType}TotalAmount`] = toNumber(xAmount);
				totals[`${cardType}Currency`] = currency;
			}
			totals[`${cardType}TotalAmount`] = toNumber(totals[`${cardType}TotalAmount`].toFixed(2));
		});
		return totals;
	};

	resolveColumnName = column => {
		let key = column;
		switch (column) {
			case 'CardData':
				key = 'xMaskedCardNumber';
				break;
			case 'AmountData':
				key = 'xAmount';
				break;
			case 'RefNumData':
				key = 'xRefNum';
				break;
			case 'xEnteredDate':
				key = 'xEnteredDateMoment';
				break;
			default:
				break;
		}
		return key;
	};

	mapData = data => {
		let i = 0;
		if (data && data.xReportData && data.xReportData.length > 0) {
			each(data.xReportData, item => {
				if (moment.isMoment(item.xEnteredDate)) {
					item.xEnteredDateMoment = moment(item.xEnteredDate, apiResponseDateTimeFormat);
					item.xEnteredDate = item.xEnteredDateMoment.format(displayDateTimeFormat);
				}

				if (item.xMaskedCardNumber && item.xMaskedCardNumber.includes('xxx')) {
					item.xMaskedCardNumber = `**** ${item.xMaskedCardNumber.slice(-4)}`;
				}

				// include gridRowNumber as a simple counter for zebra
				item.gridRowNumber = i;
				item.index = i + 1; // for initial sort;
				i++;
			});
		}
	};

	combineData = (baseData, additionalData, refNums) => {
		baseData.xReportData = concat(baseData.xReportData, additionalData.xReportData);
		baseData.xRecordsReturned += additionalData.xRecordsReturned - refNums.length;
		baseData.xReportingMaxTransactions += additionalData.xReportingMaxTransactions - refNums.length;
	};

	loadMore = async () => {
		const { loadMoreLimit, data, originalData, activeFilters } = this.state;
		const hasData = originalData && originalData.xReportData && originalData.xReportData.length > 0;

		if (hasData) {
			const filters = cloneDeep(activeFilters);
			const dateFilter = find(filters, { key: 'date' });
			const { start, end, refNums } = transactionService.getNewStartEndDates(
				dateFilter.values.startDate,
				dateFilter.values.endDate,
				originalData.xReportData
			);
			dateFilter.values.startDate = start;
			dateFilter.values.endDate = end;
			let compiledFilter;
			try {
				compiledFilter = await this.props.makePendingRequest(
					compileFilter(filters, apiDateTimeFormat),
					requestKeys.LOAD_MORE
				);
			} catch (e) {
				this.props.handleError(e, { additionalInfo: { dateFilter: dateFilter.values, apiDateTimeFormat } });
				return;
			}
			if (compiledFilter.xBeginDate && compiledFilter.xEndDate) {
				this.setState({
					fetchingAdditionalData: true,
					lastApiRefNum: null,
				});

				try {
					const pendingData = await this.props.makePendingRequest(
						loadMoreLimit
							? transactionService.filterTransactionsRequest(
									compiledFilter,
									Math.min(loadMoreLimit + refNums.length, 1000)
							  )
							: transactionService.filterTransactionsAll(compiledFilter),
						requestKeys.LOAD_MORE
					);
					const response = await this.props.makePendingRequest(
						this.props.loadMore(
							pendingData,
							filters,
							dateFilter,
							loadMoreLimit,
							refNums,
							compiledFilter,
							compileFilter
						),
						requestKeys.LOAD_MORE
					);
					this.combineData(pendingData, response.additionalData, response.additionalRefNums);
					pendingData.xReportData = filter(pendingData.xReportData, ({ xRefNum }) => !includes(refNums, xRefNum));
					if (!isEmpty(pendingData.xReportData)) {
						const updatedData = cloneDeep(data);
						this.combineData(updatedData, pendingData, refNums);
						updatedData.xReportData = map(updatedData.xReportData, this.mapRow);
						if (!loadMoreLimit) {
							updatedData.xReportingMaxTransactions += 1;
						}
						const formattedColumns = this.formatColumns(this.state.columns);
						this.mapData(updatedData);
						const filteredRows =
							updatedData && updatedData.xReportData
								? Data.Selectors.getRows({
										rows: updatedData.xReportData,
										filters: this.state.inlineFilters,
								  })
								: [];
						this.setState(
							{
								originalData: cloneDeep(updatedData),
								data: updatedData,
								filteredRows,
								fetchingAdditionalData: false,
								columns: formattedColumns,
								lastApiRefNum: pendingData.xRefNum,
								...this.getTransactionAmounts(filteredRows),
							},
							() => {
								if (this.gridRef.current) {
									this.gridRef.current.handleInitialSort();
									this.gridRef.current.calculateColumnWidths();
								}
								this.checkForDeclinedTransactions();
							}
						);
					} else {
						this.setState({
							fetchingAdditionalData: false,
							lastApiRefNum: pendingData.xRefNum,
						});
					}
				} catch (e) {
					if (this.props.handleError(e, { additionalInfo: { compiledFilter } })) {
						this.setState({
							fetchingAdditionalData: false,
						});
					}
				}
			}
		}
	};

	getAllTransactions = async () => {
		try {
			const filters = await this.props.makePendingRequest(
				compileFilter(cloneDeep(this.state.activeFilters), apiDateTimeFormat),
				requestKeys.LOAD_ALL
			);
			const allData = await this.props.makePendingRequest(
				transactionService.filterTransactionsAll(filters),
				requestKeys.LOAD_ALL
			);
			this.mapData(allData);
			return allData.xReportData;
		} catch (e) {
			this.props.handleError(e);
		}
	};

	formatColumns = (columns, appliedFilter = null) => {
		if (appliedFilter) {
			delete appliedFilter['xBeginDate'];
			delete appliedFilter['xCommand'];
			delete appliedFilter['xEndDate'];

			for (let prop in appliedFilter) {
				if (appliedFilter.hasOwnProperty(prop)) {
					// find hidden column by key
					let column = find(columns, i => {
						if (prop === 'xAmount') {
							prop = 'AmountData';
						}

						return i.key.toLowerCase() === prop.toLowerCase() && !i.visible;
					});

					if (column) {
						column.visible = true;
					}
				}
			}
		}
		return columns;
	};

	hasMoreData = data => {
		const dateFilter = find(this.state.filters, i => i.key === 'date');
		return (
			data &&
			data.xReportData &&
			data.xReportData.length > 0 &&
			data.xRecordsReturned >= data.xReportingMaxTransactions &&
			!dateFilter.values.disabled
		);
	};

	onLoadMoreLimitChange = value => {
		this.setState(
			{
				loadMoreLimit: value,
			},
			() => {
				this.loadMore();
			}
		);
	};

	handleCurrencyChange = selectedCurrency => {
		this.setState({ selectedCurrency }, () => {
			if (this.gridRef.current) {
				this.gridRef.current.forceUpdate();
			}
		});
	};

	renderSymbol = ({ data: { symbol }, ...props }) => (
		<components.SingleValue {...props}>{symbol}</components.SingleValue>
	);

	renderTransactionsAmount = () => {
		const {
			selectedCurrency,
			selectedCurrency: { amount, symbol },
			currencyOptions,
		} = this.state;
		return (
			<Fragment>
				{currencyOptions.length > 1 ? (
					<Select
						name="currency"
						id="currency"
						className="display--ib reactselect__resize--sml spc--right--xsml"
						value={selectedCurrency}
						options={currencyOptions}
						onChange={this.handleCurrencyChange}
						components={{
							SingleValue: this.renderSymbol,
						}}
						menuPlacement="auto"
						getOptionValue={option => option.label}
					/>
				) : (
					<strong>{symbol}</strong>
				)}
				<strong>
					<NumberFormat
						value={amount}
						displayType="text"
						thousandSeparator={true}
						decimalScale={2}
						fixedDecimalScale={true}
					/>
				</strong>
			</Fragment>
		);
	};

	refetchData = () => {
		if (this.state.permissions.allowReportAll) {
			this.setState({ loadMoreLimit: this.state.initialRecordsLimit }, () =>
				this.fetchData(this.state.activeFilters, apiDateTimeFormat, this.state.initialRecordsLimit)
			);
		} else {
			this.setState({
				fetchingData: false,
				data: null,
				filteredRows: [],
				currencyOptions: [],
				lastApiRefNum: null,
			});
		}
	};

	renderGridFooter = ({ openCloseModal }) => {
		return (
			<Fragment>
				<div className="react-grid__footer__details">
					<span className="spc--right--tny">Transaction total</span>
					{this.renderTransactionsAmount()} (Count: {this.displayRowCount()})
					<a
						href="javascript:void(0)"
						className="btn btn--link btn--link--underline spc--left--sml"
						onClick={() => openCloseModal({ name: modalNames.breakdown })}
					>
						Total by Card
					</a>
				</div>
			</Fragment>
		);
	};

	downloadBreakdown = () => {
		const filename = `${moment().format('YYYY_MM_DD_HH:mm:ss')}_transaction_card_breakdown`;
		if (this.breakdownRef.current) {
			this.breakdownRef.current.download(filename);
		}
	};

	renderExportButton = () => {
		return (
			<button
				className="btn btn--actions btn--action--secondary"
				data-tooltip="Download"
				onClick={() => this.downloadBreakdown()}
			>
				<i className="icon icon--sml icon--download" />
			</button>
		);
	};

	renderModals = props =>
		props.modal.name === modalNames.breakdown ? (
			<Modal isOpen={true} onClose={props.onModalClose}>
				<div className="modal__header">
					<h4>Total by Card</h4>
					{this.renderExportButton()}
				</div>
				<div className="modal__body">
					<BreakdownGrid
						ref={this.breakdownRef}
						totals={this.getAmountsByCardType()}
						columns={['Total']}
						emptyMessage={'No Disputes'}
					/>
				</div>
			</Modal>
		) : (
			<ActionsModal {...props} />
		);

	render = () => {
		const { allowReportAll } = this.state.permissions;

		return (
			<Fragment>
				{allowReportAll ? (
					<GridComponent
						emptyMessage="You should change your filter options"
						fetchingData={this.state.fetchingData}
						fetchingAdditionalData={this.state.fetchingAdditionalData}
						filteredRows={this.state.filteredRows}
						columns={this.state.columns}
						data={this.state.data}
						resolveColumnName={this.resolveColumnName}
						inlineFilters={this.state.inlineFilters}
						components={this.components}
						onChange={this.handleChange}
						hasPaging={true}
						loadMoreOptions={loadMoreOptionsWithAll}
						onLoadMoreLimitChange={this.onLoadMoreLimitChange}
						title="Disputes"
						filterColumns={true}
						defaultColumns={this.state.defaultColumns}
						enableExport={true}
						enablePrint={true}
						printTitle="Dispute report"
						type="disputes"
						filters={this.state.filters}
						activeFilters={this.state.activeFilters}
						enableFilters={true}
						fetchData={this.refetchData}
						fetchAllData={this.getAllTransactions}
						lastApiRefNum={this.state.lastApiRefNum}
						hasMoreData={this.hasMoreData}
						showResults={true}
						ref={this.gridRef}
						initialFetch={false}
						columnFilterType="/settings/user-settings/disputes"
						kvaasResourceType="dispute"
						useInlineFilters={true}
						syncQueryFilters={true}
						queryFilterValues={this.queryFilterValues}
						allTitle="All disputes"
						exportTypes={exportTypes}
						fetchExportData={{ current: exportService.mapTransactionData, all: exportService.getTransactionData }}
						loadMoreLimit={this.state.loadMoreLimit}
					/>
				) : null}
			</Fragment>
		);
	};
}

DisputesGrid.propTypes = {
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	location: PropTypes.object,
	history: PropTypes.object,
	activeFilters: PropTypes.any,
	loadMore: PropTypes.func,
};

export default withError(withLoadMore(DisputesGrid));
