import { isEmpty, startsWith, upperFirst, mapKeys, includes, replace, toLower, nth, trim } from 'lodash';
import { addError } from './validation-utils';
import { isAfter } from 'common/utilities';
import { yesterday } from '../popup/constants';

export const getErrorMessage = (fieldKey, customLabel, scheduleErrorMessages) => {
	if (customLabel || startsWith(fieldKey, 'custom')) {
		return `${customLabel || 'Recurring Custom field'} is required`;
	}
	return scheduleErrorMessages[fieldKey] || `${upperFirst(fieldKey)} is required`;
};

const addScheduleError = (schedule, field, message) => {
	addError(schedule._meta.errorMessages, schedule.fieldErrors, schedule._meta.id, field, message);
};

const clearFieldError = (schedule, field) => {
	if (schedule.fieldErrors && schedule.fieldErrors.length > 0) {
		schedule.fieldErrors = schedule.fieldErrors.filter(errorField => errorField !== field);
	}

	if (schedule._meta.errorMessages && schedule._meta.errorMessages.length > 0) {
		schedule._meta.errorMessages = schedule._meta.errorMessages.filter(error => error.key !== field);
	}
};

export const validateScheduleSpecificFields = schedule => {
	validateIntervalCount(schedule);
	validateAmount(schedule);
	validateScheduleName(schedule);
	validateScheduleDates(schedule);
	validatePaymentsOrEndDate(schedule);
};

const validateIntervalCount = schedule => {
	if (!schedule.intervalCount) {
		addScheduleError(schedule, 'intervalCount', 'Please enter Frequency');
		return;
	}

	if (schedule.intervalCount < 1) {
		addScheduleError(schedule, 'intervalCount', 'Please enter a valid Frequency');
		return;
	}

	clearFieldError(schedule, 'intervalCount');
};

const validateAmount = schedule => {
	if (schedule.amount < 0.01) {
		addScheduleError(schedule, 'amount', 'Please enter a valid Amount');
		return;
	}

	clearFieldError(schedule, 'amount');
};

const validateScheduleName = schedule => {
	if (!schedule._meta.saveAsTemplate) {
		return;
	}

	if (!schedule.scheduleName) {
		addScheduleError(schedule, 'scheduleName', 'Schedule Name required to save as template');
		return;
	}

	clearFieldError(schedule, 'scheduleName');
};

const validateScheduleDates = schedule => {
	if (isEmpty(schedule.scheduleId)) {
		validateStartDate(schedule);
	}
};

const validateStartDate = schedule => {
	if (isEmpty(schedule.startDate)) {
		addScheduleError(schedule, 'startDate', 'Please enter Start');
		return;
	}

	if (!isAfter(schedule.startDate, yesterday[schedule.calendarCulture])) {
		addScheduleError(schedule, 'startDate', 'Please enter a valid Start');
		return;
	}

	clearFieldError(schedule, 'startDate');
};

const validatePaymentsOrEndDate = schedule => {
	const until = toLower(trim(schedule._meta.until));

	if (until === 'numberofpayments') {
		validateTotalPayments(schedule);
	} else if (until === 'date') {
		validateEndDate(schedule);
	}
};

const validateTotalPayments = schedule => {
	if (!(isEmpty(schedule.totalPayments) || schedule.totalPayments > 0)) {
		addScheduleError(schedule, 'totalPayments', 'Please enter a valid Number of payments');
		return;
	}

	clearFieldError(schedule, 'totalPayments');
};

const validateEndDate = schedule => {
	if (isEmpty(schedule.endDate)) {
		addScheduleError(schedule, 'endDate', 'Please enter Date');
		return;
	}

	if (!isAfter(schedule.endDate, schedule.startDate)) {
		addScheduleError(schedule, 'endDate', 'End Date must be after Start Date');
		return;
	}

	clearFieldError(schedule, 'endDate');
};

export const validateScheduleInputs = (
	schedules,
	customerRequiredFields,
	customDisplayLabels,
	scheduleErrorMessages,
	advancedView
) => {
	const modifiedSchedules = schedules
		.filter(schedule => schedule._meta.modified && !schedule._meta.deleted)
		.map(schedule => ({
			...schedule,
			fieldErrors: [],
			_meta: {
				...schedule._meta,
				errorMessages: [],
			},
		}));

	const validatedSchedules = modifiedSchedules.map(schedule => {
		const scheduleCopy = { ...schedule };

		mapKeys(customerRequiredFields, (_value, key) => {
			if (!advancedView && (key === 'scheduleName' || key === 'description')) return;

			if (scheduleCopy._meta.isNewSchedule && isEmpty(modifiedSchedules)) return;
			if (scheduleCopy.isActive && !startsWith(key, 'custom')) {
				let viableKey = key;
				if (includes(key, 'recurringCustom')) {
					const customNumber = replace(key, /^\D+/g, '');
					if (customNumber.length < 2) {
						key = replace(key, nth(key, -1), `0${nth(key, -1)}`);
					}
					viableKey = replace(toLower(key), 'recurring', '');
				} else if (!scheduleErrorMessages[viableKey]) return;

				if (!scheduleCopy[viableKey]) {
					const message = getErrorMessage(viableKey, customDisplayLabels[key], scheduleErrorMessages);
					addScheduleError(scheduleCopy, viableKey, message);
				}
			}
		});

		validateScheduleSpecificFields(scheduleCopy);

		return scheduleCopy;
	});

	return { validatedSchedules };
};
