import { isEmpty, find, keys, toLower, every, some, get } from 'lodash';
import { validators } from 'common/fields';
import { addError } from './validation-utils';

export const validateCC = (payment, fieldErrors, errors) => {
	if (payment.token) {
		if (!validators.expDate(payment.exp)) {
			addError(
				errors,
				fieldErrors,
				payment._meta.id,
				'exp',
				!payment.exp ? 'Please enter Exp Date' : 'Exp Date is not valid'
			);
		}
	} else {
		if (payment._meta.cc.cardNumberIsEmpty) {
			addError(errors, fieldErrors, payment._meta.id, '_meta.fetchToken', 'Please enter Card number');
		} else if (!payment._meta.cc.cardNumberIsValid) {
			addError(errors, fieldErrors, payment._meta.id, '_meta.fetchToken', 'Please enter a valid Card number');
		}

		if (!validators.expDate(payment.exp)) {
			addError(
				errors,
				fieldErrors,
				payment._meta.id,
				'exp',
				!payment.exp ? 'Please enter Exp Date' : 'Exp Date is not valid'
			);
		}
	}
};

export const validateACH = (payment, fieldErrors, errors) => {
	if (!payment.token) {
		if (!validators.required(payment.accountType)) {
			addError(errors, fieldErrors, payment._meta.id, 'accountType', 'Please select an Account Type');
		}
		if (!payment._meta.routingNumber) {
			addError(errors, fieldErrors, payment._meta.id, '_meta.routingNumber', 'Please enter Routing number');
		} else if (!validators.routing(payment._meta.routingNumber)) {
			addError(errors, fieldErrors, payment._meta.id, '_meta.routingNumber', 'Please enter a valid Routing number');
		}
		if (payment._meta.check.achIsEmpty) {
			addError(errors, fieldErrors, payment._meta.id, '_meta.fetchToken', 'Please enter Account number');
		} else if (!payment._meta.check.achIsValid) {
			addError(errors, fieldErrors, payment._meta.id, '_meta.fetchToken', 'Please enter a valid Account number');
		}
	}
	if (!validators.required(payment.name)) {
		addError(errors, fieldErrors, payment._meta.id, 'name', 'Please enter Account name');
	}
};

export const validateAddress = (payment, fieldErrors, errors, requiredFields, zipLabel) => {
	if (requiredFields.address && !validators.required(payment.street)) {
		addError(errors, fieldErrors, payment._meta.id, 'street', 'Please enter Address');
	}
	if (requiredFields.zip && !validators.required(payment.zip)) {
		addError(errors, fieldErrors, payment._meta.id, 'zip', `Please enter ${zipLabel}`);
	}
};

export const checkIfPaymentModified = item => {
	const originalData = item._meta.originalData;
	const isEmptyKey = find(keys(item._meta[item.tokenType]), key => toLower(key).endsWith('isempty'));
	const isIfieldEmpty = get(item, `_meta.${item.tokenType}.${isEmptyKey}`);

	return (
		!every(originalData, (value, key) => value === item[key]) ||
		!isIfieldEmpty ||
		(item.tokenType === 'check' && item._meta.routingNumber)
	);
};

export const errorsByTokenType = (payments, type) => {
	return some(payments, payment => toLower(payment.tokenType) === type && !isEmpty(payment._meta.errorMessages));
};

export const validatePaymentInputs = (payments, requiredFields, isCanadian) => {
	const zipLabel = isCanadian ? 'Postal Code' : 'Zip';
	const modifiedPayments = payments
		.filter(payment => payment._meta.modified && !payment._meta.deleted)
		.map(payment => ({
			...payment,
			fieldErrors: [],
			_meta: {
				...payment._meta,
				errorMessages: [],
			},
		}));

	const validatedPayments = modifiedPayments.map(payment => {
		const paymentCopy = { ...payment };

		if (toLower(paymentCopy.tokenType) === 'cc') {
			validateCC(paymentCopy, paymentCopy.fieldErrors, paymentCopy._meta.errorMessages);
		} else {
			validateACH(paymentCopy, paymentCopy.fieldErrors, paymentCopy._meta.errorMessages);
		}

		validateAddress(paymentCopy, paymentCopy.fieldErrors, paymentCopy._meta.errorMessages, requiredFields, zipLabel);

		return paymentCopy;
	});

	return { validatedPayments };
};
