import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'common/components/modal';
import { get } from 'lodash';
import { CardTypeImagePath } from 'common/utilities';

const BlockSuccessModal = ({ isOpen, onClose, blockedItems, onManageBlockList }) => {
	const creditCard = get(blockedItems, 'xMaskedCardNumber');
	const cardType = get(blockedItems, 'xCardType');
	const email = get(blockedItems, 'xEmail');
	const ipAddress = get(blockedItems, 'xIP');
	const name = get(blockedItems, 'xName');

	const hasBlockedItems = creditCard || email || ipAddress || name;

	return (
		<Modal isOpen={isOpen} onClose={onClose} className="modal__content modal--med">
			<div className="modal__header">
				<div className="flex--primary flex--gap--med">
					<h4>Blocking successful</h4> <i className="icon icon--med icon--regular--check"></i>
				</div>
			</div>

			<div className="modal__body">
				<p className="type--p1 type--color--text--secondary spc--bottom--lrg">
					You have successfully blocked the following items. You can manage your block list in fraud settings.
				</p>

				{hasBlockedItems && (
					<div className="input--check--enable-form">
						{creditCard && (
							<div className="flex--tertiary flex--gap--med fullwidth">
								<p className="type--p1 type--color--text--secondary">Credit Card:</p>
								<div className="flex--primary flex--nowrap flex--gap--sml">
									{cardType && <img src={CardTypeImagePath.getPath(cardType)} className="w--24" alt={cardType} />}
									<p className="type--p1 type--color--text--secondary">{creditCard}</p>
								</div>
							</div>
						)}

						{email && (
							<div className="flex--tertiary flex--gap--med fullwidth">
								<p className="type--p1 type--color--text--secondary">Email:</p>
								<p className="type--p1 type--color--text--secondary">{email}</p>
							</div>
						)}

						{ipAddress && (
							<div className="flex--tertiary flex--gap--med fullwidth">
								<p className="type--p1 type--color--text--secondary">IP Address:</p>
								<p className="type--p1 type--color--text--secondary">{ipAddress}</p>
							</div>
						)}

						{name && (
							<div className="flex--tertiary flex--gap--med fullwidth">
								<p className="type--p1 type--color--text--secondary">Name:</p>
								<p className="type--p1 type--color--text--secondary">{name}</p>
							</div>
						)}
					</div>
				)}
			</div>

			<div className="modal__footer">
				<button type="button" className="btn btn--med btn--tertiary" onClick={onManageBlockList}>
					Manage Block List
				</button>
			</div>
		</Modal>
	);
};

BlockSuccessModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onManageBlockList: PropTypes.func.isRequired,
	blockedItems: PropTypes.shape({
		xCardType: PropTypes.string,
		xMaskedCardNumber: PropTypes.string,
		xEmail: PropTypes.string,
		xName: PropTypes.string,
		xIP: PropTypes.string,
	}),
};

export default BlockSuccessModal;
