export const ACTIONS = {
	SET_BLOCK_OPTIONS: 'SET_BLOCK_OPTIONS',
	SET_FORM_DATA: 'SET_FORM_DATA',
	SET_LOADING: 'SET_LOADING',
	SET_LOADING_SETTINGS: 'SET_LOADING_SETTINGS',
	SET_CURRENT_SETTINGS: 'SET_CURRENT_SETTINGS',
	SET_SHOW_SUCCESS_MODAL: 'SET_SHOW_SUCCESS_MODAL',
	SET_BLOCKED_ITEMS: 'SET_BLOCKED_ITEMS',
	TOGGLE_BLOCK_OPTION: 'TOGGLE_BLOCK_OPTION',
	RESET_STATE: 'RESET_STATE',
};

export const setBlockOptions = blockOptions => ({
	type: ACTIONS.SET_BLOCK_OPTIONS,
	payload: blockOptions,
});

export const setFormData = formData => ({
	type: ACTIONS.SET_FORM_DATA,
	payload: formData,
});

export const setLoading = isLoading => ({
	type: ACTIONS.SET_LOADING,
	payload: isLoading,
});

export const setLoadingSettings = isLoading => ({
	type: ACTIONS.SET_LOADING_SETTINGS,
	payload: isLoading,
});

export const setCurrentSettings = settings => ({
	type: ACTIONS.SET_CURRENT_SETTINGS,
	payload: settings,
});

export const setShowSuccessModal = show => ({
	type: ACTIONS.SET_SHOW_SUCCESS_MODAL,
	payload: show,
});

export const setBlockedItems = items => ({
	type: ACTIONS.SET_BLOCKED_ITEMS,
	payload: items,
});

export const toggleBlockOption = (name, checked) => ({
	type: ACTIONS.TOGGLE_BLOCK_OPTION,
	payload: { name, checked },
});

export const resetState = () => ({
	type: ACTIONS.RESET_STATE,
});

export const initialState = {
	blockOptions: {
		blockCreditCard: false,
		blockEmailAddress: false,
		blockIPAddress: false,
		blockName: false,
	},
	formData: {
		emailAddress: '',
		ipAddress: '',
		name: '',
		paymentId: '',
		refNum: '',
		maskedCardNumber: '',
	},
	isLoading: false,
	loadingSettings: false,
	currentSettings: null,
	showSuccessModal: false,
	blockedItems: {},
};

export function blockTransactionReducer(state, action) {
	switch (action.type) {
		case ACTIONS.SET_BLOCK_OPTIONS:
			return {
				...state,
				blockOptions: action.payload,
			};
		case ACTIONS.SET_FORM_DATA:
			return {
				...state,
				formData: action.payload,
			};
		case ACTIONS.SET_LOADING:
			return {
				...state,
				isLoading: action.payload,
			};
		case ACTIONS.SET_LOADING_SETTINGS:
			return {
				...state,
				loadingSettings: action.payload,
			};
		case ACTIONS.SET_CURRENT_SETTINGS:
			return {
				...state,
				currentSettings: action.payload,
			};
		case ACTIONS.SET_SHOW_SUCCESS_MODAL:
			return {
				...state,
				showSuccessModal: action.payload,
			};
		case ACTIONS.SET_BLOCKED_ITEMS:
			return {
				...state,
				blockedItems: action.payload,
			};
		case ACTIONS.TOGGLE_BLOCK_OPTION:
			return {
				...state,
				blockOptions: {
					...state.blockOptions,
					[action.payload.name]: action.payload.checked,
				},
			};
		case ACTIONS.RESET_STATE:
			return {
				...initialState,
			};
		default:
			return state;
	}
}
