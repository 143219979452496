import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Data } from 'react-data-grid-addons';
import {
	cloneDeep,
	each,
	find,
	map,
	findIndex,
	sortBy,
	toLower,
	includes,
	isEmpty,
	get,
	toNumber,
	findKey,
	filter,
	noop,
	every,
	toString,
	split,
	memoize,
} from 'lodash';
import NumberFormat from 'react-number-format';
import { components } from 'react-select';
import { parse, stringify } from 'query-string';

import { ActionsModal, modalNames } from 'common/components/transaction-actions';
import { RecurringScheduleColumns as Columns } from 'common/components/recurring-schedules/column-filter/recurringScheduleColumns';
import { MainFilterComponent } from 'common/components/filter';
import {
	recurringSchedulesFilter as Filter,
	compileFilter,
} from 'common/components/recurring-schedules/filter/recurringSchedulesFilter';
import { customerService, kvaasService, principalService } from 'common/services';
import { CustomerRowDetails } from './../customer-grid/details';
import { ZebraRenderer } from 'common/components/row';
import { AddEditCustomer } from 'common/components/customers/popup';
import { GridComponent } from 'common/components/grid';
import { withCancelable } from 'common/components/cancelable';
import { withError } from 'common/components/error';
import { CurrencyMap, kvaasResources } from '../../Common/utilities';
import { Select } from '../../Common/components/select';
import { exportService } from 'common/components/export/exportService';
import { Notification } from 'common/components/notifications';
import { mapDefaultOrder } from 'common/utilities/map-default-order';
import { GridTooltip } from 'common/components/tooltips';
import { validInlineFilterValues } from 'common/components/grid/valid-inline-filter-values';
import { addCustomDataType, queryFilterValues } from 'common/components/customers/popup/utils';
import { SelectAllContext, SidebarContext } from 'common/contexts';
import { RecurringActions } from 'common/components/columns/formatters';
const { recurringApiRateLimit } = ApplicationSettings;

const requestKeys = {
	FETCH: 'fetch',
	KVAAS: 'kvaas',
};

class RecurringScheduleGrid extends Component {
	static contextType = SidebarContext;
	constructor(props) {
		super(props);

		const principal = principalService.get();
		const permissions = (principal && principal.idInfo && principal.idInfo.permissions) || {};

		this.state = {
			...this.initialState,
			filters: Filter,
			activeFilters: cloneDeep(Filter),
			inlineFilters: {},
			columns: Columns,
			defaultColumns: cloneDeep(Columns),
			permissions,
		};

		this.gridRef = createRef();
		this.notificationRef = createRef();

		this.components = {
			header: this.renderHeader,
			gridHeader: this.renderGridHeader,
			modal: ActionsModal,
			filter: MainFilterComponent,
			rowRenderer: ZebraRenderer,
			tooltip: GridTooltip,
			gridFooter: this.renderGridFooter,
			rowDetails: CustomerRowDetails,
			rowActions: RecurringActions,
			selectedEntriesActions: this.renderSelectedEntriesActions,
		};
	}

	get initialState() {
		return {
			data: null,
			filteredRows: [],
			expanded: {},
			fetchingData: true,
			fetchingAdditionalData: false,
			lastApiRefNum: null,
			selectedCurrency: {},
			currencyOptions: [],
			expandedRow: '',
		};
	}

	componentWillUnmount() {
		this.context.toggleSidebarExpandedClass(false);
	}

	componentDidMount = async () => {
		try {
			const [
				recurringDefaultColumns,
				recurringHiddenFields,
				columnsOrder,
				defaultValues,
				customerDisplayLabels,
			] = await this.props.makePendingRequest(
				kvaasService.get(
					kvaasResources.recurringReportDefaultColumns,
					kvaasResources.recurringReportHiddenFields,
					kvaasResources.recurringReportOrder,
					kvaasResources.recurringReportDefaultValues,
					kvaasResources.transactionDisplayLabels
				),
				requestKeys.KVAAS
			);
			const order = mapDefaultOrder(kvaasResources.recurringReportOrder.defaultData, columnsOrder);
			const columns = this.addCustomData(this.state.columns, { recurringDefaultColumns, recurringHiddenFields, order });
			this.mapCustomDisplayLabelsToCustomFields(columns, customerDisplayLabels);
			const defaultColumns = this.addCustomData(this.state.defaultColumns, {
				recurringDefaultColumns,
				recurringHiddenFields,
				order,
			});
			const queryParams = parse(this.props.location.search);
			const filters = this.addDefaultFilter(
				this.parseQueryFilters(this.state.filters, queryParams, defaultValues),
				defaultValues
			);
			const activeFilters = this.addDefaultFilter(
				this.parseQueryFilters(this.state.activeFilters, queryParams, defaultValues),
				defaultValues
			);

			this.handleRecurringFilterValues(filters, this.props.location.search, true);

			this.setState(
				{
					filters,
					activeFilters,
					columns,
					defaultColumns,
				},
				() => {
					if (this.gridRef.current) {
						this.gridRef.current.reset();
					}
				}
			);
		} catch (e) {
			this.props.handleError(e);
		}
	};

	componentDidUpdate = prevProps => {
		if (prevProps.location.key !== this.props.location.key && includes(this.props.location.search, 'refresh=true')) {
			if (this.gridRef.current) {
				this.gridRef.current.clearFilters();
				this.gridRef.current.reset();
			}
		}
	};

	getContextValue = memoize(checked => {
		return {
			toggle: this.selectAllRows,
			checked,
		};
	});

	addDefaultFilter(filters, defaultValues) {
		let activeDefault;
		if (defaultValues) {
			activeDefault = get(defaultValues, 'data.activeDefault');
		}
		const activeFilter = find(filters, f => f.key === 'active');

		activeFilter.values.active = activeDefault;
		activeFilter.hasSelection = activeDefault;
		return filters;
	}

	parseQueryFilters = (filters, params) => {
		if (isEmpty(params)) {
			return filters;
		}
		const newFilters = cloneDeep(filters);
		let anyChanged = false;

		each(newFilters, ({ values, defaultValues, defaultHasSelection }, index) => {
			each(values, (_, key) => {
				const value = params[key];

				if (toLower(key) === 'active' || value) {
					let hasSelection = true;
					newFilters[index].values[key] = hasSelection ? value : defaultValues[key];
					newFilters[index].hasSelection = hasSelection;
					anyChanged = true;
				} else {
					let defaultValue = defaultValues[key];
					newFilters[index].hasSelection = !!defaultHasSelection;

					if (!newFilters[index].hasSelection) {
						if (typeof defaultValue === 'string') {
							defaultValue = '';
						} else if (typeof defaultValue === 'object') {
							defaultValue = null;
						} else if (typeof defaultValue === 'boolean') {
							defaultValue = false;
						}
					}

					newFilters[index].values[key] = defaultValue;
				}
			});
		});

		return anyChanged ? newFilters : filters;
	};

	updateRecurringQueryFilterState = newState => {
		this.setState({ ...newState });
	};
	handleRecurringFilterValues = (filters, query = '', initialExpandRow = false) => {
		queryFilterValues(filters, this.props, this.updateRecurringQueryFilterState, query, initialExpandRow);
	};

	fetchData = async (filters, maxRecords = 1000) => {
		const { expandedRow, columns, inlineFilters } = this.state;
		this.setState({
			fetchingData: true,
			data: null,
			filteredRows: [],
			expanded: {},
			lastApiRefNum: null,
		});
		let lastApiRefNum = null;

		try {
			const filter = await this.props.makePendingRequest(compileFilter(filters), requestKeys.FETCH);
			filter.PageSize = 100;
			const data = await this.props.makePendingRequest(
				customerService.filterRecurringSchedulesAll(null, filter),
				requestKeys.FETCH
			);

			lastApiRefNum = data.refNum;

			if (data && data.xReportData) {
				data.xReportData = map(data.xReportData, row =>
					this.formatRetryAttempts(customerService.mapDefaultRetryCount(row))
				);
				data.xReportData = map(data.xReportData, this.mapRow);
			}

			if (maxRecords === 0) {
				data.xRecordsReturned = data.xReportData.length;
				data.xReportingMaxSchedules = data.xReportData.length + 1; // +1 = quick fix
			}

			const formattedColumns = this.formatColumns(columns, cloneDeep(filter));
			this.mapData(data);
			const filteredRows =
				data && data.xReportData
					? Data.Selectors.getRows({
							rows: data.xReportData,
							filters: inlineFilters,
					  })
					: [];
			if (this.gridRef.current) {
				this.gridRef.current.scrollTo({ top: 0, left: 0 });
			}

			this.setState(
				{
					originalData: cloneDeep(data),
					data,
					filteredRows,
					fetchingData: false,
					columns: formattedColumns,
					lastApiRefNum: lastApiRefNum,
					...this.getScheduleAmounts(filteredRows),
				},
				() => {
					if (this.gridRef.current) {
						if (expandedRow) {
							const row = find(filteredRows, ({ scheduleId }) => scheduleId === expandedRow);
							if (row) {
								this.gridRef.current.onRowClick(row.index, row);
								this.setState({ expandedRow: null });
							}
						}
						this.gridRef.current.handleInitialSort();
						this.gridRef.current.calculateColumnWidths();
					}
				}
			);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.setState({
					fetchingData: false,
				});
			}
		}
	};

	hasMoreData = data => !!get(data, 'nextToken');

	addCustomData = (data, { recurringDefaultColumns, recurringHiddenFields, order }) => {
		let newData = cloneDeep(data);
		let anyChanged = false;
		newData = addCustomDataType(newData, recurringDefaultColumns, (item, _, index) => {
			if (index === undefined) {
				item.visible = true;
				anyChanged = true;
				return true;
			}
		});
		newData = addCustomDataType(newData, recurringHiddenFields, (item, value, index) => {
			if (!value) return false;
			if (index !== undefined) {
				item.props.hiddenFields[index] = true;
			} else {
				item.visible = false;
				item.hideable = false;
			}
			anyChanged = true;
			return true;
		});
		newData = addCustomDataType(
			newData,
			order,
			(item, value, index) => {
				if (index === undefined) {
					item.order = value;
					anyChanged = true;
					return true;
				}
			},
			updatedData => sortBy(updatedData, 'order')
		);
		return anyChanged ? newData : data;
	};
	mapCustomDisplayLabelsToCustomFields = (columns, customerDisplayLabels) => {
		const customLabels = get(customerDisplayLabels, 'data', {});
		each(columns, column => {
			let { key } = column;
			if (includes(key, 'customerCustom')) {
				const customLabel = customLabels[`custom${parseInt(split(toLower(key), 'customercustom')[1])}`];
				if (customLabel) {
					column.name = customLabel;
				}
			}
		});
	};

	mapData = data => {
		let i = 0;
		if (data && data.xReportData && data.xReportData.length > 0) {
			each(data.xReportData, item => {
				item.isActive = toString(item.isActive);
				item.gridRowNumber = i;
				item.index = i + 1;
				item._meta = {
					isSelected: false,
				};
				i++;
			});
		}
	};

	getScheduleAmounts = (data = this.state.filteredRows) => {
		const currencyOptions = [];
		if (data && data.length > 0) {
			each(data, reportItem => {
				const { currency, amount } = reportItem;
				const item = find(currencyOptions, { label: currency });
				if (item) {
					item.amount += amount || 0;
					item.count++;
				} else {
					currencyOptions.push({
						label: currency,
						symbol: CurrencyMap.resolveCurrency(currency),
						amount: amount || 0,
						count: 1,
					});
				}
			});
		}
		const selectedCurrency =
			find(currencyOptions, ({ label }) => label === this.state.selectedCurrency.label) || currencyOptions[0] || {};
		return {
			currencyOptions,
			selectedCurrency,
		};
	};

	formatColumns = (columns, appliedFilter = null) => {
		if (appliedFilter) {
			for (let prop in appliedFilter) {
				if (appliedFilter.hasOwnProperty(prop)) {
					let column = find(columns, i => {
						return i.key.toLowerCase() === prop.toLowerCase() && !i.visible;
					});

					if (column) {
						column.visible = true;
					}
				}
			}
		}
		return columns;
	};

	resolveColumnName = column => {
		let key = column;
		switch (column) {
			case 'dateCreated':
				key = 'createdDate';
				break;
			case 'displayStartDate':
				key = 'startDate';
				break;
			case 'displayEndDate':
				key = 'endDate';
				break;
			case 'nextBillingDate':
				key = 'nextScheduledRunTime';
				break;
			default:
				break;
		}
		return key;
	};

	openDeleteSelectedRowsModal = () => {
		if (this.gridRef.current) {
			this.gridRef.current.openCloseModal({
				name: modalNames.confirmAction,
				data: {
					loadingMessage: 'Deleting Recurring Schedules',
					question: `Are you sure you want to delete the selected Recurring Schedules?`,
					onConfirm: this.deleteSelectedRows,
				},
			});
		}
	};
	refreshGridData = () => {
		const refresh = get(this.gridRef, 'current.refreshGridData', noop);
		refresh();
		this.context.toggleSidebarExpandedClass(false);
	};
	deleteSelectedRows = async () => {
		const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
		try {
			const addNotification = get(this.notificationRef, 'current.addNotification', noop);
			let selectedRows = filter(get(this.state.data, 'xReportData', []), { _meta: { isSelected: true } });

			const numberOfRequestsAvailableInTimePeriod = recurringApiRateLimit;
			const timePeriod = 1000 * 60 * 5; // 5 minutes
			const timePerRequest = timePeriod / numberOfRequestsAvailableInTimePeriod;
			let timeBudget = 0;

			while (selectedRows.length > 0) {
				if (timeBudget < 0) {
					await delay(-timeBudget);
					timeBudget = 0;
				}
				let chunkSize = 1;
				if (timeBudget > timePerRequest) {
					chunkSize = Math.floor(timeBudget / timePerRequest);
				}
				if (timeBudget > 0 && selectedRows.length < numberOfRequestsAvailableInTimePeriod) {
					chunkSize = Math.min(selectedRows.length, 50); // Have to make sure we don't experience port exhaustion or something
				}
				const rowsChunk = selectedRows.slice(0, chunkSize);
				selectedRows = selectedRows.slice(chunkSize);

				const start = Date.now();
				await Promise.all(
					map(rowsChunk, ({ scheduleId }) => customerService.deleteCustomerRecurringSchedule(scheduleId))
				);
				const elapsed = Date.now() - start;
				timeBudget += elapsed - chunkSize * timePerRequest;
			}

			addNotification({
				message: 'Recurring Schedules deleted',
				success: true,
				onClose: this.refreshGridData,
			});
		} catch (e) {
			this.props.handleError(e);
		}
	};
	selectAllRows = () => {
		const { filteredRows, data } = this.state;
		const selectAll = isEmpty(filteredRows) ? false : every(filteredRows, { _meta: { isSelected: true } });
		const newFilteredRows = map(filteredRows, row => ({ ...row, _meta: { ...row._meta, isSelected: !selectAll } }));
		const newData = { ...data };

		each(newFilteredRows, ({ scheduleId, _meta: { isSelected } }) => {
			if (isSelected === !selectAll) {
				const index = findIndex(newData.xReportData, { scheduleId });

				if (index > -1) {
					newData.xReportData[index]._meta = {
						...newData.xReportData[index]._meta,
						isSelected: !selectAll,
					};
				}
			}
		});

		this.setState({ filteredRows: newFilteredRows, data: newData });
	};

	selectRow = scheduleId => {
		const { filteredRows, data } = this.state;
		const newState = {};
		const filteredRowIndex = findIndex(filteredRows, { scheduleId });
		const rowIndex = findIndex(data.xReportData, { scheduleId });

		if (filteredRowIndex > -1) {
			const rows = [...filteredRows];
			rows[filteredRowIndex] = {
				...filteredRows[filteredRowIndex],
				_meta: {
					...filteredRows[filteredRowIndex]._meta,
					isSelected: !filteredRows[filteredRowIndex]._meta.isSelected,
				},
			};
			newState.filteredRows = rows;
		}

		if (rowIndex > -1) {
			const newData = { ...data };
			newData.xReportData[rowIndex] = {
				...newData.xReportData[rowIndex],
				_meta: {
					...newData.xReportData[rowIndex]._meta,
					isSelected: !filteredRows[filteredRowIndex]._meta.isSelected,
				},
			};
			newState.data = newData;
		}

		this.setState(newState);
	};

	mapCellArgs = (rowId, row) => {
		if (rowId < 0) {
			return;
		}
		return {
			rowData: row,
			expandArgs: {
				children: [
					{
						isDetails: true,
						row: row,
					},
				],
			},
		};
	};

	onInfoHover = (infoDimensions, tooltip) => {
		this.setState({ tooltipProps: { infoDimensions, tooltip } });
	};

	refetchData = () => {
		this.fetchData(this.state.activeFilters);
	};

	handleChange = changes => {
		const { filters, data } = this.state;
		const newState = {};
		each(changes, ({ key, value }) => {
			if (key === 'expanded') {
				const expandedIndex = toNumber(findKey(value, item => !!item));
				const expandedRow = find(data.xReportData, ({ index }) => index === expandedIndex);
				const query = parse(this.props.location.search);
				if (expandedRow) {
					query.expandedRow = expandedRow.scheduleId;
				} else {
					delete query.expandedRow;
				}
				this.handleRecurringFilterValues(filters, stringify(query));
			}
			if (key === 'data' || key === 'inlineFilters') {
				let filters, data;
				if (key === 'data') {
					filters = this.state.inlineFilters;
					data = value;
				} else {
					filters = value;
					data = this.state.data;
				}
				newState.filteredRows =
					data && data.xReportData
						? Data.Selectors.getRows({
								rows: data.xReportData,
								filters,
						  })
						: [];
				const { currencyOptions, selectedCurrency } = this.getScheduleAmounts(newState.filteredRows);
				newState.currencyOptions = currencyOptions;
				newState.selectedCurrency = selectedCurrency;
			}
			newState[key] = value;
		});
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	handleCurrencyChange = selectedCurrency => {
		this.setState({ selectedCurrency }, () => {
			if (this.gridRef.current) {
				this.gridRef.current.forceUpdate();
			}
		});
	};

	showLoader = fetchingAdditionalData => this.setState({ fetchingAdditionalData });

	mapRow = row => ({
		...row,
		handleChange: this.selectRow,
		refreshGridData: this.refreshGridData,
		onInfoHover: this.onInfoHover,
		updateRows: this.updateRows,
		isExpandable: true,
		showLoader: this.showLoader,
		openCloseModal: this.openCloseModal,
		history: this.props.history,
		handleError: this.props.handleError,
		deleteAction: {
			key: 'deleteSchedule',
			tooltip: 'Delete schedule',
		},
		openTotalChargesPopup: () => this.openTotalChargesPopup(row),
	});

	formatRetryAttempts = row => {
		if (row.failedTransactionRetryTimes) {
			const attempts = row.failedAttemptsInCurrentInterval || 0;
			row.failedTransactionRetryTimes = `${attempts} out of ${row.failedTransactionRetryTimes}`;
		}
		return row;
	};

	openCloseModal = params => (this.gridRef.current ? this.gridRef.current.openCloseModal(params) : noop);

	openTotalChargesPopup = row => this.openCloseModal({ name: modalNames.totalCharges, data: { row } });

	updateRows = rows => {
		const { data, filteredRows } = this.state;
		const newData = cloneDeep(data);
		const newFilteredRows = cloneDeep(filteredRows);
		each(rows, row => {
			const mappedRow = this.mapRow(row);
			newData.xReportData = newData.xReportData;
			const dirtiedIndex = findIndex(newData.xReportData, item => item.scheduleId === row.scheduleId);
			if (dirtiedIndex > -1) {
				const oldRow = newData.xReportData[dirtiedIndex];
				mappedRow.gridRowNumber = oldRow.gridRowNumber;
				mappedRow.index = oldRow.index;
				mappedRow.isDetails = oldRow.isDetails;
				mappedRow._meta = oldRow._meta;
				newData.xReportData[dirtiedIndex] = this.formatRetryAttempts(customerService.mapDefaultRetryCount(mappedRow));
			}
			const dirtiedFilteredIndex = findIndex(newFilteredRows, item => item.scheduleId === row.scheduleId);
			if (dirtiedFilteredIndex > -1) {
				newFilteredRows[dirtiedFilteredIndex] = mappedRow;
			}
		});
		this.setState({
			data: newData,
			filteredRows: newFilteredRows,
		});
	};

	generateFrequencyOptionKey = ({ intervalCount, intervalType }) => {
		return `Every ${intervalCount} ${intervalType}${intervalCount > 1 ? 's' : ''}`;
	};

	generateFrequencyColumnOptions = rows => {
		const options = [];
		each(rows, ({ intervalType, intervalCount }) => {
			const generatedKey = this.generateFrequencyOptionKey({ intervalType, intervalCount });
			const existsOption = find(options, ({ key }) => key === generatedKey);
			if (!existsOption) {
				options.push({
					key: generatedKey,
					label: generatedKey,
				});
			}
		});
		return options;
	};

	getInlineFilterValues = (filterId, rows) => {
		const isFreuquencyColumn = filterId === 'frequency';
		return isFreuquencyColumn ? this.generateFrequencyColumnOptions(rows) : get(validInlineFilterValues, filterId);
	};

	renderSelectedEntriesActions = () => {
		const selectedRows = filter(get(this.state.data, 'xReportData', []), { _meta: { isSelected: true } });
		const hasSelectedRows = !isEmpty(selectedRows);
		const rowsCount = selectedRows.length;

		return (
			hasSelectedRows && (
				<div className="l--main__footer">
					<p className="type--p2 type--color--text--light">
						Selected:{' '}
						{rowsCount && (
							<span className="type--p2 type--p2--medium type--color--text">
								{rowsCount} recurring {rowsCount === 1 ? 'schedule' : 'schedules'}
							</span>
						)}
					</p>
					<button
						onClick={this.openDeleteSelectedRowsModal}
						className="btn btn--med btn--primary datatooltip--top-left datatooltip--auto datatooltip--w--150"
						data-tooltip="Delete selected entries"
					>
						Delete
					</button>
				</div>
			)
		);
	};

	renderHeader = ({ refreshGridData }) => {
		const {
			permissions: { allowCcSave, allowCheckSave },
			expanded,
		} = this.state;
		const hasIfields = !!document.querySelector('.newtransaction__iframe') && !isEmpty(expanded);
		const disabled = !allowCcSave && !allowCheckSave;
		const tooltip = disabled ? 'Permission required' : null;
		return (
			<div className="filter__container__header__item">
				<AddEditCustomer
					refreshGrid={refreshGridData}
					advancedView={false}
					notificationRef={this.notificationRef}
					type="schedules"
					trigger={props => (
						<button
							data-tooltip={tooltip}
							disabled={(!allowCcSave && !allowCheckSave) || hasIfields}
							className="btn btn--primary btn--med"
							{...props}
						>
							New
						</button>
					)}
				/>
			</div>
		);
	};

	renderGridHeader = ({ refreshGridData }) => {
		const {
			permissions: { allowCcSave, allowCheckSave },
			expanded,
		} = this.state;
		const hasIfields = !!document.querySelector('.newtransaction__iframe') && !isEmpty(expanded);
		const noPermissions = !allowCcSave && !allowCheckSave;
		const tooltip = noPermissions ? 'Permission required' : null;
		return (
			<div className="filter__container__header__item datatooltip--down" data-tooltip={tooltip}>
				<AddEditCustomer
					refreshGrid={refreshGridData}
					advancedView={false}
					notificationRef={this.notificationRef}
					trigger={props => (
						<button
							disabled={noPermissions || hasIfields}
							className="btn btn--med btn--primary spc--left--sml--alt"
							{...props}
						>
							<i className="icon icon--sml icon--add--white"></i>
							<span>New Customer</span>
						</button>
					)}
					type="schedules"
				/>
			</div>
		);
	};

	renderSymbol = ({ data: { symbol }, ...props }) => (
		<components.SingleValue {...props}>{symbol}</components.SingleValue>
	);

	renderGridFooter = () => {
		const {
			selectedCurrency,
			selectedCurrency: { amount, symbol },
			currencyOptions,
		} = this.state;
		return (
			<React.Fragment>
				{find(this.state.columns, column => column.key === 'amount').visible ? (
					<div className="react-grid__footer__details">
						<div className="react-grid__footer__details__item">
							<span className="spc--right--tny">Amount:</span>
							{currencyOptions.length > 1 ? (
								<Select
									name="currency"
									id="currency"
									className="display--ib react-select-container react-select-container--sml spc--right--sml"
									value={selectedCurrency}
									options={currencyOptions}
									onChange={this.handleCurrencyChange}
									components={{
										SingleValue: this.renderSymbol,
									}}
									menuPlacement="auto"
									getOptionValue={selectedCurrency => selectedCurrency.label}
								/>
							) : (
								<strong className="align--h--right">{symbol}</strong>
							)}
							<strong>
								<NumberFormat
									value={amount}
									displayType="text"
									thousandSeparator={true}
									decimalScale={2}
									fixedDecimalScale={true}
								/>
							</strong>
						</div>
					</div>
				) : null}
			</React.Fragment>
		);
	};

	render() {
		const {
			fetchingData,
			fetchingAdditionalData,
			filteredRows,
			columns,
			defaultColumns,
			data,
			inlineFilters,
			expanded,
			filters,
			activeFilters,
			lastApiRefNum,
			tooltipProps,
		} = this.state;

		return (
			<SelectAllContext.Provider
				value={this.getContextValue(
					isEmpty(filteredRows) ? false : every(filteredRows, { _meta: { isSelected: true } })
				)}
			>
				<Notification ref={this.notificationRef} />
				<GridComponent
					className="react-grid--two-cols"
					emptyMessage="You should change your filter options"
					fetchingData={fetchingData}
					fetchingAdditionalData={fetchingAdditionalData}
					filteredRows={filteredRows}
					columns={columns}
					defaultColumns={defaultColumns}
					data={data}
					inlineFilters={inlineFilters}
					resolveColumnName={this.resolveColumnName}
					components={this.components}
					onChange={this.handleChange}
					isExpandable={true}
					expanded={expanded}
					enableExport={true}
					enablePrint={true}
					title="Recurring Schedules"
					printTitle="Recurring schedules"
					type="schedules"
					filters={filters}
					activeFilters={activeFilters}
					enableFilters={true}
					fetchData={this.refetchData}
					lastApiRefNum={lastApiRefNum}
					showResults={true}
					mapCellArgs={this.mapCellArgs}
					showPrintDropdown={false}
					filterColumns={true}
					ref={this.gridRef}
					initialFetch={false}
					columnFilterType="/settings/user-settings/recurring-settings"
					kvaasResourceType="recurring"
					useInlineFilters={true}
					fetchExportData={{ current: exportService.mapScheduleData, all: exportService.getScheduleData }}
					hasPaging={true}
					hasMoreData={this.hasMoreData}
					expandInSidebar={true}
					tooltipProps={tooltipProps}
					getInlineFilterValues={this.getInlineFilterValues}
					syncQueryFilters={true}
					queryFilterValues={this.handleRecurringFilterValues}
					tabs={this.props.tabs}
				/>
			</SelectAllContext.Provider>
		);
	}
}

RecurringScheduleGrid.propTypes = {
	handleError: PropTypes.func,
	makePendingRequest: PropTypes.func,
	location: PropTypes.object,
	history: PropTypes.object,
	tabs: PropTypes.array.isRequired,
};

export default withError(withCancelable(RecurringScheduleGrid));
