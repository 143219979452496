import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { invokeIfFunction } from 'common/utilities';

class ConfirmActionComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
		};
	}

	onConfirm = () => {
		const { isLoading } = this.state;
		if (isLoading) {
			return;
		}
		invokeIfFunction(this.props.setLoading, true);

		this.setState(
			{
				isLoading: true,
			},
			async () => {
				const { onConfirm, notificationHandler, notificationRef, closeModal } = this.props;
				let result;
				try {
					result = await onConfirm();
				} catch (e) {
					result = e;
				} finally {
					if (notificationHandler) {
						const notification = notificationHandler(result);
						notificationRef.addNotification(notification);
					}
					invokeIfFunction(this.props.setLoading, false);
					this.setState(
						{
							isLoading: false,
						},
						closeModal
					);
				}
			}
		);
	};

	render() {
		const { isLoading } = this.state;
		const { title, question, loadingMessage } = this.props;

		return (
			<Fragment>
				{isLoading ? (
					<div className="modal__body type--med">
						<div className="loader__holder grid-sidebar__loader">
							<div className="loader__spinner"></div>
						</div>
						<div className="type--wgt--medium type--center">{loadingMessage}</div>
					</div>
				) : (
					<Fragment>
						{title && (
							<div className="modal__header">
								<h4 className="modal__header__title">{title}</h4>
							</div>
						)}
						<div className="modal__body">{question}</div>
						<div className="modal__footer">
							<button onClick={this.onConfirm} disabled={isLoading} className="btn btn--med btn--primary">
								Confirm
							</button>
						</div>
					</Fragment>
				)}
			</Fragment>
		);
	}
}

ConfirmActionComponent.defaultProps = {
	loadingMessage: 'Please wait',
};

ConfirmActionComponent.propTypes = {
	title: PropTypes.string,
	question: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
	loadingMessage: PropTypes.string,
	onConfirm: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
	notificationRef: PropTypes.object.isRequired,
	notificationHandler: PropTypes.func,
	setLoading: PropTypes.func,
};

export default ConfirmActionComponent;
