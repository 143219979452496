import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import { find, toLower, map, every, size, each } from 'lodash';

import AddEditCustomerPaymentsAddEdit from './add-edit';
import { paymentMethod } from '../../../../../components/new-transaction/constants';
import PaymentsPreview from './components/payments-preview';
import SegmentedControl from './components/SegmenteControl';
import ApplyForAch from './components/apply-for-ach';

class AddEditCustomerPayments extends Component {
	constructor(props) {
		super(props);

		this.paymentsRef = createRef();
	}

	componentDidUpdate = () => {
		const { isCanadian, selectedTab } = this.props;
		if (selectedTab === paymentMethod.CHECK && isCanadian) {
			this.handleSwitchPaymentMethod({ target: { name: 'cc' } });
		}
	};

	toggleExpandCollapseAll = allExpanded => {
		this.props.onChange('expandAllPayments', '_meta.paymentExpanded', !allExpanded);
	};

	handleSwitchPaymentMethod = ({ target: { name } }) => {
		const { switchTab, selectedTab } = this.props;
		if (name !== selectedTab) {
			return switchTab(name);
		}
	};

	handleDelete = id => {
		this.props.handleDelete(id);
	};

	onChange = async (id, field, value) => {
		if (field === 'isDefaultPaymentMethod') {
			const { payments } = this.props;
			for (let i in payments) {
				const payment = payments[i];
				if (payment._meta.id !== id) {
					if (payment[field]) {
						await this.props.onChange(payment._meta.id, field, false, true);
					}
					if (payment._meta.tentativeDelete) {
						await this.props.onChange(payment._meta.id, '_meta.tentativeDelete', false);
					}
				} else if (!payment[field]) {
					await this.props.onChange(id, '_meta.deleted', false);
					await this.props.onChange(id, '_meta.tentativeDelete', false);
				}
			}
		}

		await this.props.onChange(id, field, value);
	};

	getShowExpandAll = () => {
		const { paymentMethods, selectedTab } = this.props;
		const ccTypes = [];
		const checkTypes = [];

		if (size(paymentMethods)) {
			each(paymentMethods, paymentMethod =>
				each(paymentMethod, method => {
					if (toLower(method.tokenType) === 'cc') {
						ccTypes.push(method);
					} else {
						checkTypes.push(method);
					}
				})
			);
		}
		if (toLower(selectedTab) === 'check') {
			return size(checkTypes) > 1;
		}

		return size(ccTypes) > 1;
	};

	savePayment = id => {
		this.props.savePayment(id);
	};
	renderPayments = defaultPaymentMethod => {
		const {
			payments,
			selectedTab,
			type,
			requiredFields,
			advancedView,
			advancedViewByDefault,
			isCanadian,
			isExpanded,
			handleOpenCloseModal,
			deactivateActiveSchedulesAndRemovePaymentMethod,
			isViewOnly,
			accountTypeConstants,
		} = this.props;
		return payments && payments.length > 0 ? (
			map(payments, (item, index) => {
				const focusNew = index === 0 && !item.customerId;
				item._meta.index = index;

				return (
					<div style={toLower(item.tokenType) === selectedTab ? null : { display: 'none' }} key={item._meta.id}>
						<AddEditCustomerPaymentsAddEdit
							type={type}
							focusOnMount={focusNew}
							key={item._meta.id}
							id={item._meta.id}
							hasMultiplePayments={payments.length > 1}
							payment={item}
							handleDelete={this.handleDelete}
							onChange={this.onChange}
							savePayment={this.savePayment}
							requiredFields={requiredFields}
							defaultPaymentMethodExists={!!defaultPaymentMethod}
							advancedView={advancedView}
							advancedViewByDefault={advancedViewByDefault}
							isCanadian={isCanadian}
							isExpanded={isExpanded}
							ref={item._meta.created ? this.paymentsRef : null}
							handleOpenCloseModal={handleOpenCloseModal}
							deactivateActiveSchedulesAndRemovePaymentMethod={deactivateActiveSchedulesAndRemovePaymentMethod}
							isViewOnly={isViewOnly}
							accountTypeConstants={accountTypeConstants}
							onPaymentAccountTypeChange={this.props.onPaymentAccountTypeChange}
							isInvalidField={this.props.isInvalidField}
						/>
					</div>
				);
			})
		) : (
			<div className="type--p3 type--color--text--light spc--bottom--sml--alt type--center">
				No {selectedTab === 'cc' ? 'credit card' : 'check'} payment methods linked to this customer
			</div>
		);
	};

	render = () => {
		const {
			payments,
			selectedTab,
			refNum,
			customerId,
			advancedView,
			isCanadian,
			renderAddNewPayment,
			isPreview,
			setDefaultPaymentMethod,
			permissions,
			addNewPayment,
		} = this.props;
		const showExpandAll = this.getShowExpandAll();
		const defaultPaymentMethod = find(payments, ({ paymentMethodId }) => !!paymentMethodId);
		const allExpanded = every(payments, ({ _meta: { paymentExpanded } }) => paymentExpanded);

		return (
			<Fragment>
				{isPreview ? (
					<PaymentsPreview
						payments={payments}
						setDefaultPaymentMethod={setDefaultPaymentMethod}
						permissions={permissions}
						addNewPayment={addNewPayment}
						isAchEnabled={this.props.isAchEnabled}
					/>
				) : (
					<Fragment>
						<div className="info-panel__section">
							<SegmentedControl
								selectedTab={selectedTab}
								isCanadian={isCanadian}
								handleSwitchPaymentMethod={this.handleSwitchPaymentMethod}
							/>
							<div className="flex--primary flex--gap--sml--alt spc--top--med">
								{(customerId || refNum) && (
									<Fragment>
										{refNum && <label className="display--b type--p2 type--color--text--light">#{refNum}</label>}
									</Fragment>
								)}
								{showExpandAll && (
									<button
										className="btn btn--link align--h--right"
										onClick={() => this.toggleExpandCollapseAll(allExpanded)}
									>
										<i className="icon icon--sml icon--expand-vertical--primary spc--right--tny"></i>
										{`${allExpanded ? 'Collapse' : 'Expand'} all`}
									</button>
								)}
							</div>
						</div>

						{selectedTab === 'check' && !this.props.isAchEnabled() ? (
							<ApplyForAch advancedView={advancedView} />
						) : (
							this.renderPayments(defaultPaymentMethod)
						)}

						{renderAddNewPayment()}
					</Fragment>
				)}
			</Fragment>
		);
	};
}

AddEditCustomerPayments.propTypes = {
	type: PropTypes.string,
	payments: PropTypes.array,
	refNum: PropTypes.string,
	customerId: PropTypes.string,
	onChange: PropTypes.func,
	handleDelete: PropTypes.func.isRequired,
	savePayment: PropTypes.func.isRequired,
	selectedTab: PropTypes.string.isRequired,
	switchTab: PropTypes.func.isRequired,
	addNewPayment: PropTypes.func.isRequired,
	requiredFields: PropTypes.object,
	advancedView: PropTypes.bool.isRequired,
	advancedViewByDefault: PropTypes.bool.isRequired,
	isCanadian: PropTypes.bool,
	permissions: PropTypes.object,
	isExpanded: PropTypes.bool,
	isViewOnly: PropTypes.bool,
	isAchEnabled: PropTypes.func.isRequired,
	renderAddNewPayment: PropTypes.func.isRequired,
	handleOpenCloseModal: PropTypes.func.isRequired,
	deactivateActiveSchedulesAndRemovePaymentMethod: PropTypes.func.isRequired,
	paymentMethods: PropTypes.array,
	accountTypeConstants: PropTypes.object,
	onPaymentAccountTypeChange: PropTypes.func.isRequired,
	isPreview: PropTypes.bool,
	setDefaultPaymentMethod: PropTypes.func.isRequired,
	isInvalidField: PropTypes.func.isRequired,
};

export default AddEditCustomerPayments;
