import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import AddEditCustomerSchedulesAddEdit from './add-edit';

class AddEditCustomerSchedules extends Component {
	constructor(props) {
		super(props);
		this.scheduleToFocusRef = {};
		this.isFocusScheduleRefSet = false;
	}

	setScheduleRef = (element, scheduleId) => {
		const { schedule } = this.props;
		if (!this.isFocusScheduleRefSet && schedule) {
			if (schedule.scheduleId === scheduleId) {
				this.scheduleToFocusRef = element;
				this.isFocusScheduleRefSet = true;
			}
		}
	};

	focusSchedule = () => {
		if (this.scheduleToFocusRef && this.isFocusScheduleRefSet) {
			if (window.innerWidth < 1200) {
				const scrollableArea = document.querySelector('.grid-sidebar');
				const scheduleTop = this.scheduleToFocusRef.offsetTop;
				const offsetHeader = 82;
				scrollableArea.scrollTop = Math.max(scheduleTop, offsetHeader) - offsetHeader;
			} else {
				this.scheduleToFocusRef.scrollIntoView();
			}
		}
	};

	render() {
		const {
			schedules,
			applyTemplate,
			advancedView,
			template,
			isExpanded,
			permissions,
			hideSkipSabbath,
			saveSchedule,
			handleOpenPreview,
			handleOpenViewPayments,
			onChange,
			runPopoverAction,
			handleOpenCloseModal,
			renderAddRecurringSchedule,
			scheduleToFocus,
			general,
			onGeneralChange,
			isLoading,
			convenienceFees,
			onBeforeCalculateConvenience,
			includeExcludeConvenience,
			customDisplayLabels,
			isViewOnly,
			paymentMethods,
			isPreview,
		} = this.props;

		return (
			<Fragment>
				{renderAddRecurringSchedule()}
				{schedules && schedules.length > 0 ? (
					schedules.map((item, index) => {
						return (
							<div key={item._meta.id} ref={elem => this.setScheduleRef(elem, item.scheduleId)}>
								<AddEditCustomerSchedulesAddEdit
									index={index}
									applyTemplate={applyTemplate}
									advancedView={advancedView}
									gridHolder={this.props.gridHolder}
									handleOpenPreview={handleOpenPreview}
									handleOpenViewPayments={handleOpenViewPayments}
									key={item._meta.id}
									id={item._meta.id}
									schedule={item}
									general={general}
									onChange={onChange}
									onGeneralChange={onGeneralChange}
									saveSchedule={saveSchedule}
									template={template}
									isExpanded={isExpanded}
									permissions={permissions}
									hideSkipSabbath={hideSkipSabbath}
									runPopoverAction={runPopoverAction}
									handleOpenCloseModal={handleOpenCloseModal}
									scheduleToFocus={scheduleToFocus}
									isLoading={isLoading}
									convenienceFees={convenienceFees}
									onBeforeCalculateConvenience={onBeforeCalculateConvenience}
									includeExcludeConvenience={includeExcludeConvenience}
									customDisplayLabels={customDisplayLabels}
									isViewOnly={isViewOnly}
									paymentMethods={paymentMethods}
									isPreview={isPreview}
								/>
							</div>
						);
					})
				) : (
					<div className="info-panel__section action">
						<p className="type--p3 type--color--text--light">No recurring schedules linked to this customer</p>
					</div>
				)}
			</Fragment>
		);
	}
}

AddEditCustomerSchedules.propTypes = {
	schedules: PropTypes.array.isRequired,
	general: PropTypes.object,
	runPopoverAction: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onGeneralChange: PropTypes.func,
	handleOpenPreview: PropTypes.func.isRequired,
	handleOpenViewPayments: PropTypes.func.isRequired,
	paymentMethods: PropTypes.array.isRequired,
	handleOpenCloseModal: PropTypes.func.isRequired,
	gridHolder: PropTypes.object,
	advancedView: PropTypes.bool.isRequired,
	permissions: PropTypes.object,
	applyTemplate: PropTypes.func.isRequired,
	template: PropTypes.object,
	saveSchedule: PropTypes.func,
	isExpanded: PropTypes.bool,
	isViewOnly: PropTypes.bool,
	hideSkipSabbath: PropTypes.bool,
	renderAddRecurringSchedule: PropTypes.func.isRequired,
	schedule: PropTypes.object,
	scheduleToFocus: PropTypes.string,
	isLoading: PropTypes.bool.isRequired,
	convenienceFees: PropTypes.object,
	onBeforeCalculateConvenience: PropTypes.func.isRequired,
	includeExcludeConvenience: PropTypes.func.isRequired,
	customDisplayLabels: PropTypes.any,
	isPreview: PropTypes.bool,
};

export default AddEditCustomerSchedules;
