import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { Modal } from 'common/components/modal/index';
import { withError } from 'common/components/error';

class ConfirmDeleteModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
		};
	}

	handleOpenModal = async () => {
		if (this.props.disabled) {
			return;
		}
		const newState = { visible: true };
		this.setState(newState);
	};

	handleCloseModal = async () => {
		const { onCancel } = this.props;
		const newState = {};
		newState.visible = false;
		await onCancel();
		this.setState(newState);
	};

	confirmationHandler = async () => {
		const { onConfirm, data } = this.props;
		const newState = {};
		newState.visible = false;
		await onConfirm(data);
		this.setState(newState);
	};

	render() {
		const { className, children, isLoading, shouldHideModal } = this.props;
		const { visible } = this.state;

		return (
			<React.Fragment>
				<button
					onClick={this.handleOpenModal}
					type="button"
					className={className}
					data-tooltip={this.props['data-tooltip']}
				>
					{children}
				</button>
				<Modal
					isOpen={visible}
					onClose={this.handleCloseModal}
					overlayClassName={shouldHideModal() ? 'modal--hide' : 'modal__overlay'}
				>
					<div className="modal__header">
						<h4>Delete Key</h4>
					</div>
					<div className="modal__body">
						<p className="type--med">
							Are you sure you want to delete this key? This action cannot be undone. Once the key is deleted, it will
							be permanently removed from the system. Please confirm your decision.
						</p>
					</div>
					<div className="modal__footer">
						<button
							type="button"
							tabIndex="-1"
							className="btn btn--med btn--primary"
							onClick={this.confirmationHandler}
							disabled={isLoading}
						>
							Delete
						</button>
					</div>
				</Modal>
			</React.Fragment>
		);
	}
}

ConfirmDeleteModal.defaultProps = {
	onCancel: noop,
};

ConfirmDeleteModal.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	onConfirm: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
	children: PropTypes.any,
	className: PropTypes.string,
	data: PropTypes.object,
	shouldHideModal: PropTypes.func,
	disabled: PropTypes.bool,
	['data-tooltip']: PropTypes.string,
};

export default withError(ConfirmDeleteModal);
