import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { get, isNumber } from 'lodash';

const arrowClassNames = {
	NONE: 'down',
	ASC: 'top--primary',
	DESC: 'down--primary',
};

class ColumnHeaderComponent extends Component {
	constructor(props) {
		super(props);

		this.nameRef = createRef();
	}

	get style() {
		const { column } = this.props;
		const ref = get(this.nameRef, 'current', false);
		let style = {};

		if (ref) {
			const sortIconsWidth = isNumber(column.customWidth) ? column.customWidth : 45;
			const width = this.props.column.width - sortIconsWidth;
			const isOverflowing = ref.scrollWidth > width;

			if (isOverflowing) {
				style = {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					width,
				};
			}
		}

		return style;
	}

	render() {
		const {
			column: { name, sortable, alignHeaderRight },
			sortDirection,
		} = this.props;

		return (
			<div className={`react-grid-HeaderCell-content${alignHeaderRight ? ' type--right' : ''}`}>
				<p ref={this.nameRef} className="name" style={this.style}>
					{name}
				</p>
				{sortable && (
					<div
						data-tooltip="Column Sorting"
						className="datatooltip--left datatooltip--auto datatooltip--no-wrap sorting-icon-wrapper"
					>
						<div>
							<i className={`icon icon--chevron--${arrowClassNames[sortDirection]} sorting-icon`}></i>
						</div>
					</div>
				)}
			</div>
		);
	}
}

ColumnHeaderComponent.propTypes = {
	column: PropTypes.object,
	sortDirection: PropTypes.string,
};

export default ColumnHeaderComponent;
