import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, find } from 'lodash';

import { Modal } from 'common/components/modal';
import { kvaasService, principalService } from 'common/services';
import { withLoader } from 'common/components/loader';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { initialPageOptions, kvaasResources, logger } from 'common/utilities';
import { withRouter } from 'react-router-dom';

const requestKeys = {
	KVAAS: 'kvaas',
};

class UpgradeNotificationModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			principal: {},
			userSettings: {},
			oldData: null,
			isLoading: true,
		};
	}

	componentDidMount = async () => {
		try {
			this.subscription = principalService.subscribe(principal => this.setState({ principal }));
			const principal = principalService.get();
			const [userSettings] = await this.props.makePendingRequest(
				kvaasService.get(kvaasResources.userSettings),
				requestKeys.KVAAS
			);

			this.setState({
				principal,
				userSettings,
				isLoading: false,
				oldData: userSettings,
			});
		} catch (e) {
			this.props.handleError(e);
			this.setState({ isLoading: false });
		}
	};

	componentWillUnmount() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	redirect = async () => {
		const { history, location } = this.props;
		let search = '';
		let redirectUrl = '/';
		let additionalState = {};

		try {
			const route = this.getInitialRoute();
			if (route) {
				redirectUrl = route.path;
				additionalState = route.state;
			}
		} catch (error) {
			logger.logError({
				message: 'Initial page redirect error.',
				errorDetails: error,
			});
		} finally {
			this.setState({ isLoading: false });
		}

		const returnUrl = get(location, 'state.returnUrl');
		if (returnUrl) {
			[redirectUrl, search] = returnUrl.split('?');
			search = search ? `?${search}` : '';
		}

		history.push({
			pathname: redirectUrl,
			search,
			...additionalState,
		});
	};

	getInitialRoute = () => {
		const initial = get(this.state.userSettings, 'data.initialPage');
		return initial ? find(initialPageOptions, { key: initial }) : null;
	};

	render() {
		const { isLoading } = this.state;

		return (
			<Modal isOpen={true} onClose={this.redirect} isLoading={isLoading}>
				<div className="modal__header">
					<h2 className="modal__header__title">Important: Your Portal is Moving Soon!</h2>
				</div>
				<div className="modal__body">
					<div className="notes notes--primary spc--bottom--lrg">
						<i className="icon"></i>
						<p className="type--p2">
							Starting March 18, 2025, the Merchant Portal will move to portal.solapayments.com.
						</p>
					</div>
					<h3 className="type--p1 type--wgt--bold spc--bottom--sml">Why the Change?</h3>
					<p className="spc--bottom--med">
						Cardknox has evolved into Sola, bringing you a refreshed, modern experience while keeping the features you
						trust. This update is based on extensive user feedback and beta testing to improve usability.
					</p>
					<div className="flex--primary flex--top flex--gap--sml flex--nowrap spc--bottom--sml">
						<i className="icon icon--sml icon--regular--check spc--top--tny flex--no-shrink"></i>
						<p>Your login credentials, PaymentSITE links, and mobile app remain the same.</p>
					</div>
					<div className="flex--primary flex--top flex--gap--sml flex--nowrap spc--bottom--lrg">
						<i className="icon icon--sml icon--regular--check spc--top--tny flex--no-shrink"></i>
						<p>Update your bookmarks and password manager to portal.solapayments.com for easy access.</p>
					</div>
					<div className="notes notes--default">
						<p className="type--p3">
							Security Reminder: This is an official update from Sola/Cardknox. If you have any concerns about fraud,
							visit our official rebrand page to verify the transition:{' '}
							<a
								className="btn btn--anchor"
								href="https://solapayments.com/meet-the-brand/"
								rel="noopener noreferrer"
								target="_blank"
							>
								Meet the Brand
							</a>
						</p>
					</div>
				</div>
			</Modal>
		);
	}
}

UpgradeNotificationModal.propTypes = {
	handleError: PropTypes.func.isRequired,
	makePendingRequest: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.shape({
		state: PropTypes.shape({
			returnUrl: PropTypes.string,
		}),
	}),
};

export default withError(withLoader(withCancelable(withRouter(UpgradeNotificationModal))));
