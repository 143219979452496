import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'common/components/select';
import { uniq, sortBy } from 'lodash';

const YearSelection = ({ data, onYearSelect, selectedYear }) => {
	const [years, setYears] = useState([]);

	useEffect(() => {
		if (data && data.xReportData && data.xReportData.length > 0) {
			const availableYears = uniq(data.xReportData.map(item => item.year));
			const sortedYears = sortBy(availableYears, year => -parseInt(year));

			setYears(
				sortedYears.map(year => ({
					value: year,
					label: year.toString(),
				}))
			);
		}
	}, [data]);

	const handleYearChange = selectedOption => {
		const year = selectedOption ? selectedOption.value : null;
		onYearSelect(year);
	};

	const selectedOption = selectedYear ? { value: selectedYear, label: selectedYear.toString() } : null;

	return (
		<div className="filter__toolbar__datepicker">
			<label className="type--color--text">Date Range:</label>
			<Select
				className="react-select-container"
				classNamePrefix="react-select"
				value={selectedOption}
				onChange={handleYearChange}
				options={years}
				isClearable={true}
				placeholder="All Years"
			/>
		</div>
	);
};

YearSelection.propTypes = {
	data: PropTypes.object,
	onYearSelect: PropTypes.func.isRequired,
	selectedYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default YearSelection;
