import React, { Component } from 'react';
import PropTypes, { string } from 'prop-types';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { trim, split, toLower, isArray, includes, isEmpty, compact } from 'lodash';

import { CurrencyMap, kvaasResources, mapAvsItem } from '../../../Common/utilities';
import PaymentTransactionTypes from '../../utilities/paymentTransactionTypes';
import Logo from '../print-grid-data/logo';
import HeaderBackground from '../print-grid-data/transaction-receipt-header';
import { withCancelable } from '../../../Common/components/cancelable';
import { kvaasService, principalService } from '../../services';
import { checkIfCanadian } from 'common/utilities/check-if-canadian';
import renderCustomFields from './printUtils/printUtils';
import { setToCanadianVerbiage } from 'common/utilities/setToCanadianVerbiage';
import { exportService } from '../export/exportService';
import ImageWithFallback from '../ImageFallback';

const statusClasses = {
	Approved: 'newtransaction__print__status approved',
	Declined: 'newtransaction__print__status declined',
	Pending: 'newtransaction__print__status pending',
	Error: 'newtransaction__print__status error icon--middle',
};

class PrintTransactionReceiptComponent extends Component {
	isCanadian = checkIfCanadian();
	constructor(props) {
		super(props);
		this.state = {
			customDisplayLabels: null,
		};
	}

	async componentDidMount() {
		const [customDisplayLabels] = await this.props.makePendingRequest(
			kvaasService.get(kvaasResources.transactionDisplayLabels),
			'fetch'
		);
		this.setState({
			customDisplayLabels: customDisplayLabels.data,
		});
	}

	get currency() {
		return this.props.data && this.props.data.currency;
	}

	get currencyCode() {
		return CurrencyMap.resolveCurrency(this.currency);
	}

	renderReceiptMultipleValues = (name, values) => {
		if (isArray(values)) {
			return values.map((e, index) => (
				<div key={index} className="type--color--text--regular">
					{e}
				</div>
			));
		} else {
			return (
				<div className="type--color--text--regular spc--bottom--nano">
					{includes(['electronic transfer fee', 'original amount', 'service fee', 'tax'], toLower(name))
						? this.renderNumberFormat(values)
						: values}
				</div>
			);
		}
	};

	renderNumberFormat = value => {
		return (
			<NumberFormat
				prefix={this.currencyCode}
				value={value}
				displayType="text"
				thousandSeparator={true}
				decimalScale={2}
				fixedDecimalScale={true}
			/>
		);
	};

	renderStatus() {
		const {
			data: { isVoid, isRefund, isRefunded, canRefundAmount, previouslyRefundedAmount, xResponseResult },
		} = this.props;
		let statusImage = null;

		if (isVoid) {
			statusImage = <img src="/static/media/tags/void.svg" className="w--48p spc--top--tny" />;
		} else if (isRefund) {
			statusImage = <img src="/static/media/tags/refund.svg" className="w--48p spc--top--tny" />;
		} else if (isRefunded) {
			if (canRefundAmount > 0 && previouslyRefundedAmount > 0) {
				statusImage = <img src="/static/media/tags/partiallyrefunded.svg" className="w--48p spc--top--tny" />;
			} else {
				statusImage = <img src="/static/media/tags/refunded.svg" className="w--48p spc--top--tny" />;
			}
		} else {
			statusImage = (
				<div className={`type--color--text--regular spc--bottom--nano ${statusClasses[xResponseResult]}`}>
					{xResponseResult}
				</div>
			);
		}

		if (statusImage !== null) {
			return <div>{statusImage}</div>;
		}
		return null;
	}

	formatCcExpDate = d => {
		if (d && d.length === 4) {
			let date = d.split('', 4);
			date.splice(2, 0, '/');
			return date.join('');
		} else {
			return d;
		}
	};

	formatPhoneNumber = p => {
		const number = p ? p : '';
		return (
			number && (
				<NumberFormat value={number} displayType="text" format={number.length < 11 ? '(###) ###-#####' : undefined} />
			)
		);
	};

	hasAnyInfoValues = (...values) => {
		let hasValues = false;
		for (let item of values) {
			if (isArray(item)) {
				for (let i of item) {
					if (i) {
						hasValues = true;
						break;
					}
				}
			} else {
				if (item) {
					hasValues = true;
					break;
				}
			}
		}
		return hasValues;
	};

	renderReceiptItem = (name, values, renderEmpty = false) => {
		if (name && ((values && this.hasAnyInfoValues(values)) || renderEmpty)) {
			return (
				<div className="f-col f-col-sml-4 field">
					<div className="field__label">{setToCanadianVerbiage(name)}:</div>
					<div className="field__info">
						{name === 'Status' ? this.renderStatus() : this.renderReceiptMultipleValues(name, values)}
					</div>
				</div>
			);
		}
	};

	renderBillingAndShipping = (billingInfo, shippingInfo) => {
		const hasBillingInfo = this.hasAnyInfoValues(billingInfo);
		const hasShippingInfo = this.hasAnyInfoValues(shippingInfo);
		const columnClass = hasBillingInfo && hasShippingInfo ? 'f-col f-col-sml-6' : 'f-col f-col-sml-12';
		return hasBillingInfo || hasShippingInfo ? (
			<div className="newtransaction__print__main">
				<div className="f-row">
					{hasBillingInfo && (
						<div className={columnClass}>
							<div className="newtransaction__print__title">Billing Information</div>
							<div className="f-row newtransaction__print__billing">
								{this.renderReceiptItem('Billing info', billingInfo)}
							</div>
						</div>
					)}
					{hasShippingInfo && (
						<div className={columnClass}>
							<div className="newtransaction__print__title">Shipping Information</div>
							<div className="f-row newtransaction__print__billing">
								{this.renderReceiptItem('Shipping info', shippingInfo)}
							</div>
						</div>
					)}
				</div>
			</div>
		) : null;
	};

	renderPaymentMethod = value => {
		if (!value) {
			return '';
		} else if (value === '0') {
			return 'Credit';
		} else if (value === '1') {
			return 'Debit';
		}
	};

	renderPrintHeader() {
		const principal = principalService.get();
		if (principal && principal.idInfo && principal.companyName) {
			const { xMerchantCity, xMerchantAddress, xMerchantState, xMerchantZip, xMerchantPhone } = principal.idInfo;
			return (
				<div className="newtransaction__print__header__info">
					<p className="type--wgt--bold">{principal.companyName}</p>
					<p>{xMerchantAddress}</p>
					<p className="display--ib address">{xMerchantCity}</p>
					<p className="display--ib address">{xMerchantState}</p>
					<p className="display--ib">{xMerchantZip}</p>
					<p>{xMerchantPhone}</p>
				</div>
			);
		}
	}

	renderAmount = () => {
		const data = { ...this.props.data };
		if (exportService.shouldSubtract(data.xCommand)) {
			data.xAmount = -Math.abs(data.xAmount);
		}
		let value = (
			<NumberFormat
				prefix={this.currencyCode}
				value={data.xVoid === '1' ? data.xRequestAmount : data.xAmount}
				displayType="text"
				thousandSeparator={true}
				decimalScale={2}
				fixedDecimalScale={true}
			/>
		);

		if (data.xVoid === '1') {
			value = <span className="type--linethrough">{value}</span>;
		}

		return value;
	};

	render() {
		const {
			data,
			data: { xIsSplitCapturable, xClearedCount, xClearedAmount, xECI, xCAVV },
			logoUrlBase64,
		} = this.props;

		const [paymentType, transactionType] = split(data && toLower(data.xCommand), ':');
		let avs = mapAvsItem(data && data.xResponseAVSCode);
		if (avs) {
			avs = avs.text;
		}

		const customInformation = compact(
			renderCustomFields(data, this.state.customDisplayLabels, this.renderReceiptItem, true)
		);

		return (
			<table className="newtransaction__print__width">
				<thead>
					<tr>
						<th />
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<div className="newtransaction__print">
								<div className="newtransaction__print__header">
									<div className="newtransaction__print__header__logo">
										{!logoUrlBase64 && (
											<ImageWithFallback imgAttributes={{ height: '50px' }}>
												{props => <Logo {...props} />}
											</ImageWithFallback>
										)}
										{logoUrlBase64 && (
											<img
												className="spc--left--sml"
												src={`data:image/jpeg;base64,${logoUrlBase64}`}
												alt="Logo Image"
												width="60"
											/>
										)}
									</div>
									{this.renderPrintHeader()}
									{!logoUrlBase64 && (
										<div className="newtransaction__print__header__background">
											<HeaderBackground />
										</div>
									)}
								</div>
								<div className="newtransaction__print__main">
									<h2 className="newtransaction__print__title">Processing information</h2>
									<div className="newtransaction__print__amount__wrapper">
										<label className="field__label">Amount</label>
										<div className="newtransaction__print__amount">{this.renderAmount()}</div>
									</div>
									{xIsSplitCapturable && xClearedCount && (
										<div className="f-row newtransaction__print__fullwidth spc--bottom--nano">
											<div className="f-col f-col-sml-6 word-break--break-word spc--bottom--tny">
												<div className="field__label spc--right--tny">Cleared Amount:</div>
												<div className="display--ib type--xsml type--wgt--medium">
													<div className="type--color--text--regular spc--bottom--nano">
														{this.renderNumberFormat(xClearedAmount)} ({xClearedCount})
													</div>
												</div>
											</div>
										</div>
									)}
									<div className="f-row newtransaction__print__fullwidth spc--bottom--nano">
										{this.renderReceiptItem(
											'Date',
											moment(data.xEnteredDate, ApplicationSettings.apiResponseDateTimeFormat).format(
												ApplicationSettings.displayDateTimeFormat
											),
											true
										)}
										{this.renderReceiptItem(
											'Transaction type',
											PaymentTransactionTypes.getTransactionType(transactionType),
											true
										)}
										{this.renderReceiptItem('Status', data.xResponseResult, true)}
									</div>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="newtransaction__print__main">
								<h2 className="newtransaction__print__title">Card information</h2>
								<div className="f-row">
									{this.renderReceiptItem('Card type', data.xCardType)}
									{this.renderReceiptItem('Card expiration', this.formatCcExpDate(data.xExp), true)}
									{this.renderReceiptItem(
										(PaymentTransactionTypes.getPaymentType(paymentType) === 'Credit Card' ? 'Card' : 'Account') +
											' number',
										data.xMaskedCardNumber && data.xMaskedCardNumber.includes('xxx')
											? `**** ${data.xMaskedCardNumber.slice(-4)}`
											: data.xMaskedCardNumber,
										true
									)}
									{this.renderReceiptItem('Cardholder name', data.xName, true)}
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							{this.renderBillingAndShipping(
								[
									trim(`${data.xBillFirstName || ''} ${data.xBillLastName || ''}`),
									data.xBillCompany,
									data.xBillStreet,
									trim(`${data.xBillCity || ''} ${data.xBillState || ''} ${data.xBillZip || ''}`),
									data.xBillCountry,
									data.xEmail,
									this.formatPhoneNumber(data.xBillPhone),
								],
								[
									trim(`${data.xShipFirstName || ''} ${data.xShipLastName || ''}`),
									data.xShipCompany,
									data.xShipStreet,
									trim(`${data.xShipCity || ''} ${data.xShipState || ''} ${data.xShipZip || ''}`),
									data.xShipCountry,
									data.xShipEmail,
									this.formatPhoneNumber(data.xShipPhone),
								]
							)}
						</td>
					</tr>
					<td>
						{!isEmpty(customInformation) && (
							<div className="newtransaction__print__main">
								<div className="newtransaction__print__title">Custom information</div>
								<div className="f-row">{customInformation}</div>
							</div>
						)}
					</td>
					<tr>
						<td>
							<div className="newtransaction__print__main">
								<div className="newtransaction__print__title">Additional information</div>
								<div className="f-row">
									{this.renderReceiptItem('Entry method', data.xEntryMethod, true)}
									{this.renderReceiptItem('Payment method', this.renderPaymentMethod(data && data.xIsDebit))}
									{this.renderReceiptItem('Reference Number', data.xRefNum, true)}
									{this.renderReceiptItem('Payment type', PaymentTransactionTypes.getPaymentType(paymentType), true)}
									{this.renderReceiptItem('Authorization Code', data.xResponseAuthCode, true)}
									{this.renderReceiptItem('Invoice', data.xInvoice)}
									{this.renderReceiptItem('AVS', avs)}
									{this.renderReceiptItem('AVS Street', data.xStreet)}
									{this.renderReceiptItem('AVS ZIP', data.xZip)}
									{this.renderReceiptItem('PO Number', data.xPONum)}
									{this.renderReceiptItem('Tax', data.xTax)}
									{this.renderReceiptItem('Service Fee', data.xServiceFee)}
									{this.renderReceiptItem('Description', data.xDescription)}
								</div>
							</div>
						</td>
					</tr>
					{xECI || xCAVV ? (
						<tr>
							<td>
								<div className="newtransaction__print__main">
									<div className="newtransaction__print__title">3D Secure</div>
									<div className="f-row">
										{this.renderReceiptItem('CAVV', xCAVV)}
										{this.renderReceiptItem('ECI', xECI)}
									</div>
								</div>
							</td>
						</tr>
					) : null}
					<tr>
						<td>
							<div className="newtransaction__print__main noborder">
								<div className="newtransaction__print__signature__border">
									<h2 className="newtransaction__print__title">Signature</h2>
									{trim(data.xSignature) ? (
										<div className="f-col f-col-sml-4 spc--bottom--med">
											<div className="field__label spc--bottom--tny">{name}</div>
											<img
												className="newtransaction__print__signature__img"
												src={`data:image/png;base64,${data.xSignature}`}
											/>
										</div>
									) : (
										<p className="type--nano padd--bottom--xsml">X</p>
									)}
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="newtransaction__print__main noborder">
								<p className="newtransaction__print__agreement">
									I Agree to pay above total amount according to card issuer agreement period.
								</p>
							</div>
						</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<td>
							<ImageWithFallback>{props => logoUrlBase64 && <Logo {...props} />}</ImageWithFallback>
						</td>
					</tr>
				</tfoot>
			</table>
		);
	}
}

PrintTransactionReceiptComponent.propTypes = {
	data: PropTypes.any,
	makePendingRequest: PropTypes.func,
	logoUrlBase64: string,
};

export const PrintTransactionReceipt = PrintTransactionReceiptComponent;
export const WrappedPrintTransactionReceiptComponent = withCancelable(PrintTransactionReceiptComponent);
