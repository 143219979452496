import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FraudSettingsActionGrid from './components/FraudSettingsActionGrid';
import { map, pullAt, keys, get, concat, chain, split, some, values, toLower, includes, remove } from 'lodash';
import { TransactionRowDetails } from 'components/transaction-grid/details';
import { Modal } from 'common/components/modal';

class FraudSettingsBlockList extends Component {
	constructor(props) {
		super(props);
		this.state = { newValue: '', activeTab: keys(this.blockTypes)[0], errorMessages: {}, isPopupOpen: false };
	}
	get defaultColumns() {
		return [{ field: 'ipAddress', label: '' }];
	}
	get blockTypes() {
		return {
			'Credit Card': {
				inputPlaceholder: 'Enter Reference Number',
				inputLabel: 'Enter Reference Number',
				columns: this.blockCardByPaymentIdColumns,
				gridTitle: 'List of blocked Credit Cards',
				addButtonLabel: 'Add to blocked',
				settingsKey: 'AFF_BlockCardByPaymentId',
				validator: value => value,
				columnGroup: true,
			},
			BIN: {
				inputPlaceholder: '000 000',
				columns: this.defaultColumns,
				settingsKey: 'AFF_Block_CardBin',
				validator: value => /^(\d{6}|\d{8})$/.test(value),
			},
			Email: {
				inputPlaceholder: 'johndoe@solapayments.com',
				columns: this.defaultColumns,
				settingsKey: 'AFF_Block_Email',
				validator: value => /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value),
			},
			Name: {
				inputPlaceholder: 'John Doe',
				columns: this.defaultColumns,
				settingsKey: 'AffBlockName',
				validator: value => value.trim() !== '',
			},
			'IP & IP Range': {
				inputLabel: 'Enter IP',
				inputPlaceholder: '000.000.0.0',
				columns: this.defaultColumns,
				settingsKey: 'AFF_BLOCK_IP',
				validator: value => /^\d{1,3}\.\d{1,3}\.\d{1,3}\.(\d{1,3}|\*)$/.test(value),
			},
			Other: {
				inputPlaceholder: 'Enter value',
				columns: this.defaultColumns,
				settingsKey: null,
				validator: null,
			},
		};
	}
	get blockCardByPaymentIdColumns() {
		return [
			{ field: 'cardNum', label: 'Credit Card number' },
			{ field: 'refNum', label: 'Ref. Number', onClick: this.handleOpenTransactionDetails },
			{ field: 'paymentId', label: 'Payment ID', colSpan: '2', fieldClassName: 'type--color--primary' },
		];
	}
	handleOpenTransactionDetails = async refNum => {
		this.setState({ isPopupOpen: true, activeRefNum: refNum });
	};
	handleCloseTransactionDetails = () => {
		this.setState({ isPopupOpen: false, activeRefNum: null });
	};
	handleTabChange = tab => {
		this.setState({ activeTab: tab, newValue: '', errorMessages: {} });
	};

	onInputButton = async () => {
		const { newValue, activeTab } = this.state;

		if (!newValue) {
			this.updateErrorMessages(activeTab, ['Input cannot be empty']);
			return;
		}

		const newSettings = { ...this.props.affSettings };
		const validationErrors = this.validateInput(newValue, activeTab);

		if (validationErrors.length > 0) {
			this.updateErrorMessages(activeTab, validationErrors);
		} else {
			await this.updateSettings(newValue, activeTab, newSettings);
		}
	};

	validateInput = (newValue, activeTab) => {
		const validationErrors = [];
		const validator = get(this.blockTypes, `${activeTab}.validator`);

		if (validator && !validator(newValue)) {
			validationErrors.push(`${activeTab} is not valid`);
		}

		return validationErrors;
	};

	updateErrorMessages = (activeTab, errors) => {
		this.setState({ errorMessages: { ...this.state.errorMessages, [activeTab]: errors } });
	};

	updateSettings = async (newValue, activeTab, newSettings) => {
		if (activeTab === 'IP & IP Range') {
			this.updateIPRangeSettings(newValue, newSettings);
		} else if (activeTab === 'Credit Card') {
			await this.updateCreditCardSettings(newValue, newSettings);
		} else {
			this.updateOtherSettings(newValue, activeTab, newSettings);
		}

		this.props.handleSettingsUpdate('affSettings', newSettings);
		this.setState({ newValue: '', errors: [] });
	};

	updateIPRangeSettings = (newValue, newSettings) => {
		const key = newValue.includes('*') ? 'AFF_Block_IP_3' : 'AFF_Block_IP_4';
		newSettings[key] = [...newSettings[key], newValue];
	};

	updateCreditCardSettings = async (newValue, newSettings) => {
		const cardInformation = await this.props.getCardByRefNum(newValue);
		if (!cardInformation) {
			this.updateErrorMessages(this.state.activeTab, ['Invalid Refnum']);
			return;
		}
		const cardInfo = this.extractCardInfo(cardInformation, newValue);

		if (cardInfo) {
			newSettings['AFF_BlockCardByPaymentId'] = [...newSettings['AFF_BlockCardByPaymentId'], cardInfo];
		} else {
			this.updateErrorMessages(this.state.activeTab, ['Invalid Refnum']);
		}
	};

	updateOtherSettings = (newValue, activeTab, newSettings) => {
		const settingsKey = get(this.blockTypes, `${activeTab}.settingsKey`);
		newSettings[settingsKey] = [...newSettings[settingsKey], newValue];
	};
	removeIpAndRangeItem = (item, newSettings) => {
		let key = 'AFF_Block_IP_4';
		if (includes(item, '*')) {
			key = 'AFF_Block_IP_3';
		}
		remove(newSettings[key], currentItem => currentItem === item);
	};
	onAction = (item, index) => {
		const { activeTab } = this.state;
		let newSettings = { ...this.props.affSettings };
		const settingsKey = get(this.blockTypes, `${activeTab}.settingsKey`);
		if (toLower(settingsKey) === 'aff_block_ip') {
			this.removeIpAndRangeItem(item, newSettings);
		} else if (settingsKey) {
			if (index !== -1) pullAt(newSettings[settingsKey], index);
		}

		this.props.handleSettingsUpdate('affSettings', newSettings);
	};
	isSettingChecked = key => {
		const { affSettings } = this.props;
		if (key === 'affBlockKeyed') {
			return affSettings.AFF_Block_Keyed;
		} else if (key === 'blockPrepaid') {
			return affSettings.BlockPrepaid;
		} else if (key === 'blockNonUSCanada') {
			return affSettings.BlockNonUSCanada;
		}
	};

	handleCheckboxChange = (key, checked) => {
		const { affSettings } = this.props;
		if (key === 'affBlockKeyed') {
			this.props.handleSettingsUpdate('affSettings', {
				...affSettings,
				AFF_Block_Keyed: checked,
			});
		} else if (key === 'blockPrepaid') {
			this.props.handleSettingsUpdate('affSettings', {
				...affSettings,
				BlockPrepaid: checked,
			});
		} else if (key === 'blockNonUSCanada') {
			this.props.handleSettingsUpdate('affSettings', {
				...affSettings,
				BlockNonUSCanada: checked,
			});
		}
	};

	extractCardInfo = (cardInformation, refNum) => {
		if (get(cardInformation, 'xReportData', []).length > 0) {
			const { xPaymentId, xMaskedCardNum } = cardInformation.xReportData[0];
			return `${xPaymentId}_${xMaskedCardNum}_${refNum}`;
		}
		return null;
	};

	renderFraudListBlockOther = () => {
		const { isLoading } = this.props;
		return (
			<div className="spc--bottom--med">
				<div className="spc--bottom--sml--alt">
					<input
						type="checkbox"
						id="affBlockKeyed"
						name="affBlockKeyed"
						className="input input--check"
						checked={this.isSettingChecked('affBlockKeyed')}
						onChange={e => this.handleCheckboxChange('affBlockKeyed', e.target.checked)}
						disabled={isLoading}
					/>
					<label htmlFor="affBlockKeyed" className="type--color--text--medium type--wgt--medium">
						Block Keyed Transactions
					</label>
				</div>
				<div className="spc--bottom--sml--alt">
					<input
						type="checkbox"
						id="blockPrepaid"
						name="blockPrepaid"
						className="input input--check"
						checked={this.isSettingChecked('blockPrepaid')}
						onChange={e => this.handleCheckboxChange('blockPrepaid', e.target.checked)}
						disabled={isLoading}
					/>
					<label htmlFor="blockPrepaid" className="type--color--text--medium type--wgt--medium">
						Block Prepaid Cards
					</label>
				</div>
				<div>
					<input
						type="checkbox"
						id="blockNonUSCanada"
						name="blockNonUSCanada"
						className="input input--check"
						checked={this.isSettingChecked('blockNonUSCanada')}
						onChange={e => this.handleCheckboxChange('blockNonUSCanada', e.target.checked)}
						disabled={isLoading}
					/>
					<label htmlFor="blockNonUSCanada" className="type--color--text--medium type--wgt--medium">
						Block all Cards originating outside of the US & Canada
					</label>
				</div>
			</div>
		);
	};

	onGridInputChange = value => {
		this.setState({ newValue: value, errorMessages: {} });
	};
	renderBlockTypes = () => {
		const { activeTab } = this.state;
		return (
			<ul className="tabs spc--bottom--xlrg">
				{keys(this.blockTypes).map(tab => (
					<li key={tab} className="tabs__item">
						<button
							onClick={() => this.handleTabChange(tab)}
							className={`tabs__link${activeTab === tab ? ' is-active' : ''}`}
						>
							{tab}
						</button>
					</li>
				))}
			</ul>
		);
	};
	renderErrors = () => {
		const { errorMessages, activeTab } = this.state;
		return map(errorMessages[activeTab], message => (
			<p key={message} className="type--validation spc--bottom--med">
				{message}
			</p>
		));
	};
	renderHeading = () => {
		const { isExpanded, toggleExpand } = this.props;

		return (
			<button className="card__header card__header--expandable" onClick={toggleExpand}>
				<h5>Block Lists</h5>
				<i className={`icon icon--sml icon--chevron--${isExpanded ? 'top' : 'down'}--primary`}></i>
			</button>
		);
	};

	renderContent = () => {
		return (
			<div className="card__body">
				{this.renderBlockTypes()}
				{this.renderErrors()}
				{this.renderBlockList()}
			</div>
		);
	};

	renderBlockList = () => {
		return map(this.blockTypes, (blockType, type) => {
			if (this.state.activeTab === type) {
				let data = this.getBlockData(blockType, type);
				if (type === 'Other') {
					return this.renderFraudListBlockOther();
				} else {
					return this.renderFraudSettingsActionGrid(blockType, type, data);
				}
			}
		});
	};

	getBlockData = (blockType, type) => {
		let data = [];
		if (blockType.settingsKey) {
			if (type === 'IP & IP Range') {
				data = concat(this.props.affSettings.AFF_Block_IP_4, this.props.affSettings.AFF_Block_IP_3);
			} else {
				data = get(this.props.affSettings, blockType.settingsKey);
			}
		}
		if (type === 'Credit Card') {
			data = this.processCreditCardData(data);
		}
		return data;
	};

	processCreditCardData = data => {
		return chain(data)
			.map(item => {
				const parts = split(item, '_');
				if (parts.length === 1) {
					return { cardNum: parts[0] };
				} else {
					const [paymentId, cardNum, refNum] = parts;
					return { paymentId, cardNum, refNum };
				}
			})
			.filter(item => item && some(values(item), value => value !== ''))
			.value();
	};

	renderFraudSettingsActionGrid = (blockType, type, data) => {
		return (
			<FraudSettingsActionGrid
				key={type}
				data={data}
				columns={blockType.columns}
				onAction={this.onAction}
				onInputButton={this.onInputButton.bind(this)}
				gridTitle={blockType.gridTitle || `List of blocked ${type}`}
				inputPlaceholder={blockType.inputPlaceholder || `Enter ${type}`}
				inputLabel={blockType.inputLabel || `Enter ${type}`}
				addButtonLabel={blockType.addButtonLabel || `Block ${type}`}
				iconOnly={true}
				newGridItem={this.state.newValue}
				onGridInputChange={this.onGridInputChange}
				isLoading={this.props.isLoading}
				actionLabel={''}
				showColumnGroup={blockType.columnGroup}
			/>
		);
	};

	render() {
		const { isExpanded } = this.props;
		const { isPopupOpen, activeRefNum } = this.state;
		return (
			<div className="card spc--bottom--lrg">
				<Modal
					isOpen={isPopupOpen}
					onClose={this.handleCloseTransactionDetails}
					shouldCloseOnOverlayClick={false}
					className="modal__content modal--med"
				>
					<div className="modal__header">
						<div className="modal__header__title">Original transaction</div>
					</div>
					<div className="modal__body modal__body--original-transaction">
						<TransactionRowDetails
							inPopup={true}
							hideHeaderButtons={true}
							hideFooter={true}
							row={{ xRefNum: activeRefNum }}
						/>
					</div>
				</Modal>
				{this.renderHeading()}
				{isExpanded && this.renderContent()}
			</div>
		);
	}
}
FraudSettingsBlockList.propTypes = {
	isExpanded: PropTypes.bool.isRequired,
	toggleExpand: PropTypes.func.isRequired,
	affSettings: PropTypes.object.isRequired,
	handleSettingsUpdate: PropTypes.func.isRequired,
	getCardByRefNum: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
};
export default FraudSettingsBlockList;
