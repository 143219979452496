import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import { accountType as accountTypeConstant } from './constants';
import { fieldPropType, isValid } from '../../Common/fields';
import principalService from '../../Common/services/principalService';
import { RoutingNumberTooltip, AccountNumberTooltip } from '../../Common/components/tooltips';

const { ifieldsSource } = ApplicationSettings;

const inputStyle = {
	padding: '0 12px',
	width: 'calc(100% - 28px)',
	height: '42px',
	border: '1px solid #CCCDD3',
	fontSize: '14px',
	'border-radius': '8px',
	'box-shadow': '',
	outline: 'none',
};

const invalidStyle = {
	height: '42px',
	padding: '0 12px',
	border: '1px solid #C83E4D',
};

/*global setAccount, setIfieldStyle, ifieldEventCallbacks, addIfieldKeyPressCallback, getTokens*/

class CheckFields extends Component {
	constructor(props) {
		super(props);

		this.account = React.createRef();
	}

	componentDidMount() {
		if (this.account.current && this.hidAccountNum) {
			window.ifieldDataCache = {
				cardNumberIsValid: false,
				cardNumberLength: 0,
				cardNumberFormattedLength: 0,
				cardNumberIsEmpty: true,
				issuer: 'unknown',
				cvvIsValid: false,
				cvvLength: 0,
				cvvIsEmpty: true,
				achLength: 0,
				achIsEmpty: true,
				achIsValid: false,
				lastIfieldChanged: '',
			};
			const ifields = principalService.get().ifields;
			setAccount(ifields, SoftwareSettings.name, SoftwareSettings.version);

			setIfieldStyle('ach', inputStyle);

			if (ifieldEventCallbacks) {
				//eslint-disable-next-line
				window.ifieldEventCallbacks = {};
			}
			addIfieldKeyPressCallback(
				function(value) {
					const { achIsValid, achIsEmpty } = value;
					this.props.onChange(
						{
							key: 'achValid',
							value: achIsValid,
						},
						{
							key: 'achIsEmpty',
							value: achIsEmpty,
						}
					);
				}.bind(this)
			);
		}
	}

	getIfieldTokens = () => {
		return new Promise(resolve => {
			if (this.hidAccountNum) {
				getTokens(
					() => {
						resolve({
							ach: this.hidAccountNum.value,
						});
					},
					resolve,
					30000
				);
			} else {
				resolve();
			}
		});
	};

	onChange = e => {
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.props.onChange({
			key: name,
			value: value,
		});
	};

	onNumberChange = ({ value }, { target: { name } }) => {
		this.props.onChange({
			key: name,
			value,
		});
	};
	onPaste = e => {
		e.preventDefault();
	};

	onRoutingEnter = () => {
		this.routing.focus();
	};

	onAccountEnter = () => {
		this.account.current.focus();
	};
	isAllowedRoutingNumber = values => {
		const { value } = values;

		return value.length < 10;
	};

	render() {
		const { check, invalidClassName } = this.props;

		const required = (
			<span className="form__group__required" data-tooltip="Required">
				*
			</span>
		);

		setIfieldStyle('ach', isValid(check.achValid) ? inputStyle : invalidStyle);

		return (
			<div className="newtransaction--expanded--inner newtransaction__group">
				<div className="form__group">
					<div className="form__group__header">
						<label className="form__group__label">Account type</label>
					</div>
					<select
						name="accountType"
						className="input input--med input--select"
						value={check.accountType}
						onChange={this.onChange}
					>
						<option value={accountTypeConstant.CHECKING}>Checking</option>
						<option value={accountTypeConstant.SAVING}>Saving</option>
					</select>
					<label data-ifields-id="card-data-error" />
				</div>
				<div className="form__group">
					<div className="form__group__header">
						<label className="form__group__label">Account name {required}</label>
					</div>
					<div className="newtransaction__item__value newtransaction__item__value--expandable">
						<input
							type="text"
							name="accountName"
							className={`input input--med ${(!isValid(check.accountName) && invalidClassName) || ''}`}
							placeholder="Account name"
							value={check.accountName.value}
							onChange={this.onChange}
						/>
					</div>
				</div>
				<div className="form__group">
					<div className="form__group__header">
						<label className="form__group__label">
							Routing number {required}
							<RoutingNumberTooltip onMouseEnter={this.onRoutingEnter} />
						</label>
					</div>
					<NumberFormat
						value={check.routingNumber.value}
						format="##########"
						placeholder="Routing number"
						className={`input input--med ${(!isValid(check.routingNumber) && invalidClassName) || ''}`}
						name="routingNumber"
						inputMode="numeric"
						getInputRef={el => {
							this.routing = el;
						}}
						isAllowed={this.isAllowedRoutingNumber}
						onValueChange={this.onNumberChange}
					/>
				</div>
				<div className="form__group">
					<div className="form__group__header">
						<label className="form__group__label">
							Account number {required}
							<AccountNumberTooltip onMouseEnter={this.onAccountEnter} />
						</label>
					</div>
					<div className="input--fake-input" tabIndex="-1" maximum-scale="1">
						<iframe
							data-ifields-id="ach"
							data-ifields-placeholder="Account number"
							src={ifieldsSource}
							ref={this.account}
						/>
						<input
							type="hidden"
							data-ifields-id="ach-token"
							name="xAccount"
							ref={r => {
								this.hidAccountNum = r;
							}}
							required
						/>
					</div>
				</div>
			</div>
		);
	}
}

CheckFields.propTypes = {
	check: PropTypes.shape({
		accountName: fieldPropType(PropTypes.string).isRequired,
		accountType: PropTypes.string.isRequired,
		routingNumber: fieldPropType(PropTypes.string).isRequired,
	}).isRequired,
	onChange: PropTypes.func.isRequired,
	invalidClassName: PropTypes.string,
};

export default CheckFields;
