import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ImageWithFallback from 'common/components/ImageFallback';
import { getDomainName } from 'common/services/helper-service';
import { startsWith, toLower } from 'lodash';

class PublicLayoutComponent extends Component {
	constructor(props) {
		super(props);
		this.isSolaDomain = !startsWith(toLower(getDomainName()), 'cardknox');
	}

	render() {
		return (
			<Fragment>
				<div className={`auth ${this.props.customLayoutClass ? this.props.customLayoutClass : ''}`}>
					<aside className="auth__sidebar">
						<ImageWithFallback imgAttributes={{ alt: 'logo' }}>
							{props => (
								<img alt="logo" src={'static/media/sola-logo.svg'} className="auth__sidebar__logo" {...props} />
							)}
						</ImageWithFallback>
					</aside>

					<div className="auth__main">
						{this.isSolaDomain ? (
							<div className="dashboard__message">
								<div>
									<p className="type--p1 type--p1--medium spc--bottom--sml">Welcome to Sola!</p>
									<p className="type--p2">
										Your Merchant Portal has been upgraded with a modern design based on merchant feedback.
										<br />
										Use your same login credentials as portal.cardknox.com, and rest assured that your PaymentSITE links and
										mobile app access continue working as usual.
									</p>
								</div>
							</div>
						) : (
							<div className="dashboard__message">
								<div>
									<p className="type--p1 type--p1--medium spc--bottom--sml">Welcome to Sola!</p>
									<p className="type--p2">
										Starting March 18, 2025, the Merchant Portal will move to portal.solapayments.com.
										<br />
										Your login details will remain the same, but be sure to update your bookmarks and password manager
										with the new URL
									</p>
								</div>
							</div>
						)}
						{this.props.children}
					</div>
				</div>
			</Fragment>
		);
	}
}

PublicLayoutComponent.propTypes = {
	children: PropTypes.any,
	customLayoutClass: PropTypes.string,
};

export default PublicLayoutComponent;
