import React from 'react';
import PropTypes from 'prop-types';
import { withError } from 'common/components/error';
import { GridComponent } from 'common/components/grid';
import DownloadButtonCell from 'common/components/columns/formatters/download-button-cell';
import { ZebraRenderer } from 'common/components/row';
import { get, map, noop, orderBy, filter } from 'lodash';
import statementService from 'common/services/statementService';
import { withCancelable } from 'common/components/cancelable';
import { principalService } from 'common/services';
import { func } from 'prop-types';
import { withLoader } from 'common/components/loader';
import { ColumnHeader } from 'common/components/columns/headers';
import FileNameRowRenderer from './components/FileNameRowRenderer';
import YearSelection from './components/year-selection';

class StatementsGrid extends React.Component {
	constructor(props) {
		const principal = principalService.get();

		super(props);
		this.classes = {
			filter: '',
			gridHeader: 'flex--primary datatooltip--v--bottom flex--grow--1',
			filterHeader: '',
		};
		this.gridRef = React.createRef();
		this.components = {
			rowRenderer: ZebraRenderer,
			standaloneInlineFilter: this.renderStandaloneFilter,
		};
		this.state = {
			mid: get(principal, 'idInfo.xMerchantID', ''),
			data: null,
			filteredRows: [],
			fetchingData: true,
			selectedYear: null,
			columns: [
				{
					key: 'fileName',
					name: 'File Name',
					initWidth: 250,
					visible: true,
					formatter: FileNameRowRenderer,
				},
				{
					key: 'year',
					name: 'Year',
					initWidth: 100,
					visible: true,
				},
				{
					key: 'download',
					name: 'Download',
					initWidth: 100,
					visible: true,
					headerRenderer: <ColumnHeader />,
					alignHeaderRight: true,
					formatter: ({ row }) => <DownloadButtonCell row={row} handleDownload={() => this.downloadStatement(row)} />,
				},
			],
			filters: [],
			activeFilters: [],
		};
	}

	downloadStatement = async ({ fileName, year }) => {
		this.props.showLoader(true);
		try {
			const response = await this.props.makePendingRequest(
				statementService.loadStatement(this.state.mid, year, fileName),
				{}
			);
			const { fileBytes: base64File, fileName: responseFileName } = response;
			const link = document.createElement('a');
			link.href = `data:application/pdf;base64,${base64File}`;
			link.download = responseFileName;
			link.click();
		} catch (e) {
			this.props.handleError(e);
		}
		this.props.showLoader(false);
	};

	fetchData = async () => {
		try {
			this.setState({ fetchingData: true });
			const response = await this.props.makePendingRequest(statementService.loadStatements(this.state.mid), {});
			const data = {
				xReportData: map(orderBy(response.statements, ['year'], ['desc']), (item, index) => ({
					...item,
					gridRowNumber: index,
				})),
			};

			const filteredRows = this.filterRowsByYear(data.xReportData, this.state.selectedYear);

			this.setState({
				data,
				filteredRows,
			});
		} catch (e) {
			this.props.handleError(e);
		} finally {
			this.setState({ fetchingData: false });
		}
	};

	filterRowsByYear = (rows, year) => {
		if (!year) {
			return rows;
		}

		return filter(rows, row => row.year === year);
	};

	handleYearSelect = year => {
		const { data } = this.state;
		if (!data) return;

		const filteredRows = this.filterRowsByYear(data.xReportData, year);

		this.setState({
			selectedYear: year,
			filteredRows,
		});
	};

	renderStandaloneFilter = () => {
		const { data, selectedYear } = this.state;

		return (
			<div className="flex--primary flex--gap--sml fullwidth">
				<YearSelection data={data} onYearSelect={this.handleYearSelect} selectedYear={selectedYear} />
			</div>
		);
	};

	render() {
		const { data, filteredRows, columns, filters, activeFilters, fetchingData } = this.state;

		return (
			<GridComponent
				components={this.components}
				classes={this.classes}
				emptyMessage="No statements found."
				fetchingData={fetchingData}
				filteredRows={filteredRows}
				columns={columns}
				defaultColumns={columns}
				data={data}
				filters={filters}
				activeFilters={activeFilters}
				onChange={noop}
				fetchData={this.fetchData}
				title="Statements"
				type="statementsGrid"
				ref={this.gridRef}
				enableFilters={false}
				tabs={this.props.tabs}
			/>
		);
	}
}

StatementsGrid.propTypes = {
	makePendingRequest: func.isRequired,
	showLoader: func.isRequired,
	handleError: func.isRequired,
	tabs: PropTypes.array.isRequired,
};

export default withCancelable(withError(withLoader(StatementsGrid)));
