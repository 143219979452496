import { validators } from 'common/fields';
import {
	get,
	isEmpty,
	filter,
	without,
	isPlainObject,
	includes,
	toLower,
	upperFirst,
	startsWith,
	replace,
	each,
	some,
} from 'lodash';
import { createError } from './validation-utils';

export const validateNameCompanyFields = (general, customerRequiredFields, customDisplayLabels) => {
	const errors = [];
	const fieldErrors = [];

	const billFirstNameRequired = get(customerRequiredFields, 'firstName', false);
	const billLastNameRequired = get(customerRequiredFields, 'lastName', false);
	const billCompanyRequired = get(customerRequiredFields, 'company', false);

	const hasBillFirstName = !isEmpty(get(general, 'billFirstName', ''));
	const hasBillLastName = !isEmpty(get(general, 'billLastName', ''));
	const hasBillCompany = !isEmpty(get(general, 'billCompany', ''));

	// Handle individual required fields
	if (billFirstNameRequired && !hasBillFirstName) {
		const label = get(customDisplayLabels, 'firstName', 'first name');
		errors.push({
			key: 'billFirstName',
			message: `${label} is required.`,
		});
		fieldErrors.push('billFirstName');
	}

	if (billLastNameRequired && !hasBillLastName) {
		const label = get(customDisplayLabels, 'lastName', 'last name');
		errors.push({
			key: 'billLastName',
			message: `${label} is required.`,
		});
		fieldErrors.push('billLastName');
	}

	if (billCompanyRequired && !hasBillCompany) {
		const label = get(customDisplayLabels, 'company', 'company');
		errors.push({
			key: 'billCompany',
			message: `${label} is required.`,
		});
		fieldErrors.push('billCompany');
	}

	const hasIndividualRequirements = billFirstNameRequired || billLastNameRequired || billCompanyRequired;
	const hasAnyValue = hasBillFirstName || hasBillLastName || hasBillCompany;

	if (!hasIndividualRequirements && !hasAnyValue) {
		const firstNameLabel = get(customDisplayLabels, 'firstName', 'First Name');
		const lastNameLabel = get(customDisplayLabels, 'lastName', 'Last Name');
		const companyLabel = get(customDisplayLabels, 'company', 'Company');

		errors.push({
			key: 'billFirstLastCompany',
			message: `Please provide a ${firstNameLabel}, ${lastNameLabel} or ${companyLabel}`,
		});
		fieldErrors.push('billFirstName', 'billLastName', 'billCompany');
	}

	return { errors, fieldErrors };
};

export const validateEmailFormat = (email, originalEmail) => {
	if (email && email !== originalEmail && !validators.email(email)) {
		return createError('email', 'Email is not valid');
	}
	return null;
};

export const validatePhoneNumber = (phone, originalPhone, fieldName, label) => {
	if (phone && phone !== originalPhone && !validators.phoneNumber(phone)) {
		return createError(fieldName, `${label} is not valid`);
	}
	return null;
};

export const updateInvalidFields = (key, value, invalidFields, hasRequiredNameFields) => {
	const nameCompanyFields = ['billFirstName', 'billLastName', 'billCompany'];
	const isNameOrCompanyField = nameCompanyFields.includes(key);

	if (isNameOrCompanyField) {
		// If this is a name/company field and it has a value, and there are no
		// specific requirements for these fields, clear all name/company fields
		if (value && !hasRequiredNameFields) {
			return without(invalidFields, ...nameCompanyFields);
		}
		// Otherwise just clear this specific field if it has a value
		else if (value) {
			return without(invalidFields, key);
		}
	}
	// For all other fields, just remove from invalid fields if it has a value
	else if (value) {
		return without(invalidFields, key);
	}

	return invalidFields;
};

export const clearFieldErrorMessages = (errorMessages, key) => {
	if (isEmpty(errorMessages)) {
		return errorMessages;
	}

	const nameCompanyFields = ['billFirstName', 'billLastName', 'billCompany'];
	const isNameOrCompanyField = nameCompanyFields.includes(key);

	return filter(errorMessages, error => {
		if (isPlainObject(error)) {
			// Check for specific billFirstLastCompany key
			if (error.key === 'billFirstLastCompany' && isNameOrCompanyField) {
				return false;
			}
			return error.key !== key && error.billKey !== key;
		}

		// For name/company fields, also clear the combined validation error
		if (isNameOrCompanyField && includes(toLower(error), 'please provide a')) {
			return false;
		}

		return !includes(toLower(error), toLower(key));
	});
};

export const hasAnyNameCompanyValue = (general, key, value) => {
	// Use the new value for the current key, existing values for other keys
	const firstName = key === 'billFirstName' ? value : get(general, 'billFirstName', '');
	const lastName = key === 'billLastName' ? value : get(general, 'billLastName', '');
	const company = key === 'billCompany' ? value : get(general, 'billCompany', '');

	return !isEmpty(firstName) || !isEmpty(lastName) || !isEmpty(company);
};

export const validateSendReceipt = (shouldValidateSendReceipt, errors, fieldErrors, general, schedules) => {
	if (!shouldValidateSendReceipt) return;

	// Find any active schedules with custReceipt set to true
	const hasActiveScheduleWithReceipt = some(
		schedules,
		schedule =>
			schedule.custReceipt === true && !schedule._meta.deleted && (schedule._meta.modified || !schedule._meta.created)
	);

	if (hasActiveScheduleWithReceipt && isEmpty(general.email)) {
		errors.push({
			key: 'email',
			message: 'Email is required to send receipts',
		});

		if (!includes(fieldErrors, 'email')) {
			fieldErrors.push('email');
		}
	}
};

export const processBillingKey = key => {
	let billKey = `bill${upperFirst(key)}`;
	if (includes(toLower(key), 'address')) {
		key = replace(key, 'address', 'street');
		billKey = replace(billKey, 'Address', 'Street');
	}

	if (key === 'mobilePhoneNumber') billKey = 'billMobile';
	if (key === 'phoneNumber') billKey = 'billPhone';
	if (key === 'note') billKey = 'customerNotes';

	return { billKey, modifiedKey: key };
};

export const processCustomKey = (key, paddedNumber) => {
	let newKey = key.startsWith('custom') ? 'customerCustom' + key.slice(6) : key;
	let i = newKey.length - 1;

	while (i >= 0 && newKey[i] >= '0' && newKey[i] <= '9') {
		i--;
	}

	if (i !== newKey.length - 1) {
		newKey = newKey.substring(0, i + 1) + paddedNumber;
	}

	return newKey;
};

export const handleCustomField = (modifiedKey, advancedView) => {
	if (!advancedView) return null;

	const customNumber = modifiedKey.replace(/^\D+/g, '');
	const paddedNumber = customNumber.length < 2 ? `0${customNumber}` : customNumber;

	return processCustomKey(modifiedKey, paddedNumber);
};

export const existingErrorsPresent = (billKey, currentKey, generalErrorMessages) => {
	return (
		!get(generalErrorMessages, billKey) && !get(generalErrorMessages, currentKey) && !startsWith(currentKey, 'custom')
	);
};

export const validateBillingCustomerFields = (
	customerRequiredFields,
	advancedView,
	customDisplayLabels,
	general,
	generalErrorMessages,
	errors,
	fieldErrors
) => {
	const addValidationError = (key, billKey) => {
		const hasValue = get(general, key) || get(general, billKey);
		if (hasValue || key === 'amount') return;

		const customLabel = get(customDisplayLabels, key) || get(customDisplayLabels, billKey);
		const errorMessage =
			customLabel || (startsWith(key, 'custom') && key !== 'customerNumber')
				? `${customLabel || 'Custom field'} is required`
				: get(generalErrorMessages, billKey) || get(generalErrorMessages, key);

		errors.push({ message: errorMessage, key: key, billKey: billKey });
		fieldErrors.push(key, billKey);
	};

	each(customerRequiredFields, (_, key) => {
		if (key === 'customerNumber' && !advancedView) return;

		const { billKey, modifiedKey } = processBillingKey(key);
		const currentKey =
			startsWith(modifiedKey, 'custom') && key !== 'customerNumber'
				? handleCustomField(modifiedKey, advancedView)
				: modifiedKey;

		if (!currentKey) return;
		if (existingErrorsPresent(billKey, currentKey, generalErrorMessages)) return;

		addValidationError(currentKey, billKey);
	});
};
