import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import PropTypes from 'prop-types';
import {
	cloneDeep,
	clone,
	filter,
	each,
	map,
	some,
	has,
	values,
	transform,
	find,
	every,
	get,
	isFunction,
	toLower,
	findIndex,
	mapValues,
} from 'lodash';
import 'rc-menu/assets/index.css';
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu';
import { isSafari } from 'react-device-detect';

import { kvaasResources } from 'common/utilities';
import { principalService, kvaasService } from '../../services';
import { validatePermissions } from '../../utilities';
import { predefinedDates, DatePickerPredefined } from 'common/components/date-picker';
import { withError } from '../error';
import { withCancelable } from '../cancelable';
import { parseFilters } from './filterUtils';

const requestKeys = {
	FETCH: 'fetch',
	KVAAS: 'kvaas',
};

class MainFilterComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeKeys: [],
			displayAdvancedFilter: false,
			filters: cloneDeep(props.filters),
			dates: cloneDeep(props.predefinedDates),
			permissions: {},
			expandAdvanceFilterByDefault: false,
			expandAdvanceFilterOldDefault: false,
		};
	}

	setStateAsync = async newState => {
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	componentDidMount = async () => {
		try {
			const principal = principalService.get();
			const permissions = (principal && principal.idInfo && principal.idInfo.permissions) || {};

			const [userSettings, defaultValues] = await this.props.makePendingRequest(
				kvaasService.get(kvaasResources.userSettings, kvaasResources.transactionReportDefaultValues),
				requestKeys.KVAAS
			);
			const multiKeyEnabled = get(defaultValues, 'data.multiKeyReporting');

			const expandAdvanceFilterByDefault = get(userSettings, 'data.expandAdvanceFilterByDefault', false);

			this.setState(
				{
					multiKeyEnabled,
					permissions,
					displayAdvancedFilter: expandAdvanceFilterByDefault,
					expandAdvanceFilterOldDefault: expandAdvanceFilterByDefault,
					expandAdvanceFilterByDefault,
				},
				this.syncFilters
			);
		} catch (e) {
			//eslint-disable-next-line
			console.error(e);
		}
	};

	syncFilters = () => {
		this.setState({
			filters: cloneDeep(this.props.filters),
		});
	};

	injectFilters = filtersToInject => {
		const { filters } = this.state;
		return transform(
			filtersToInject,
			(acc, key) => {
				const filter = find(filters, { key });
				if (filter) {
					acc[key] = filter.values;
				}
			},
			{}
		);
	};

	shouldDisableDateRange = () => {
		const { filters } = this.state;
		const newFilters = [...filters];
		const filterHasValue =
			some(filters, item => item.allowsDateDisable && item.hasSelection) &&
			!some(filters, item => item.disallowsDateDisable && item.hasSelection);
		const dateFilterIndex = findIndex(newFilters, ({ key }) => key === 'date');
		if (dateFilterIndex > -1 && newFilters[dateFilterIndex].values.disabled && !filterHasValue) {
			newFilters[dateFilterIndex].values = {
				...newFilters[dateFilterIndex].values,
				disabled: false,
			};
			this.setState({
				filters: newFilters,
			});
		}
	};

	onActiveFilterChanged = item => {
		const filters = this.getActiveItem(item.id);
		parseFilters(filters, item);

		const stateFilters = cloneDeep(this.state.filters);
		const index = stateFilters.findIndex(f => f.key === item.id);
		stateFilters[index] = filters;
		this.setState({ activeKeys: [], filters: stateFilters });
		this.props.updateFilters({
			filters: cloneDeep(this.props.activeFilters),
			activeFilters: this.props.activeFilters,
		});
	};

	onFilterChanged = item => {
		const filter = this.getItem(item.id);
		parseFilters(filter, item);

		let { filters } = this.state;
		this.setState(
			{
				filters: filters,
			},
			() => {
				this.shouldDisableDateRange();
				if (filter.applyOnChange) {
					this.applyStandaloneFilter();
				}
			}
		);
	};

	onFilterRemoved = (item, override) => {
		const filters = this.getActiveItem(item.id);
		const dateFilter = this.getActiveItem('date');
		const isDateFilterDisabled = get(dateFilter, 'values.disabled', false);
		const defaultFilter = override || filters;

		if (defaultFilter.resetToFalseOnRemove) {
			each(defaultFilter.defaultValues, (_, key) => {
				defaultFilter.defaultValues[key] = false;
			});
			defaultFilter.defaultHasSelection = false;
		}

		filters.values = clone(defaultFilter.defaultValues);
		filters.hasSelection = defaultFilter.defaultHasSelection;

		const stateFilters = cloneDeep(this.state.filters);
		const index = stateFilters.findIndex(f => f.key === item.id);
		stateFilters[index] = filters;
		const filtersThatAllowDateDisable = filter(
			stateFilters,
			({ key, allowsDateDisable }) => allowsDateDisable && key !== 'date'
		);

		if (isDateFilterDisabled && every(filtersThatAllowDateDisable, ({ hasSelection }) => !hasSelection)) {
			const dateIndex = stateFilters.findIndex(f => f.key === 'date');
			stateFilters[dateIndex].values.disabled = false;
		}

		this.setState({
			filters: stateFilters,
		});
		this.props.updateFilters({
			filters: stateFilters,
			activeFilters: cloneDeep(stateFilters),
		});
	};

	onOpenChange = activeKeys => {
		const currentActiveKeys = this.state.activeKeys;
		const filterNoLongerActive = currentActiveKeys.includes('filter') && !activeKeys.includes('filter');

		if (filterNoLongerActive) {
			activeKeys = [];
		}

		if (activeKeys.length === 0 || this.state.activeKeys.length === 0) {
			this.setState({
				activeKeys: activeKeys,
				filters: cloneDeep(this.props.filters),
			});
		} else {
			this.setState({ activeKeys: activeKeys });
		}

		if (this.MenuRef && this.hasOnly(currentActiveKeys, [])) {
			setTimeout(() => {
				this.MenuRef && this.MenuRef.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
			}, 130);
		}
	};

	hasOnly = (arr1, arr2) => every(arr1, elem => arr2.includes(elem));

	resetFilter = () => {
		const { filters: originalFilters, updateFilters, showHideAccountKeyColumn } = this.props;
		const filters = cloneDeep(originalFilters);

		const resetFilters = map(filters, filter => {
			if (!filter.clearable) return filter;

			const newFilter = { ...filter };
			if (filter.resetToFalseOnRemove) {
				newFilter.values = mapValues(filter.values, () => false);
				newFilter.hasSelection = false;
			} else {
				newFilter.values = cloneDeep(filter.defaultValues);
				newFilter.hasSelection = filter.defaultHasSelection;
			}
			return newFilter;
		});

		updateFilters({
			filters: resetFilters,
			activeFilters: resetFilters,
		});

		this.setState({ activeKeys: [], filters: resetFilters }, () => {
			if (isFunction(showHideAccountKeyColumn)) {
				showHideAccountKeyColumn({ filters: resetFilters });
			}
		});
	};
	applyFilter = async (_, standaloneFilter) => {
		const { filters } = this.state;
		const { updateFilters } = this.props;
		updateFilters({
			filters: cloneDeep(filters),
			activeFilters: cloneDeep(filters),
			standaloneFilter,
		});
		this.saveExpandAdvanceFilterDefault();
		await this.setStateAsync({ activeKeys: [] });
	};

	saveExpandAdvanceFilterDefault = async () => {
		const currentState = this.state.expandAdvanceFilterByDefault;
		const oldState = this.state.expandAdvanceFilterOldDefault;
		if (currentState != oldState) {
			try {
				const [userSettings] = await this.props.makePendingRequest(
					kvaasService.get(kvaasResources.userSettings),
					requestKeys.KVAAS
				);
				const data = clone(get(userSettings, 'data', {}));
				data.expandAdvanceFilterByDefault = currentState;
				const mappedState = this.mapStateToRequest(data, userSettings);
				await this.props.makePendingRequest(kvaasService.save(mappedState), requestKeys.KVAAS);
				this.setState({
					expandAdvanceFilterByDefault: currentState,
					expandAdvanceFilterOldDefault: currentState,
				});
			} catch (e) {
				this.props.handleError(e);
			}
		}
	};

	mapStateToRequest = (data, oldData) => {
		return {
			newData: {
				revision: 0,
				data,
			},
			oldData,
			...kvaasResources.userSettings,
		};
	};

	applyStandaloneFilter = async item => {
		await this.applyFilter(null, item);
		this.setState({
			filters: cloneDeep(this.props.filters),
		});
	};

	getItem = key => {
		const item = filter(this.state.filters, { key: key })[0];
		return item;
	};

	getActiveItem = key => {
		const item = filter(this.props.activeFilters, { key: key })[0];
		return item;
	};

	renderSubMenuItemTitle = (title, key) => {
		const item = this.getItem(key);
		if (item && item.hasSelection) {
			return (
				<span>
					<span className="selected">{title}</span>
					<span className="filter--selected"></span>
				</span>
			);
		} else {
			return <span>{title}</span>;
		}
	};

	renderCustomSubMenuItemTitle = (_, key) => {
		const item = this.getActiveItem(key);
		return (
			<span>
				<span className="rc-menu-input-text">
					{item.getSelectionText && item.getSelectionText(item.values, this.state.dates)}
				</span>
			</span>
		);
	};

	renderMenuTitle = title => {
		const filters = filter(this.props.activeFilters, function(filter) {
			return filter.key !== 'date';
		});
		const count = filter(filters, ({ hasSelection, standalone }) => hasSelection && !standalone).length;
		if (count > 0) {
			return (
				<React.Fragment>
					<span className={`selected ${this.props.advancedFilterWrapperClassName}`}>{title}</span>
					<div className="filter__counter">
						<span>{count}</span>
					</div>
				</React.Fragment>
			);
		} else {
			return <span className={this.props.advancedFilterTitleClassName}>{title}</span>;
		}
	};

	anyActiveHasSelection = () => {
		return filter(this.props.activeFilters, { hasSelection: true }).length > 0;
	};

	refreshData = () => {
		const { filters, activeFilters, updateFilters } = this.props;
		updateFilters({
			filters,
			activeFilters,
			forceRefresh: true,
		});
	};

	onKeyPressEnter = event => {
		if (event.key == 'Enter') {
			this.applyFilter();
		}
	};

	onToggleAdvanceFilter = () => {
		const currentState = this.state.displayAdvancedFilter;
		this.setState({
			displayAdvancedFilter: !currentState,
		});
	};

	onDefaultStateChange = () => {
		const currentState = this.state.expandAdvanceFilterByDefault;
		const newState = {
			expandAdvanceFilterByDefault: !currentState,
		};
		if (!currentState) {
			newState['displayAdvancedFilter'] = true;
		}
		this.setState(newState);
	};

	handleFiltersChange = () => {
		const { filters } = this.state;
		const newFilters = [...filters];
		const dateFilterIndex = findIndex(newFilters, ({ key }) => key === 'date');

		if (dateFilterIndex < 0) {
			return;
		}

		const willDisableDateFilter = newFilters[dateFilterIndex] && !newFilters[dateFilterIndex].values.disabled;
		each(filters, item => {
			if ((willDisableDateFilter && !item.allowsDateDisable) || item.removeOnDateEnable) {
				item.hasSelection = false;
				item.values = clone(item.defaultValues);
			}
		});
		if (dateFilterIndex > -1) {
			newFilters[dateFilterIndex].values = {
				...newFilters[dateFilterIndex].values,
				disabled: willDisableDateFilter,
			};

			this.setState({ filters: newFilters });
		} else {
			this.setState(filters);
		}
	};

	handleSecondaryKeysFilterChange = () => {
		const { filters } = this.state;
		const newFilters = [...filters];
		const secondaryKeysFilterIndex = findIndex(newFilters, ({ key }) => key === 'secondaryKeys');
		if (newFilters[secondaryKeysFilterIndex].hasSelection) {
			newFilters[secondaryKeysFilterIndex].hasSelection = false;
			newFilters[secondaryKeysFilterIndex].values = { secondaryKeys: false };
			return this.setState({ filters: newFilters });
		}

		if (secondaryKeysFilterIndex > -1) {
			const secondaryKeys = newFilters[secondaryKeysFilterIndex].values.secondaryKeys
				? newFilters[secondaryKeysFilterIndex].values.secondaryKeys
				: true;
			newFilters[secondaryKeysFilterIndex].values = {
				...newFilters[secondaryKeysFilterIndex].values,
				secondaryKeys,
			};
			newFilters[secondaryKeysFilterIndex].hasSelection = !!secondaryKeys;
		}
		this.setState({ filters: newFilters });
	};
	renderExpectSlowerResponse = () => {
		const secondaryKeysFilter = this.getItem('secondaryKeys');
		const secondaryKeys =
			get(secondaryKeysFilter, 'values.secondaryKeys', false) && get(secondaryKeysFilter, 'hasSelection', false);
		if (secondaryKeys) {
			return (
				<div className="display--ib type--xsml bg--chablis padd--sml type--color--primary spc--bottom--tny">
					Expect slower response time when viewing transactions for all accounts.
				</div>
			);
		}
	};
	renderFilterSelection = () => (
		<Fragment>
			{map(this.props.activeFilters, item =>
				item.hasSelection && item.selectionComponent && !item.values.disabled ? (
					<item.selectionComponent
						key={item.key}
						filter={item}
						onFilterRemoved={this.onFilterRemoved}
						isExporting={this.props.isExporting}
						showRangeMessage={this.props.showRangeMessage}
						{...item.props}
					/>
				) : null
			)}
		</Fragment>
	);

	renderSubMenuFilter = (item, isDateRangeChecked, isReferenceNumberFilter, permissions) => (
		<SubMenu
			key={item.key}
			disabled={(isDateRangeChecked || (isReferenceNumberFilter && item.key !== 'referenceNumber')) && !item.onTop}
			title={this.renderSubMenuItemTitle(item.name, item.key)}
			mode={'vertical-right'}
		>
			<MenuItem>
				<item.component
					filter={item}
					onFilterChanged={this.onFilterChanged}
					permissions={permissions}
					injectedFilters={this.injectFilters(item.injectFilters)}
					goButtonHandler={this.applyFilter}
					{...item.props}
				/>
			</MenuItem>
		</SubMenu>
	);
	renderDatePickerPredefined = () => {
		const { dates, activeKeys } = this.state;
		const activeDateFilter = this.getActiveItem('date');

		return (
			<DatePickerPredefined
				subMenuTitle={this.renderCustomSubMenuItemTitle('Custom', 'date')}
				onOpenChange={this.onOpenChange}
				activeKeys={activeKeys}
				filter={activeDateFilter}
				onApplyFilter={this.applyFilter}
				onFilterChanged={this.onFilterChanged}
				onActiveFilterChanged={this.onActiveFilterChanged}
				predefinedDates={dates}
				{...activeDateFilter.props}
			/>
		);
	};
	render() {
		const {
			displayAdvancedFilter,
			filters,
			permissions,
			permissions: { allowReportTransactions },
			activeKeys,
			expandAdvanceFilterByDefault,
			multiKeyEnabled,
		} = this.state;
		const { filterSelectionRef, clearFilters, className, title } = this.props;
		const filterHasValue =
			some(filters, item => item.allowsDateDisable && item.hasSelection) &&
			!some(filters, item => item.disallowsDateDisable && item.hasSelection);
		const dateFilter = this.getItem('date');
		const secondaryKeysFilter = this.getItem('secondaryKeys');
		const activeDateFilter = this.getActiveItem('date');
		const canDisableDateRange = dateFilter && has(dateFilter, 'values.disabled') && allowReportTransactions;
		const isDateRangeChecked = dateFilter && dateFilter.values.disabled;
		const isReferenceNumberFilter = some(filters, filter => filter.key === 'referenceNumber' && filter.hasSelection);
		const secondaryKeys =
			get(secondaryKeysFilter, 'values.secondaryKeys', false) && get(secondaryKeysFilter, 'hasSelection', false);
		const secondaryKeysDisabled = get(this.getItem('scheduleId'), 'hasSelection');
		const principal = principalService.get();

		return (
			<Fragment>
				{this.renderExpectSlowerResponse()}
				<div className="flex--primary flex--gap--sml">
					{activeDateFilter && <div className="filter__date">{this.renderDatePickerPredefined()}</div>}
					{map(
						filters,
						item =>
							item.standalone &&
							!item.afterFilter && (
								<div key={item.key} className="filter__search">
									<KeyboardEventHandler handleKeys={['enter']} onKeyEvent={() => this.applyStandaloneFilter(item)}>
										<item.component
											filter={item}
											onFilterChanged={this.onFilterChanged}
											permissions={permissions}
											noFocus={true}
											injectedFilters={this.injectFilters(item.injectFilters)}
											goButtonHandler={this.applyFilter}
											goButtonText={item.goButtonText}
											{...item.props}
										/>
									</KeyboardEventHandler>
								</div>
							)
					)}{' '}
					{some(filters, ({ key, standalone }) => key !== 'date' && !standalone) && (
						<div
							className="filter__select"
							tabIndex={0}
							onScroll={({ target: { id } }) => {
								if (toLower(id) === 'filter$menu') {
									this.setState({ activeKeys: filter(activeKeys, key => key === 'date ' || key === 'filter') });
								}
							}}
							onKeyPress={this.onKeyPressEnter}
						>
							<Menu
								className={`${className ? className : ''} rc-menu-datepicker-tooltip `}
								mode={'horizontal'}
								openAnimation={'slide-up'}
								triggerSubMenuAction={'click'}
								openKeys={activeKeys}
								onOpenChange={this.onOpenChange}
							>
								<SubMenu
									title={this.renderMenuTitle(title)}
									key="filter"
									popupClassName={`rc-menu-filter-dropdown ${isSafari ? 'rc-menu-safari' : ''}`}
								>
									{isReferenceNumberFilter && (
										<p className="badge badge--default spc--top--sml spc--right--med spc--bottom--sml spc--left--med">
											Ref Num cannot be used with other filters
										</p>
									)}
									{multiKeyEnabled && secondaryKeysFilter && (principal.list.length > 1 || secondaryKeys) && (
										<Fragment>
											<div
												ref={ref => {
													this.MenuRef = ref;
												}}
											></div>
											<div className="spc--left--xsml spc--right--xsml spc--top--xsml spc--bottom--xsml">
												<div>
													<div className="display--ib">
														<input
															type="checkbox"
															onChange={this.handleSecondaryKeysFilterChange}
															checked={!!secondaryKeys}
															value={!!secondaryKeys}
															name="secondaryKeys"
															id="secondaryKeys"
															disabled={secondaryKeysDisabled}
															className="input input--check"
														/>
														<label htmlFor="secondaryKeys" className="type--none">
															View Transactions for All Accounts
														</label>
													</div>
												</div>
											</div>
											<hr className="separator separator--primary" />
										</Fragment>
									)}

									{map(filters, item =>
										item.standalone ||
										!item.onTop ||
										!item.component ||
										!validatePermissions(values(item.permissions)) ? null : (
											<SubMenu
												key={item.key}
												title={this.renderSubMenuItemTitle(item.name, item.key)}
												mode={'vertical-right'}
												disabled={isReferenceNumberFilter && item.key !== 'referenceNumber'}
											>
												<MenuItem>
													<item.component
														filter={item}
														onFilterChanged={this.onFilterChanged}
														permissions={permissions}
														injectedFilters={this.injectFilters(item.injectFilters)}
														goButtonHandler={this.applyFilter}
														{...item.props}
													/>
												</MenuItem>
											</SubMenu>
										)
									)}

									{canDisableDateRange && (
										<Fragment>
											<div className="flex--primary spc--top--med spc--bottom--med spc--left--med">
												<div>
													<input
														type="checkbox"
														disabled={!filterHasValue}
														onChange={this.handleFiltersChange}
														checked={isDateRangeChecked}
														value={isDateRangeChecked}
														name="disableDateRange"
														id="disableDateRange"
														className="input--check"
													/>
													<label htmlFor="disableDateRange">Disable date range</label>
												</div>
												<i
													className="icon icon--tny icon--regular--info spc--left--sml datatooltip--w--140"
													data-tooltip="Requires exact match. This report will look back up to 2 years."
												></i>
											</div>
											<hr className="separator separator--primary" />
										</Fragment>
									)}

									{map(filters, item =>
										item.hide ||
										item.standalone ||
										item.onTop ||
										!item.component ||
										item.advancedFilter ||
										!validatePermissions(values(item.permissions))
											? null
											: this.renderSubMenuFilter(item, isDateRangeChecked, isReferenceNumberFilter, permissions)
									)}

									{some(filters, { advancedFilter: true }) && (
										<Fragment>
											<li className="rc-menu-item">
												<div className="rc-menu-footer rc-menu-advanced flex--tertiary">
													<button type="button" className="btn btn--link" onClick={this.onToggleAdvanceFilter}>
														<i
															className={`icon icon--sml icon--${
																displayAdvancedFilter ? 'minus' : 'add'
															}--primary spc--right--xsml`}
														></i>
														<span>Advanced</span>
													</button>
													<div>
														<input
															type="checkbox"
															onChange={this.onDefaultStateChange}
															checked={expandAdvanceFilterByDefault}
															name="expandAdvanceFilterByDefault"
															id="expandAdvanceFilterByDefault"
															className="input input--check"
														/>
														<label htmlFor="expandAdvanceFilterByDefault" className="type--none">
															Always Expand
														</label>
													</div>
												</div>
											</li>
										</Fragment>
									)}

									{map(filters, item =>
										item.standalone ||
										!item.component ||
										!item.advancedFilter ||
										!displayAdvancedFilter ||
										!validatePermissions(values(item.permissions))
											? null
											: this.renderSubMenuFilter(item, isDateRangeChecked, isReferenceNumberFilter, permissions)
									)}

									<MenuItem disabled={true}>
										<div className="rc-menu-footer rc-menu-footer-alt">
											<button type="button" onClick={clearFilters} className="btn btn--link btn--link--tertiary">
												Reset all
											</button>
											<button
												type="button"
												onClick={this.applyFilter}
												className="btn btn--link type--wgt--semibold type--uppercase"
											>
												Done
											</button>
										</div>
									</MenuItem>
								</SubMenu>
							</Menu>
						</div>
					)}
					{map(
						filters,
						item =>
							item.standalone &&
							item.afterFilter && (
								<div key={item.key} className="filter__search">
									<KeyboardEventHandler handleKeys={['enter']} onKeyEvent={() => this.applyStandaloneFilter(item)}>
										<item.component
											filter={item}
											onFilterChanged={this.onFilterChanged}
											permissions={permissions}
											noFocus={true}
											injectedFilters={this.injectFilters(item.injectFilters)}
											goButtonHandler={this.applyFilter}
											{...item.props}
										/>
									</KeyboardEventHandler>
								</div>
							)
					)}
				</div>
				{filterSelectionRef &&
					filterSelectionRef.current &&
					createPortal(this.renderFilterSelection(), filterSelectionRef.current)}
			</Fragment>
		);
	}
}

MainFilterComponent.defaultProps = {
	predefinedDates,
	title: 'Add Filter',
	advancedFilterTitleClassName: '',
	advancedFilterWrapperClassName: 'hide--to--xxlrg',
	displayStandaloneDatePicker: false,
};

MainFilterComponent.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	updateFilters: PropTypes.func,
	filters: PropTypes.array,
	activeFilters: PropTypes.array,
	clearFilters: PropTypes.func,
	filterSelectionRef: PropTypes.any,
	predefinedDates: PropTypes.array,
	isExporting: PropTypes.bool,
	showHideAccountKeyColumn: PropTypes.func,
	advancedFilterTitleClassName: PropTypes.string,
	advancedFilterWrapperClassName: PropTypes.string,
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	showRangeMessage: PropTypes.any,
	displayStandaloneDatePicker: PropTypes.bool,
};

export default withError(withCancelable(MainFilterComponent));
