import React, { Component } from 'react';

import sectionKeys from '../../routing/sections';
import NestedRoutes from 'routing/NestedRoutes';
import StatementsGrid from './StatementsGrid';

const routes = [
	{
		path: '/1099k',
		component: StatementsGrid,
		section: sectionKeys.statements1099k,
		title: '1099K Statements',
		mobileTitle: '1099K Statements',
		linkTitle: '1099K Statements',
	},
	{
		path: '/merchant-processing-statements',
		component: StatementsGrid,
		section: sectionKeys.statementsMerchantProcessing,
		title: 'Merchant Processing Statements',
		mobileTitle: 'Merchant Processing Statements',
		linkTitle: 'Merchant Processing Statements',
	},
];

class StatementsTabs extends Component {
	render() {
		return <NestedRoutes tabs={routes} />;
	}
}

export default StatementsTabs;
