import React, { Component, createRef, Fragment } from 'react';
import { cloneDeep, each, keys, toLower, map, noop, transform, camelCase, startsWith, snakeCase, get } from 'lodash';
import PropTypes from 'prop-types';
import { Data } from 'react-data-grid-addons';

import { GridComponent } from 'common/components/grid';
import { Notification } from 'common/components/notifications';
import { keyManagementColumns } from 'common/components/keymanagement/column-filter/keymanagementColumn';
import AddEditKey from 'common/components/keymanagement/popup/add-edit-key';
import KeyProvider from 'common/components/keymanagement/popup/key-provider';
import ConfirmDeleteModal from 'common/components/keymanagement/popup/confirm-delete-key';
import { withCancelable } from 'common/components/cancelable';
import { withError } from 'common/components/error';
import { ActionsModal } from 'common/components/transaction-actions';
import { keyManagementService, principalService } from 'common/services';
import { withLoader } from 'common/components/loader';

const requestKeys = {
	fetch: 'FETCH',
	list: 'LIST',
	load: 'LOAD',
	save: 'SAVE',
	create: 'CREATE',
	remove: 'REMOVE',
};

class KeyManagement extends Component {
	constructor() {
		super();
		this.notification = createRef();
		this.keyProvider = createRef();
		this.gridRef = createRef();
		this.classes = {
			wrapper: 'settings--main',
			header: '',
			gridHeader: 'col col-sml-12',
			filter: 'col col-sml-6 col-med-5',
			filterContainer: 'spc--bottom--sml',
			title: 'settings__header__title',
			headerMenu: '',
			headerMenuAction: 'settings__header__action',
			headerGroup: '',
			content: 'grid__holder__users',
		};
		this.components = {
			modal: ActionsModal,
		};

		const principal = principalService.get();

		this.state = {
			fetchingData: false,
			expanded: false,
			fetchingAdditionalData: false,
			filteredRows: [],
			inlineFilters: {},
			columns: cloneDeep(keyManagementColumns),
			data: {},
			lastApiRefNum: '',
			shouldHideModal: false,
			showKeyProvider: false,
			accountId: principal.idInfo.xMerchantID,
			isCognitoAdmin: get(principal, 'isAdmin', false),
		};
	}

	handleChange = changes => {
		const newState = {};
		each(changes, ({ key, value }) => {
			if (key === 'data' || key === 'inlineFilters') {
				let filters, data;
				if (key === 'data') {
					filters = this.state.inlineFilters;
					data = value;
				} else {
					filters = value;
					data = this.state.data;
				}
				newState.filteredRows =
					data && data.xReportData
						? Data.Selectors.getRows({
								rows: data.xReportData,
								filters,
						  })
						: [];
			}
			newState[key] = value;
		});
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	fetchData = async () => {
		this.setState({
			fetchingData: true,
			data: null,
			filteredeRows: [],
		});

		try {
			const data = await this.props.makePendingRequest(this.getUserKeys());
			if (data && data.xReportData) {
				data.xReportData = map(data.xReportData, this.mapRow);
			}
			if (this.gridRef.current) {
				this.gridRef.current.scrollTo({ top: 0, left: 0 });
			}

			this.setState(
				{
					data: data,
					fetchingData: false,
					filteredRows: data ? data.xReportData : [],
					lastApiRefNum: data ? data.xRefNum : '',
				},
				() => {
					if (this.gridRef.current) {
						// this.gridRef.current.handleInitialSort();
					}
				}
			);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.setState({
					fetchingData: false,
				});
			}
		}
	};

	refetchData = () => {
		this.fetchData();
	};

	actions = row => {
		return [
			{
				className: 'btn btn--link datatooltip--left datatooltip--auto',
				action: noop,
				component: ConfirmDeleteModal,
				tooltip: 'Delete',
				key: 'delete',
				icon: 'delete--light',
				componentProps: {
					onConfirm: this.deleteKey,
					isLoading: this.props.isLoading,
					data: row,
					shouldHideModal: () => this.state.shouldHideModal,
				},
			},
			{
				className: 'btn btn--link datatooltip--left datatooltip--auto',
				action: noop,
				component: AddEditKey,
				tooltip: 'Duplicate',
				key: 'duplicate',
				icon: 'copy--light',
				dataTooltip: 'Duplicate',
				componentProps: {
					action: 'duplicate',
					createNewKey: this.createNewKey,
					shouldHideModal: () => this.state.shouldHideModal,
					isLoading: this.props.isLoading,
					data: row,
					fetchKey: this.fetchKey,
				},
			},
			{
				className: 'btn btn--link datatooltip--left datatooltip--auto',
				action: noop,
				component: AddEditKey,
				tooltip: 'Edit',
				key: 'edit',
				icon: 'edit--light',
				dataTooltip: 'Edit',
				componentProps: {
					action: 'edit',
					saveChanges: this.saveChanges,
					shouldHideModal: () => this.state.shouldHideModal,
					isLoading: this.props.isLoading,
					data: row,
					fetchKey: this.fetchKey,
				},
			},
		];
	};

	mapRow = row => {
		if (row.keyType === 'standard') row.keyType = 'API';
		if (row.keyType === 'ifields') row.keyType = 'iFields';
		return {
			...row,
			actions: [...this.actions(row)],
		};
	};

	getUserKeys = async () => {
		const filterValue = ['standard', 'ifields'];
		if (this.state.isCognitoAdmin) {
			filterValue.push('paymentsite');
		}
		try {
			const { accountId } = this.state;
			const { data, refnum } = await this.props.makePendingRequest(
				keyManagementService.list(accountId, {
					filtername: 'KeyType',
					filterValue,
				}),
				requestKeys.list
			);

			return {
				xReportData: this.mapResponse(data),
				xRecordsReturned: keys(data).length,
				xRefNum: refnum,
			};
		} catch (e) {
			if (toLower(e && e.message) === 'admin account inactive') {
				return;
			} else {
				this.props.handleError(e);
			}
		}
	};
	showHideModal = value => {
		this.setState({ shouldHideModal: value }, () => {
			if (this.gridRef.current) {
				this.gridRef.current.calculateColumnWidths(null, cloneDeep(this.state.columns));
			}
		});
	};

	createNewKey = async (data, closeModal, errorHandler) => {
		try {
			const principal = principalService.get();
			data.index1 = principal && principal.idInfo.xMerchantID;
			this.showHideModal(false);
			this.props.showLoader(true);
			if (!(await this.isDuplicate(data))) {
				const response = await this.props.makePendingRequest(keyManagementService.create(data), requestKeys.create);
				const { ref, key, result, message } = response;
				closeModal();

				if (result === 'Success') {
					this.refetchData();
					this.keyProvider.current.show({ key, ref });
				} else {
					throw message ? { message, ref } : response;
				}
			} else {
				errorHandler({
					key: 'description',
					message: 'Another key exists with the same Description. Please define a different one.',
				});
			}
			this.props.showLoader(false);
		} catch (e) {
			const message = get(e, 'message', '');
			const ref = get(e, 'ref', '');
			const notification = this.props.handleError(e, { additionalInfo: { message: message, ref } });
			if (notification) {
				if (startsWith(toLower(message), 'unable to create key')) {
					notification.message = (
						<div className="spc--bottom--sml">
							{'Unable to create User (MK). \nPlease reach out to Support for assistance.'}
						</div>
					);
				}
				closeModal();
				this.props.showLoader(false);
				this.showHideModal(true);
			}
		}
	};
	fetchKey = async key => {
		try {
			let result = {};
			const permissions = {};
			this.props.showLoader(true);
			const { data } = await this.props.makePendingRequest(keyManagementService.load(null, key), requestKeys.load);
			this.keyInfo = data;
			const mappedData = transform(data, (acc, value, key) => (acc[camelCase(key)] = value));
			const { keyType, keyDescription, role, ddbRevision, key2, index1, whiteListServerIPs } = mappedData;
			each(mappedData, (value, key) => {
				if (startsWith(key, 'allow')) {
					const val = toLower(value) === 'true';
					permissions[key] = val;
				}
			});
			result = {
				keyType,
				keyDescription,
				role,
				ddbRevision,
				key2,
				index1,
				permissions,
				whiteListServerIPs,
				data,
			};
			this.props.showLoader(false);
			return result;
		} catch (e) {
			if (this.props.handleError(e)) {
				this.props.showLoader(false);
			}
		}
	};

	saveChanges = async (data, closeModal, errorHandler) => {
		this.modifyData(data);
		try {
			this.showHideModal(false);
			this.props.showLoader(true);
			if (!(await this.isDuplicate(data))) {
				const response = await this.props.makePendingRequest(keyManagementService.save(data), requestKeys.save);
				const success = toLower(response.result) === 'success';
				if (!success) {
					throw response;
				}
				this.refetchData();
				closeModal();
				this.notification.current.addNotification({
					message: 'Key saved',
					ref: response.refnum,
					success: true,
				});
			} else {
				errorHandler({
					key: 'description',
					message: 'Another key exists with the same Description. Please define a different one.',
				});
			}
			this.props.showLoader(false);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.props.showLoader(false);
				this.showHideModal(true);
			}
		}
	};

	modifyData = data => {
		each(this.keyInfo, (value, key) => {
			const lowerKey = toLower(key);
			const isWhiteListedIps = startsWith(toLower(key), 'white') && data['whiteListedIPs'];
			const dataHasValue = data[camelCase(key)] || data[lowerKey] || data[snakeCase(key)] || isWhiteListedIps;
			const isRevision = toLower(key) === 'ddb_revision';
			const isPermission = startsWith(lowerKey, 'allow');
			if (dataHasValue === undefined && !isRevision && !isPermission) {
				data[key] = value;
			}
		});
	};
	deleteKey = async ({ key }) => {
		try {
			this.showHideModal(false);
			this.props.showLoader(true);
			const response = await this.props.makePendingRequest(keyManagementService.load(null, key), requestKeys.remove);
			const { data: keyObj } = response;
			keyObj.Inactive = 'True';
			keyObj.Role = toLower(keyObj.Role);
			const { refnum } = await this.props.makePendingRequest(
				keyManagementService.remove({ data: keyObj }),
				requestKeys.remove
			);
			this.refetchData();
			this.props.showLoader(false);
			this.notification.current.addNotification({
				message: 'Key removed',
				ref: refnum,
				success: true,
			});
		} catch (e) {
			if (this.props.handleError(e)) {
				this.props.showLoader(false);
				this.showHideModal(true);
			}
		}
	};

	isDuplicate = async key => {
		const {
			data: { xReportData },
		} = this.state;

		let duplicateKey = this.getDuplicates(xReportData, key);

		if (!duplicateKey) {
			const data = await this.props.makePendingRequest(this.getUserKeys());
			if (data && data.xReportData) {
				data.xReportData = map(data.xReportData, this.mapRow);
				duplicateKey = this.getDuplicates(data.xReportData, key);
				return !!duplicateKey;
			}
		}

		// On edit
		if (key.key2) duplicateKey = !(duplicateKey.key === key.key2);

		return !!duplicateKey;
	};

	getDuplicates = (data, key) => {
		return data.find(report => toLower(report.keyDescription) === toLower(key.keyDescription));
	};

	mapResponse = response => {
		const result = [];
		each(response, ({ KeyType, Role, KeyDescription }, key) => {
			result.push({
				key: key,
				keyType: KeyType,
				Role,
				keyDescription: KeyDescription,
			});
		});

		return result;
	};

	renderHeaderDisclaimer = () => (
		<div className="settings__header">
			<h3 className="settings__title">Gateway Settings</h3>
			<div className="flex--tertiary flex--nowrap">
				<div>
					<h5 className="spc--bottom--sml">Key Management</h5>
					<p className="type--p4 type--color--text--light">
						For more details on the API calls associated with these permissions, see the{' '}
						<a className="btn btn--link" href="https://kb.cardknox.com/api/" rel="noopener noreferrer" target="_blank">
							Cardknox API documentation
						</a>
						{/*
						 */}
						.
					</p>
				</div>
				{this.renderHeader()}
			</div>
		</div>
	);
	renderHeader = () => {
		const { shouldHideModal } = this.state;
		const { isLoading } = this.props;
		return (
			<Fragment>
				<AddEditKey
					className="btn btn--med btn--primary spc--bottom--sml"
					createNewKey={this.createNewKey}
					action="add"
					shouldHideModal={() => shouldHideModal}
					isLoading={isLoading}
				>
					Create a Key
				</AddEditKey>
			</Fragment>
		);
	};

	render() {
		const {
			fetchingData,
			fetchingAdditionalData,
			filteredRows,
			columns,
			data,
			lastApiRefNum,
			inlineFilters,
		} = this.state;
		return (
			<Fragment>
				{this.renderHeaderDisclaimer()}
				<GridComponent
					classes={this.classes}
					fetchingData={fetchingData}
					fetchingAdditionalData={fetchingAdditionalData}
					columns={columns}
					data={data}
					filteredRows={filteredRows}
					inlineFilters={inlineFilters}
					useInlineFilters={true}
					onChange={this.handleChange}
					hasPaging={false}
					title={this.renderHeaderDisclaimer()}
					renderDisclaimerBody={this.renderDisclaimerInfo}
					fetchData={this.fetchData}
					lastApiRefNum={lastApiRefNum}
					showResults={true}
					showPanel={false}
					showPrintDropdown={false}
					showGridHeader={false}
					ref={this.gridRef}
					headerMenuThreshold={Infinity}
					components={this.components}
					hideFooterResults={true}
				/>
				<Notification ref={this.notification} />
				<KeyProvider ref={this.keyProvider} title="Key Created"></KeyProvider>
			</Fragment>
		);
	}
}

KeyManagement.propTypes = {
	handleError: PropTypes.func,
	makePendingRequest: PropTypes.func,
	showLoader: PropTypes.func,
	isLoading: PropTypes.bool.isRequired,
};

export default withError(withLoader(withCancelable(KeyManagement)));
