import React, { useReducer, useEffect, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { get, includes } from 'lodash';
import { Modal } from 'common/components/modal';
import settingService from 'common/services/settingsService';
import { Notification } from 'common/components/notifications';
import { CardTypeImagePath } from 'common/utilities';
import BlockSuccessModal from './block-success-modal';
import {
	blockTransactionReducer,
	initialState,
	setBlockOptions,
	setFormData,
	setLoading,
	setLoadingSettings,
	setCurrentSettings,
	setShowSuccessModal,
	setBlockedItems,
	toggleBlockOption,
	resetState,
} from './reducers/block-transaction-reducer';

const BlockTransactionModal = ({ isOpen, onClose, data, history }) => {
	const [state, dispatch] = useReducer(blockTransactionReducer, initialState);
	const { blockOptions, formData, isLoading, loadingSettings, currentSettings, showSuccessModal, blockedItems } = state;

	const notificationRef = useRef(null);

	useEffect(() => {
		if (isOpen) {
			loadFraudSettings();
			updateFormDataFromProps();
		} else if (!showSuccessModal) {
			dispatch(resetState());
		}
	}, [isOpen, data, showSuccessModal]);

	const loadFraudSettings = async () => {
		dispatch(setLoadingSettings(true));
		try {
			const advancedFraudSettings = await settingService.loadSettings('AdvancedFraudFeatures');
			let { cardknoxSettings: affSettings } = advancedFraudSettings;

			affSettings = convertAffSettingsToArray(affSettings);

			dispatch(setCurrentSettings(affSettings));
		} catch (error) {
			if (notificationRef.current) {
				notificationRef.current.addNotification({
					message: 'Failed to load fraud settings',
					success: false,
				});
			}
		} finally {
			dispatch(setLoadingSettings(false));
		}
	};

	const convertAffSettingsToArray = affSettings => {
		const convertedSettings = {};

		Object.keys(affSettings).forEach(key => {
			let value = affSettings[key];

			if (typeof value === 'string') {
				if (value.toLowerCase() === 'true') {
					convertedSettings[key] = true;
				} else if (value.toLowerCase() === 'false') {
					convertedSettings[key] = false;
				} else if (value === '') {
					if (key === 'AFF_Allow_Country') {
						convertedSettings[key] = [''];
					} else {
						convertedSettings[key] = [];
					}
				} else if (
					key === 'AFF_Block_Email' ||
					key === 'AffBlockName' ||
					key === 'AFF_Block_IP_3' ||
					key === 'AFF_Block_IP_4' ||
					key === 'AFF_BlockCardByPaymentId'
				) {
					convertedSettings[key] = value.split(',').filter(Boolean);
				} else {
					convertedSettings[key] = value;
				}
			} else {
				convertedSettings[key] = value;
			}
		});

		return convertedSettings;
	};

	const convertAffSettingsForApi = affSettings => {
		const convertedSettings = {};

		Object.keys(affSettings).forEach(key => {
			let value = affSettings[key];

			if (Array.isArray(value)) {
				if (value[0] === '') {
					value = value.slice(1);
				}

				convertedSettings[key] = value.join(',');
			} else {
				convertedSettings[key] = value;
			}
		});

		return convertedSettings;
	};

	const updateFormDataFromProps = () => {
		const emailAddress = get(data, 'xEmail', '');
		const ipAddress = get(data, 'xIP', '');
		const name = get(data, 'xName', '');
		const maskedCardNumber = get(data, 'xMaskedCardNumber', '');
		const paymentId = get(data, 'xPaymentId', '');
		const refNum = get(data, 'xRefNum', '');

		const canBlockCard = !!maskedCardNumber && !!paymentId && !!refNum;

		dispatch(
			setFormData({
				emailAddress,
				ipAddress,
				name,
				paymentId,
				refNum,
				maskedCardNumber,
			})
		);

		dispatch(
			setBlockOptions({
				blockCreditCard: canBlockCard,
				blockEmailAddress: !!emailAddress,
				blockIPAddress: !!ipAddress,
				blockName: !!name,
			})
		);
	};

	const handleOptionChange = e => {
		const { name, checked } = e.target;
		dispatch(toggleBlockOption(name, checked));
	};

	const handleSubmit = async () => {
		if (!currentSettings) {
			if (notificationRef.current) {
				notificationRef.current.addNotification({
					message: 'Settings not loaded. Please try again.',
					success: false,
				});
			}
			return;
		}

		dispatch(setLoading(true));

		try {
			const updatedSettings = { ...currentSettings };
			const actuallyBlocked = {
				xCardType: get(data, 'xCardType', ''),
				xMaskedCardNumber: '',
				xEmail: '',
				xIP: '',
				xName: '',
			};

			const blockConfigs = [
				{
					enabled: blockOptions.blockCreditCard && formData.paymentId && formData.maskedCardNumber && formData.refNum,
					settingKey: 'AFF_BlockCardByPaymentId',
					value: `${formData.paymentId}_${formData.maskedCardNumber}_${formData.refNum}`,
					blockedKey: 'xMaskedCardNumber',
					blockedValue: formData.maskedCardNumber,
				},
				{
					enabled: blockOptions.blockEmailAddress && formData.emailAddress,
					settingKey: 'AFF_Block_Email',
					value: formData.emailAddress,
					blockedKey: 'xEmail',
					blockedValue: formData.emailAddress,
				},
				{
					enabled: blockOptions.blockName && formData.name,
					settingKey: 'AffBlockName',
					value: formData.name,
					blockedKey: 'xName',
					blockedValue: formData.name,
				},
				{
					enabled: blockOptions.blockIPAddress && formData.ipAddress,
					settingKey: includes(formData.ipAddress, '*') ? 'AFF_Block_IP_3' : 'AFF_Block_IP_4',
					value: formData.ipAddress,
					blockedKey: 'xIP',
					blockedValue: formData.ipAddress,
				},
			];

			blockConfigs.forEach(config => {
				if (config.enabled && addToSettingsArray(updatedSettings, config.settingKey, config.value)) {
					actuallyBlocked[config.blockedKey] = config.blockedValue;
				}
			});

			const apiSettings = convertAffSettingsForApi(updatedSettings);
			await settingService.updateSettings('AdvancedFraudFeatures', apiSettings);
			dispatch(setBlockedItems(actuallyBlocked));
			dispatch(setShowSuccessModal(true));
			onClose();
		} catch (error) {
			if (notificationRef.current) {
				notificationRef.current.addNotification({
					message: 'Failed to update settings',
					success: false,
				});
			}
		} finally {
			dispatch(setLoading(false));
		}
	};

	const addToSettingsArray = (settings, key, value) => {
		if (!Array.isArray(settings[key])) {
			settings[key] = [];
		}

		if (!includes(settings[key], value)) {
			settings[key] = [...settings[key], value];
			return true;
		}

		return false;
	};
	const handleManageBlockList = () => {
		dispatch(setShowSuccessModal(false));
		if (history && history.push) {
			history.push('/settings/gateway-settings/fraud');
		}
	};

	const cardType = get(data, 'xCardType', '');
	const maskedCardNumber = get(data, 'xMaskedCardNumber', '');
	const emailAddress = get(data, 'xEmail', '');
	const ipAddress = get(data, 'xIP', '');
	const name = get(data, 'xName', '');
	const paymentId = get(data, 'xPaymentId', '');
	const refNum = get(data, 'xRefNum', '');

	const canBlockCard = !!maskedCardNumber && !!paymentId && !!refNum;

	return (
		<Fragment>
			<Notification ref={notificationRef} />

			<Modal isOpen={isOpen} onClose={onClose} className="modal__content modal--med">
				<div className="modal__header">
					<h4>Add Card to block list</h4>
				</div>

				<div className="modal__body">
					{loadingSettings ? (
						<div className="loader__holder">
							<div className="loader__spinner"></div>
						</div>
					) : (
						<div className="input--check--enable-form">
							{maskedCardNumber && (
								<div className="flex--tertiary flex--gap--med fullwidth">
									<div>
										<input
											type="checkbox"
											name="blockCreditCard"
											id="blockCreditCard"
											className="input--check"
											checked={blockOptions.blockCreditCard}
											onChange={handleOptionChange}
											disabled={!canBlockCard}
										/>
										<label htmlFor="blockCreditCard">
											Block Credit Card
											{!canBlockCard && (
												<span className="type--p2 type--color--text--light"> (Payment ID required)</span>
											)}
										</label>
									</div>
									<div className="flex--primary flex--gap--sml flex--nowrap">
										{cardType && <img src={CardTypeImagePath.getPath(cardType)} className="w--24" alt={cardType} />}
										<p className="type--p1 type--color--text--secondary">{maskedCardNumber}</p>
									</div>
								</div>
							)}

							{emailAddress && (
								<div className="flex--tertiary flex--gap--med fullwidth">
									<div>
										<input
											type="checkbox"
											name="blockEmailAddress"
											id="blockEmailAddress"
											className="input--check"
											checked={blockOptions.blockEmailAddress}
											onChange={handleOptionChange}
										/>
										<label htmlFor="blockEmailAddress">Block Email Address</label>
									</div>
									<p className="type--p2 type--color--text--secondary">{emailAddress}</p>
								</div>
							)}

							{ipAddress && (
								<div className="flex--tertiary flex--gap--med fullwidth">
									<div>
										<input
											type="checkbox"
											name="blockIPAddress"
											id="blockIPAddress"
											className="input--check"
											checked={blockOptions.blockIPAddress}
											onChange={handleOptionChange}
										/>
										<label htmlFor="blockIPAddress">Block IP Address</label>
									</div>
									<p className="type--p2 type--color--text--secondary">{ipAddress}</p>
								</div>
							)}

							{name && (
								<div className="flex--tertiary flex--gap--med fullwidth">
									<div>
										<input
											type="checkbox"
											name="blockName"
											id="blockName"
											className="input--check"
											checked={blockOptions.blockName}
											onChange={handleOptionChange}
										/>
										<label htmlFor="blockName">Block Name</label>
									</div>
									<p className="type--p2 type--color--text--secondary">{name}</p>
								</div>
							)}

							{!maskedCardNumber && !emailAddress && !ipAddress && !name && (
								<div className="spc--bottom--med">
									<p className="type--color--text--light">No blockable information found in this transaction.</p>
								</div>
							)}
						</div>
					)}
				</div>

				<div className="modal__footer">
					<button
						type="button"
						className="btn btn--med btn--primary"
						onClick={handleSubmit}
						disabled={
							isLoading ||
							loadingSettings ||
							(!blockOptions.blockCreditCard &&
								!blockOptions.blockEmailAddress &&
								!blockOptions.blockIPAddress &&
								!blockOptions.blockName)
						}
					>
						{isLoading ? 'Processing...' : 'Block'}
					</button>
				</div>
			</Modal>

			<BlockSuccessModal
				isOpen={showSuccessModal}
				onClose={() => dispatch(setShowSuccessModal(false))}
				blockedItems={blockedItems}
				onManageBlockList={handleManageBlockList}
			/>
		</Fragment>
	);
};

BlockTransactionModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	history: PropTypes.object,
	data: PropTypes.shape({
		xCardType: PropTypes.string,
		xMaskedCardNumber: PropTypes.string,
		xEmail: PropTypes.string,
		xName: PropTypes.string,
		xIP: PropTypes.string,
		xPaymentId: PropTypes.string,
		xRefNum: PropTypes.string,
	}),
};

export default BlockTransactionModal;
