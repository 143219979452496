import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Data } from 'react-data-grid-addons';
import { cloneDeep, map, each, find, isEmpty } from 'lodash';
import moment from 'moment';

import { ViewPaymentsColumns as Columns } from 'common/components/view-payments/column-filter/viewPaymentsColumns';
import { transactionsFilter as Filter } from 'common/components/transactions/filter/transactionsFilter';
import { ZebraRenderer } from 'common/components/row';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { customerService } from 'common/services';
import PopupGrid from 'common/components/grid/PopupGrid';
import { exportService } from 'common/components/export/exportService';
import { PopupGridTooltip } from 'common/components/tooltips';
import { withRouter } from 'react-router-dom';

const { apiResponseDateTimeFormat, displayDateTimeFormat } = ApplicationSettings;

const requestKeys = {
	FETCH: 'fetch',
	SAVE: 'save',
};

class ViewPaymentsGrid extends Component {
	constructor() {
		super();

		this.gridRef = createRef();

		this.state = {
			...this.addFiltersToState(cloneDeep(this.initialState)),
			columns: Columns,
			defaultColumns: cloneDeep(Columns),
			loadMoreLimit: 50,
			fields: this.fields,
		};

		this.components = {
			rowRenderer: ZebraRenderer,
			title: this.renderTitle,
			tooltip: PopupGridTooltip,
		};

		this.classes = {
			printWrapper: 'datatooltip--v--bottom',
			exportWrapper: 'datatooltip--v--bottom',
			filterContainer: '',
			filter: 'spc--bottom--sml spc--right--sml',
			gridHeader: 'spc--bottom--sml spc--right--xxxlrg',
			gridHolder: 'react-grid',
			gridWrapper: 'grid__holder--override',
			headerGroup: 'flex--primary spc--bottom--sml',
		};
	}

	get initialState() {
		return {
			data: null,
			filteredRows: [],
			expanded: {},
			fetchingData: true,
			fetchingAdditionalData: false,
			lastApiRefNum: null,
			initiallyOpenNewCustomer: false,
		};
	}

	fetchData = async (maxRecords = 20) => {
		this.setState({
			fetchingData: true,
			data: null,
			filteredRows: [],
			expanded: {},
			lastApiRefNum: null,
		});
		let lastApiRefNum = null;

		try {
			const data = await this.props.makePendingRequest(
				maxRecords
					? customerService.getRecurringTransactions(this.props.scheduleId)
					: customerService.getRecurringTransactionsAll(this.props.scheduleId),
				requestKeys.FETCH
			);
			lastApiRefNum = data.xRefNum;
			if (data && data.xReportData) {
				data.xReportData = map(data.xReportData, this.mapRow);
			}

			if (maxRecords === 0) {
				data.xRecordsReturned = data.xReportData.length;
				data.xReportingMaxCustomers = data.xReportData.length + 1; // +1 = quick fix
			}
			this.mapData(data);
			const filteredRows =
				data && data.xReportData
					? Data.Selectors.getRows({
							rows: data.xReportData,
							filters: this.state.inlineFilters,
					  })
					: [];
			if (this.gridRef.current) {
				this.gridRef.current.scrollTo({ top: 0, left: 0 });
			}

			this.setState(
				{
					data,
					originalData: cloneDeep(data),
					filteredRows,
					fetchingData: false,
					lastApiRefNum: lastApiRefNum,
				},
				() => {
					if (this.gridRef.current) {
						this.gridRef.current.handleInitialSort();
					}
				}
			);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.setState({
					fetchingData: false,
				});
			}
		}
	};

	refetchData = () => {
		this.fetchData();
	};

	handleChange = changes => {
		const newState = {};
		each(changes, ({ key, value }) => {
			if (key === 'data' || key === 'inlineFilters') {
				let filters, data;
				if (key === 'data') {
					filters = this.state.inlineFilters;
					data = value;
				} else {
					filters = value;
					data = this.state.data;
				}
				newState.filteredRows =
					data && data.xReportData
						? Data.Selectors.getRows({
								rows: data.xReportData,
								filters,
						  })
						: [];
			}
			newState[key] = value;
		});
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	resolveColumnName = column => {
		let key = column;
		switch (column) {
			case 'CardData':
				key = 'xMaskedCardNumber';
				break;
			case 'AmountData':
				key = 'xAmount';
				break;
			case 'RefNumData':
				key = 'xRefNum';
				break;
			case 'xEnteredDate':
				key = 'xEnteredDateMoment';
				break;
			default:
				break;
		}
		return key;
	};

	redirectToTransactionsGrid = (loadAll = false) => {
		const { history, scheduleId } = this.props;
		history.push({
			pathname: `/transactions`,
			search: `scheduleId=${scheduleId}${loadAll ? '&loadAll=true' : ''}&disabled=true`,
		});
	};

	onInfoHover = (infoDimensions, tooltip) => {
		this.setState({ tooltipProps: { infoDimensions, tooltip } });
	};

	mapRow = row => ({
		...row,
		gridRef: this.gridRef.current ? this.gridRef.current : null,
		handleError: this.props.handleError,
		onInfoHover: this.onInfoHover,
	});

	mapData = data => {
		let i = 0;
		if (data && data.xReportData && data.xReportData.length > 0) {
			each(data.xReportData, item => {
				if (moment.isMoment(item.xEnteredDate)) {
					item.xEnteredDateMoment = moment(item.xEnteredDate, apiResponseDateTimeFormat);
					item.xEnteredDate = item.xEnteredDateMoment.format(displayDateTimeFormat);
				}
				if (item.xMaskedCardNumber && item.xMaskedCardNumber.includes('xxx')) {
					item.xMaskedCardNumber = `**** ${item.xMaskedCardNumber.slice(-4)}`;
				}

				item.gridRowNumber = i;
				item.index = i + 1;
				i++;
			});
		}
	};

	formatColumns = (columns, appliedFilter = null) => {
		if (appliedFilter) {
			for (let prop in appliedFilter) {
				if (appliedFilter.hasOwnProperty(prop)) {
					let column = find(columns, i => {
						return i.key.toLowerCase() === prop.toLowerCase() && !i.visible;
					});

					if (column) {
						column.visible = true;
					}
				}
			}
		}
		return columns;
	};

	addFiltersToState = state => {
		const filters = Filter;
		return {
			...state,
			filters: filters,
			activeFilters: cloneDeep(filters),
			inlineFilters: {},
		};
	};

	hasMoreData = data => {
		return (
			data && data.xReportData && data.xReportData.length > 0 && data.xRecordsReturned >= data.xReportingMaxTransactions
		);
	};

	renderTitle = () => {
		const { scheduleName, scheduleId } = this.props;

		return (
			<div>
				<h4 className="type--wgt--medium spc--bottom--tny">View Payments</h4>
				<p className="type--sml type--color--text--light type--break-word spc--bottom--sml">
					Schedule {scheduleName ? 'Name' : 'ID'}: {scheduleName || scheduleId}
				</p>
			</div>
		);
	};

	renderViewInTransactionReport = () => {
		const { filteredRows, fetchingData, fetchingAdditionalData } = this.state;

		return (
			<div className="datatooltip--down spc--right--sml" data-tooltip="View in Transactions Report">
				<button
					disabled={isEmpty(filteredRows) || fetchingData || fetchingAdditionalData}
					onClick={() => this.redirectToTransactionsGrid()}
					className="btn btn--action btn--action--secondary"
				>
					<i className="icon icon--sml icon--view"></i>
				</button>
			</div>
		);
	};

	renderViewAllPayments = () => {
		const { data, fetchingData, fetchingAdditionalData } = this.state;

		return (
			this.hasMoreData(data) && (
				<button
					disabled={fetchingData || fetchingAdditionalData}
					data-tooltip="View in Transactions Report"
					onClick={() => this.redirectToTransactionsGrid(true)}
					className="btn btn--med btn--secondary"
				>
					View All Schedule Payments
				</button>
			)
		);
	};

	render() {
		const {
			fetchingData,
			fetchingAdditionalData,
			filteredRows,
			columns,
			data,
			inlineFilters,
			expanded,
			filters,
			activeFilters,
			lastApiRefNum,
			defaultColumns,
			tooltipProps,
		} = this.state;
		const { popupRef } = this.props;

		return (
			<PopupGrid
				title="View Payments"
				emptyMessage="You should change your filter options"
				fetchingData={fetchingData}
				fetchingAdditionalData={fetchingAdditionalData}
				filteredRows={filteredRows}
				columns={columns}
				data={data}
				resolveColumnName={this.resolveColumnName}
				inlineFilters={inlineFilters}
				components={this.components}
				classes={this.classes}
				onChange={this.handleChange}
				expanded={expanded}
				type="transactions"
				filters={filters}
				activeFilters={activeFilters}
				fetchData={this.refetchData}
				lastApiRefNum={lastApiRefNum}
				displayRowDetails={true}
				showPrintDropdown={false}
				defaultColumns={defaultColumns}
				ref={this.gridRef}
				initialFetch={true}
				columnFilterType="/settings/user-settings/transaction-history"
				kvaasResourceType="transaction"
				useInlineFilters={true}
				showGridHeader={true}
				displayHeaderMenu={false}
				displayShowHideSelectedFilters={false}
				showResults={!this.state.fetchingData}
				showPanel={false}
				showGridFooter={true}
				showGridFooterLoadAllResults={true}
				enableFilters={true}
				enablePrint={true}
				enableExport={true}
				enableViewInTransactionReport={true}
				renderGridHeaderViewInTransactionReport={this.renderViewInTransactionReport}
				renderGridFooterLoadAllResults={this.renderViewAllPayments}
				tooltipProps={tooltipProps}
				fetchExportData={{ current: exportService.mapTransactionData, all: exportService.getTransactionData }}
				popupRef={popupRef}
			/>
		);
	}
}

ViewPaymentsGrid.propTypes = {
	makePendingRequest: PropTypes.func.isRequired,
	handleError: PropTypes.func.isRequired,
	popupRef: PropTypes.any,
	scheduleId: PropTypes.string.isRequired,
	scheduleName: PropTypes.string,
	history: PropTypes.object.isRequired,
};

export default ViewPaymentsGrid;
export const WrappedViewPaymentsGrid = withError(withCancelable(withRouter(ViewPaymentsGrid)));
