import React from 'react';
import PropTypes from 'prop-types';

const logo = () => (
	<svg width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.09324 12.892C2.7261 12.892 -0.0032959 10.1626 -0.0032959 6.79551C-0.0032959 3.42837 2.7261 0.698975 6.09324 0.698975C9.46038 0.698975 12.1898 3.42837 12.1898 6.79551C12.1898 10.1626 9.46038 12.892 6.09324 12.892ZM10.1774 6.79551C10.1774 4.53864 8.35012 2.71133 6.09324 2.71133C3.83637 2.71133 2.00906 4.53864 2.00906 6.79551C2.00906 9.05239 3.83637 10.8797 6.09324 10.8797C8.35012 10.8797 10.1774 9.05239 10.1774 6.79551Z"
			fill="#231F20"
		/>
		<path
			d="M1.79103 20.9481C1.85381 20.925 1.90999 20.8919 1.95955 20.8424C2.00581 20.7961 2.04216 20.7366 2.06529 20.6739C2.08842 20.6111 2.08512 20.5417 2.08512 20.4756V16.9267H11.6281C11.6942 16.9267 11.7636 16.9267 11.8264 16.9036C11.8891 16.8805 11.9486 16.8441 11.9949 16.7979C12.0411 16.7516 12.0775 16.6921 12.1006 16.6293C12.1237 16.5665 12.1304 16.4972 12.1237 16.4311V15.41C12.1304 15.3439 12.1237 15.2745 12.1006 15.2118C12.0775 15.149 12.0411 15.0895 11.9949 15.0432C11.9486 14.997 11.8891 14.9606 11.8264 14.9375C11.7636 14.9144 11.681 14.9144 11.6281 14.9144H0.568417C0.495721 14.9144 0.432938 14.9144 0.370155 14.9375C0.307372 14.9606 0.247894 14.997 0.201633 15.0432C0.155372 15.0895 0.119025 15.149 0.0958946 15.2118C0.0727641 15.2745 0.0727632 15.3406 0.0727632 15.41V20.4723C0.0727632 20.5351 0.0727641 20.6045 0.0958946 20.6706C0.119025 20.7333 0.155372 20.7928 0.201633 20.8391C0.247894 20.8853 0.307372 20.9217 0.370155 20.9448C0.432938 20.9679 0.499026 20.9679 0.568417 20.9679H1.59607C1.66546 20.9679 1.72825 20.9679 1.79433 20.9448"
			fill="#231F20"
		/>
	</svg>
);

logo.propTypes = {
	width: PropTypes.any,
};

export default logo;
