import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { Modal } from 'common/components/modal';
import propType from 'common/fields/prop-type';
import { StatusComponent } from 'common/components/columns/formatters';

class FullScheduleInfo extends Component {
	renderStringField = field => {
		const { schedule } = this.props;
		const fieldVal = get(schedule, `${field}`);
		if (!fieldVal) {
			return null;
		}
		return (
			<div className="f-row spc--bottom--sml separator--primary--bottom">
				<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--sml">
					<label className="type--p2 type--color--text--secondary">{_.startCase(_.camelCase(field))}:</label>
				</div>
				<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--sml">
					<p className="type--p2 type--p2--medium">{fieldVal}</p>
				</div>
			</div>
		);
	};
	renderBoolField = field => {
		const { schedule } = this.props;
		const fieldVal = get(schedule, `${field}`);
		return (
			<div className="f-row spc--bottom--sml separator--primary--bottom">
				<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--sml">
					<label className="type--p2 type--color--text--secondary">{_.startCase(_.camelCase(field))}:</label>
				</div>
				<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--sml">
					<p className="type--p2 type--p2--medium">{fieldVal ? 'True' : 'False'}</p>
				</div>
			</div>
		);
	};
	renderIntField = field => {
		const { schedule } = this.props;
		const fieldVal = get(schedule, `${field}`);
		return (
			<div className="f-row spc--bottom--sml separator--primary--bottom">
				<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--sml">
					<label className="type--p2 type--color--text--secondary">{_.startCase(_.camelCase(field))}:</label>
				</div>
				<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--sml">
					<p className="type--p2 type--p2--medium">{fieldVal || 0}</p>
				</div>
			</div>
		);
	};
	renderTitle = (id, refnum) => {
		return (
			<div className="modal__header">
				<h4 className="type--wgt--medium spc--bottom--tny">Full Schedule Information {refnum}</h4>
				<p className="type--sml type--color--text--light type--break-word">Schedule ID: {id}</p>
			</div>
		);
	};

	render() {
		const { isOpen, onClose, className, schedule } = this.props;

		return (
			<Modal isOpen={isOpen} onClose={onClose} className={className}>
				{isOpen ? (
					<div className={isOpen ? '' : 'display--n'}>
						{this.renderTitle(schedule.scheduleId, schedule.refNum)}
						<div className="modal__body">
							{this.renderBoolField('isActive')}
							{schedule && schedule.lastTransactionStatus && schedule.lastTransactionStatus.trim() !== '' ? (
								<div className="f-row spc--bottom--sml separator--primary--bottom">
									<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--sml">
										<span className="type--p2 type--color--text--secondary">Last Transaction Status:</span>
									</div>
									<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--sml">
										<StatusComponent value={schedule.lastTransactionStatus} />
									</div>
								</div>
							) : null}
							{this.renderStringField('customerId')}
							{this.renderStringField('scheduleName')}
							<div className="f-row spc--bottom--sml separator--primary--bottom">
								<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--sml">
									<span className="type--p2 type--color--text--secondary">Amount:</span>
								</div>
								<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--sml">
									<p className="type--p2 type--p2--medium">
										<NumberFormat
											value={schedule.amount}
											displayType="text"
											thousandSeparator={true}
											decimalScale={2}
											fixedDecimalScale={true}
										/>
									</p>
								</div>
							</div>
							{this.renderStringField('startDate')}
							{this.renderStringField('intervalType')}
							{this.renderIntField('intervalCount')}
							<div className="f-row spc--bottom--sml separator--primary--bottom">
								<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--sml">
									<span className="type--p2 type--color--text--secondary">Total Payments:</span>
								</div>
								<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--sml">
									<div className="type--p2 type--p2--medium">
										{schedule.totalPayments ? schedule.totalPayments : '*'}
									</div>
								</div>
							</div>
							{this.renderStringField('endDate')}
							{this.renderBoolField('skipSaturdayAndHolidays')}
							{this.renderBoolField('useDefaultCardOnly')}
							{this.renderBoolField('custReceipt')}
							{this.renderStringField('createdDate')}
							{this.renderStringField('creationRefnum')}
							{this.renderStringField('modifiedDate')}
							{this.renderStringField('modifiedRefnum')}
							{this.renderStringField('xGatewayRefnum')}
							{this.renderIntField('paymentsProcessed')}
							{this.renderIntField('failedAttemptsInCurrentInterval')}
							{this.renderIntField('paymentsMissed')}
							{this.renderBoolField('allowInitialTransactionToDecline')}
							{this.renderStringField('afterMaxRetriesAction')}
							{this.renderIntField('daysBetweenRetries')}
							{this.renderIntField('failedTransactionRetryTimes')}
							{this.renderBoolField('isDeleted')}
							{this.renderStringField('nextScheduledRunTime')}
							{this.renderStringField('initialRunTime')}
							{this.renderStringField('lastRunTime')}
							{this.renderStringField('lastTransactionError')}
							{this.renderStringField('lastProjectedPaymentDate')}
							{this.renderStringField('calendarCulture')}
							{this.renderStringField('currency')}
							{this.renderStringField('description')}
							{this.renderStringField('invoice')}
							{this.renderStringField('email')}
							{this.renderStringField('customerNumber')}
							{this.renderStringField('billFirstName')}
							{this.renderStringField('billMiddleName')}
							{this.renderStringField('billLastName')}
							{this.renderStringField('billCompany')}
							{this.renderStringField('custom02')}
							{this.renderStringField('custom03')}
							{this.renderStringField('custom04')}
							{this.renderStringField('custom05')}
							{this.renderStringField('custom06')}
							{this.renderStringField('custom07')}
							{this.renderStringField('custom08')}
							{this.renderStringField('custom09')}
							{this.renderStringField('custom10')}
							{this.renderStringField('custom11')}
							{this.renderStringField('custom12')}
							{this.renderStringField('custom13')}
							{this.renderStringField('custom14')}
							{this.renderStringField('custom15')}
							{this.renderStringField('custom16')}
							{this.renderStringField('custom17')}
							{this.renderStringField('custom18')}
							{this.renderStringField('custom19')}
							{this.renderStringField('custom20')}
							<div className="f-row spc--bottom--sml separator--primary--bottom">
								<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--sml">
									<span className="type--p2 type--color--text--secondary">Revision:</span>
								</div>
								<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--sml">
									<p2 className="type--p2 type--p2--medium">{schedule.revision ? schedule.revision : 0}</p2>
								</div>
							</div>
						</div>
					</div>
				) : (
					''
				)}
			</Modal>
		);
	}
}

FullScheduleInfo.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	className: PropTypes.string,
	handleError: PropTypes.func,
	schedule: propType.Object,
};

export default withCancelable(withError(FullScheduleInfo));
