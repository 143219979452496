import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

const ModalComponent = ({
	children,
	onClose,
	hideCloseButton,
	isLoading,
	displayProgress,
	hideHeaderCloseButton,
	closeButtonLabel,
	...props
}) => (
	<ReactModal onRequestClose={onClose} {...props}>
		<div className="modal__content__wrapper">
			{!hideHeaderCloseButton && !isLoading && (
				<button className="btn btn--action btn--action--secondary modal__close--header" onClick={onClose}>
					<i className="icon icon--sml icon--close--text"></i>
				</button>
			)}
			{children}
			{!isLoading && hideHeaderCloseButton && (
				<button tabIndex="-1" className="btn btn--med btn--tertiary modal__close" onClick={onClose}>
					{closeButtonLabel}
				</button>
			)}
		</div>
		{isLoading && displayProgress && <div className="loader loader--progress"></div>}
	</ReactModal>
);

ModalComponent.defaultProps = {
	displayProgress: false,
	shouldCloseOnOverlayClick: true,
	overlayClassName: 'modal__overlay',
	className: 'modal__content',
	closeButtonLabel: 'Close',
};

ModalComponent.propTypes = {
	children: PropTypes.element.isRequired,
	onClose: PropTypes.func.isRequired,
	isOpen: PropTypes.bool,
	isLoading: PropTypes.bool,
	isProcessing: PropTypes.bool,
	overlayClassName: PropTypes.string,
	className: PropTypes.string,
	shouldCloseOnOverlayClick: PropTypes.bool,
	hideCloseButton: PropTypes.bool,
	displayProgress: PropTypes.bool,
	hideHeaderCloseButton: PropTypes.bool,
	closeButtonLabel: PropTypes.string,
};

export default ModalComponent;
