import React, { Component, createRef } from 'react';
import { isPlainObject } from 'lodash';
import PropTypes from 'prop-types';

import { modalNames } from './modal-names';
import { Modal } from '../modal';
import TransactionVoid from './void';
import TransactionCapture from './capture';
import TransactionRefund from './refund';
import TransactionReceipt from './receipt';
import TransactionAdjust from './adjust';
import ConfirmAction from './confirmAction';
import { NewTransaction } from '../../../components/new-transaction';
import { WrappedBulkChargeCustomersPopup as BulkChargeCustomersPopup } from '../../../components/customer-grid/bulk-charge/BulkChargeCustomersPopup';
import ReceiptNotification from '../../../Common/components/notifications/receipt-notification';
import { LogoPreviewPopup } from 'components/logo-preview';
import { WhitelistIPsPopup } from '../whitelist-IPs-popup';
import SendTestEmailPopup from '../send-test-email-popup/SendEmailPopup';
import SendSmsPopup from '../send-sms-popup/SendSmsPopup';
import { TotalChargesPopup } from '../total-charges-popup';
import {
	RegisterApplePayCardknoxCertificatePopup,
	RegisterApplePayOwnCertificatePopup,
} from '../apple-pay-registration';
import { ClickToPayRegistrationPopup } from '../click-to-pay-registration';

const modalComponents = {
	[modalNames.capture]: {
		component: TransactionCapture,
		props: {
			showHeaderCloseButton: true,
		},
	},
	[modalNames.newTransaction]: {
		component: NewTransaction,
		props: {
			shouldCloseOnOverlayClick: false,
			showHeaderCloseButton: true,
		},
	},
	[modalNames.print]: {
		component: TransactionReceipt,
		props: {},
	},
	[modalNames.refund]: {
		component: TransactionRefund,
		props: {},
	},
	[modalNames.void]: {
		component: TransactionVoid,
		props: {
			hideHeaderCloseButton: true,
		},
	},
	[modalNames.confirmAction]: {
		component: ConfirmAction,
		props: {},
	},
	[modalNames.adjust]: {
		component: TransactionAdjust,
		props: {
			showHeaderCloseButton: true,
		},
	},
	[modalNames.previewLogo]: {
		component: LogoPreviewPopup,
		props: {},
	},
	[modalNames.whitelistIPs]: {
		component: WhitelistIPsPopup,
		props: {
			shouldCloseOnOverlayClick: false,
		},
	},
	[modalNames.bulkCharge]: {
		component: BulkChargeCustomersPopup,
		props: {
			shouldCloseOnOverlayClick: false,
			showHeaderCloseButton: true,
		},
	},
	[modalNames.sendEmail]: {
		component: SendTestEmailPopup,
		props: {
			shouldCloseOnOverlayClick: false,
			showHeaderCloseButton: true,
		},
	},
	[modalNames.sendSms]: {
		component: SendSmsPopup,
		props: {
			shouldCloseOnOverlayClick: false,
		},
	},
	[modalNames.totalCharges]: {
		component: TotalChargesPopup,
		props: {
			shouldCloseOnOverlayClick: false,
			className: 'modal__content modal--xxlrg modal--view-payments',
		},
	},
	[modalNames.registerApplePay]: {
		component: RegisterApplePayCardknoxCertificatePopup,
		props: {
			shouldCloseOnOverlayClick: false,
		},
	},
	[modalNames.generateApplePay]: {
		component: RegisterApplePayOwnCertificatePopup,
		props: {
			shouldCloseOnOverlayClick: false,
			isLoading: false,
		},
	},
	[modalNames.configureClickToPay]: {
		component: ClickToPayRegistrationPopup,
		props: {
			shouldCloseOnOverlayClick: false,
		},
	},
};

class ActionsModalWrapperComponent extends Component {
	constructor(props) {
		super(props);
		this.componentRef = createRef();

		this.state = {
			isHiddenModal: false,
			isLoading: false,
		};
	}

	setLoading = isLoading => this.setState({ isLoading });

	onModalClose = () => {
		if (this.componentRef.current && this.componentRef.current.onModalClose) {
			this.componentRef.current.onModalClose();
		}
		this.props.onModalClose({
			name: modalNames.none,
			data: null,
		});
		this.setState({ isHiddenModal: false });
	};

	hideModal = () => this.setState({ isHiddenModal: true });

	unhideModal = () => this.setState({ isHiddenModal: false });

	renderSpecificModal = () => {
		const { isHiddenModal, isLoading } = this.state;
		const { name, data } = this.props.modal;

		const modal = modalComponents[name] || (isPlainObject(name) && name);

		if (!modal) {
			return null;
		}

		if (data.modalClassName) {
			modal.props.className = data.modalClassName;
		} else {
			delete modal.props.className;
		}
		const overlayClassName = this.props.overlayClassName || 'modal__overlay modal__overlay--flex';
		return (
			<Modal
				isLoading={isLoading || this.props.isLoading || data.isLoading}
				isOpen={true}
				onClose={this.onModalClose}
				overlayClassName={isHiddenModal ? '' : overlayClassName}
				className={`modal__content${isHiddenModal ? ' display--n' : ''}`}
				{...modal.props}
				{...this.props}
				hideHeaderCloseButton={modal.props.showHeaderCloseButton ? false : true}
			>
				<modal.component
					ref={this.componentRef}
					closeModal={this.onModalClose}
					notificationRef={this.notification}
					hideModal={this.hideModal}
					unhideModal={this.unhideModal}
					setLoading={this.setLoading}
					isLoading={isLoading}
					{...data}
				/>
			</Modal>
		);
	};

	render = () => {
		return (
			<div>
				{this.props.modal && this.renderSpecificModal()}
				<ReceiptNotification ref={el => (this.notification = el)} />
			</div>
		);
	};
}

ActionsModalWrapperComponent.propTypes = {
	modal: PropTypes.shape({
		name: PropTypes.any,
		data: PropTypes.object,
	}),
	onModalClose: PropTypes.func.isRequired,
	overlayClassName: PropTypes.string,
	isLoading: PropTypes.bool,
};

export default ActionsModalWrapperComponent;
