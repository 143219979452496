import { overEvery, some, every, split, trim, toLower } from 'lodash';
import moment from 'moment';

import { emailValidator } from '../../Common/components/email-validator';

const { cardExpiryDateFormat, displayDateFormat } = ApplicationSettings;

const phoneValidationRegex = /^\s*((\d+\s*|\+\d+\s*|\(\s*\d+\s*\)\s*)\s*(-|\s*))\s*(\s*\d+(\s*-|\s*)\s*)+\d+\s*$/;
const mobilePhoneValidationRegex = /^(\+\d{1,3}[- ]?)?\(?\d{1,4}\)?[- ]?\d{1,4}[- ]?\d{1,9}$/;

const required = value => !!value;
const validateIf = (validator, predicate) => value => (predicate(value) ? validator(value) : true);
const match = fieldGetter => value => value === fieldGetter().value;
const pattern = pattern => value => pattern.test(value);
const email = value => {
	const emails = split(value, ',');
	return every(emails, email => emailValidator.test(trim(email)));
};
const expDateUploadTool = (value, form, fieldKey) => {
	const date = moment(value, cardExpiryDateFormat, true);
	const index = fieldKey.substring(fieldKey.length - 1);
	const tokenKey = `token${index}`;
	if (form[tokenKey] && form[tokenKey].value) return true;
	return (
		date.isValid() &&
		!moment()
			.startOf('month')
			.isAfter(date)
	);
};
const expDate = value => {
	const date = moment(value, cardExpiryDateFormat, true);
	return (
		date.isValid() &&
		!moment()
			.startOf('month')
			.isAfter(date)
	);
};
const phoneNumber = value => {
	return phoneValidationRegex.test(value);
};
const mobilePhone = value => {
	return mobilePhoneValidationRegex.test(value);
};
const assert = valueToMatch => value => value === valueToMatch;
const currency = value => {
	const floatAmount = !isNaN(parseFloat(value)) ? parseFloat(value).toFixed(2) : 0;
	if (value == '' || floatAmount <= 0) {
		return false;
	}
	return true;
};
const paymentType = value => {
	const result = toLower(value);
	return result === 'cc' || result === 'check';
};
const paymentTypeOptions = (value, form, fieldKey) => {
	const paymentFields = [
		'paymentType',
		'cardNumber',
		'token',
		'expDate',
		'paymentName',
		'cardholderName',
		'accountName',
		'routingNumber',
		'accountNumber',
	];
	const index = fieldKey.substring(fieldKey.length - 1);

	const hasPaymentOptionFilled = some(paymentFields, field => {
		const fieldValue = form[`${field}${index}`];
		return fieldValue && fieldValue.value;
	});

	if (hasPaymentOptionFilled) {
		return false;
	} else {
		return true;
	}
};
const cardNumber = (value, form, fieldKey) => {
	const index = fieldKey.substring(fieldKey.length - 1);
	const tokenKey = `token${index}`;
	if (form[tokenKey] && form[tokenKey].value) return !value;
	return /^\d{13,16}$/.test(value);
};
const token = (value, form, fieldKey) => {
	const index = fieldKey.substring(fieldKey.length - 1);
	const cardNumberKey = `cardNumber${index}`;
	if (form[cardNumberKey].value) return !value;
	return !!value;
};
const number = pattern(/^(?!$)\d*(\.\d+)?$/);
const routing = value => {
	if (!value || !/^\d{9}$/.test(value)) {
		return false;
	}

	const weights = [3, 7, 1, 3, 7, 1, 3, 7, 1];
	const digits = value.split('').map(Number);

	const sum = digits.reduce((acc, digit, index) => {
		return acc + digit * weights[index];
	}, 0);

	return sum % 10 === 0;
};
const accNumber = value => {
	if (!value || !/^\d{4,17}$/.test(value)) {
		return false;
	}

	if (/^0+$/.test(value)) {
		return false;
	}

	if (/^(?:0123456789|1234567890|9876543210|0987654321)/.test(value)) {
		return false;
	}

	return true;
};
const date = value => moment(value, displayDateFormat, true).isValid();
const startDate = value => {
	if (date(value)) {
		const momentValue = moment(value, displayDateFormat, true);
		const today = moment().format(displayDateFormat);
		return momentValue.isAfter(today) || momentValue.isSame(today);
	}

	return false;
};
const frequency = value => number(value) && value.length < 3;
const intervalType = value => some(['day', 'week', 'month', 'year'], item => item === toLower(value));
const untilType = value => {
	if (!value || date(value) || number(value)) {
		return true;
	}
	return false;
};
const remainingPayments = value => {
	if (!value || number(value)) {
		return true;
	}
	return false;
};
const any = (...fieldGetters) => validator => () => some(fieldGetters, fieldGetter => validator(fieldGetter().value));

export const isValid = field => (field && field.dirty ? overEvery(field.validators)(field.value) : true);

export default {
	required,
	if: validateIf,
	match,
	pattern,
	email,
	assert,
	currency,
	any,
	expDate,
	expDateUploadTool,
	phoneNumber,
	paymentType,
	cardNumber,
	token,
	number,
	routing,
	accNumber,
	date,
	frequency,
	intervalType,
	untilType,
	startDate,
	remainingPayments,
	paymentTypeOptions,
	mobilePhone,
};
