import React, { Component } from 'react';
import PropTypes from 'prop-types';
class DownloadButtonCell extends Component {
	render() {
		return (
			<div className="type--right">
				<button className="btn btn--sml btn--link" onClick={this.props.handleDownload}>
					<i className="icon icon--sml icon--download"></i>
				</button>
			</div>
		);
	}
}
DownloadButtonCell.propTypes = {
	handleDownload: PropTypes.func.isRequired,
};
export default DownloadButtonCell;
