import React, { Component, Fragment, createRef } from 'react';
import {
	map,
	flatMap,
	slice,
	times,
	each,
	isEmpty,
	transform,
	some,
	every,
	findIndex,
	compact,
	cloneDeep,
	get,
	noop,
	startsWith,
	toLower,
	split,
	trim,
	filter,
	overEvery,
	isString,
	replace,
	chunk,
	groupBy,
	concat,
	range,
	size,
	set,
	findKey,
	reject,
	includes,
	forEach,
	sumBy,
	pullAt,
	head,
	unset,
	pull,
	uniqBy,
	find,
	keys,
} from 'lodash';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import { array, bool, func } from 'prop-types';
import moment from 'moment';

import { withCancelable } from '../../Common/components/cancelable';
import { withError } from '../../Common/components/error';
import { withLoader } from '../../Common/components/loader';
import { ExportComponent } from '../../Common/components/export';
import { parseCsv } from '../../Common/utilities';
import { validators, createField } from '../../Common/fields';
import { Notification } from '../../Common/components/notifications';
import { UserAccountPanel } from '../../Common/components/user-account-panel';
import { exportService } from 'common/components/export/exportService';
import { columns } from './uploadCustomerPreviewColumns';
import { customerService, ifieldService } from 'common/services';
import { calendarTypes, today, until } from 'common/components/customers/popup/constants';
import { ActionsModal, modalNames } from 'common/components/transaction-actions';
import SuccessModal from './components/SuccessModal';
import UploadModal from './components/UploadModal';
import { withBlock } from 'common/components/block';
import Requirements from './components/Requirements';
import { Link } from 'react-router-dom';

const { apiDateFormat, displayDateFormat, recurringApiRateLimit } = ApplicationSettings;

const fieldPrefixesToIgnoreWhenToken = ['token', 'accountName', 'paymentType'];
const customerRequiredFields = ['billingFirstName', 'billingLastName', 'billingCompany', 'customerNumber'];
const baseScheduleFields = [
	'scheduleName',
	'description',
	'frequency',
	'intervalType',
	'amount',
	'startDate',
	'remainingPayments',
	'until',
];

const ccFields = ['paymentType', 'cardNumber', 'expDate', 'paymentName', 'cardholderName'];
const checkFields = ['paymentType', 'accountName', 'routingNumber', 'accountNumber', 'paymentName'];
const requestKeys = {
	SAVE: 'save',
};

class UploadCustomerPreview extends Component {
	constructor(props) {
		super(props);
		this.notificationRef = createRef();
		this.account = createRef();
		this.customerRowRefs = {};
		this.state = this.initialState;
		this.failedUploadData = [];
	}

	get initialState() {
		return {
			isInitialErrorPaginate: true,
			currentLineNumber: 0,
			headers: columns,
			data: [],
			paginatedData: [],
			errorMessages: [],
			uniqueErrorMessages: [],
			numberOfPages: 0,
			pageSize: 15,
			page: 1,
			fileName: '',
			progress: {
				isUploading: false,
				total: 0,
				completed: 0,
				errored: 0,
			},
			mappedErrors: [],
			failedResponses: [],
			customersToRemove: [],
			documentUploaded: false,
		};
	}

	splitAt(array, index) {
		return [array.slice(0, index), array.slice(index)];
	}

	processData = async () => {
		const { handleError, showLoader, makePendingRequest } = this.props;
		const pauseIdleTimer = get(this.props, 'idleTimerRef.current.pause', noop);
		const resumeIdleTimer = get(this.props, 'idleTimerRef.current.resume', noop);

		try {
			pauseIdleTimer();
			showLoader(true);
			let customers = await this.mapCustomersForProcessing();
			this.setState({
				progress: {
					isUploading: true,
					total: customers.length,
					completed: 0,
					errored: 0,
				},
			});
			let payments = map(customers, customer => this.createPaymentMethods(customer));
			let schedules = map(customers, customer => this.createSchedules(customer));

			const numberOfRequestsAvailableInTimePeriod = recurringApiRateLimit;
			const timePeriod = 1000 * 60 * 5; // 5 minutes
			const timePerRequest = timePeriod / numberOfRequestsAvailableInTimePeriod;
			let timeBudget = 0;

			const responses = [];
			let customerChunk, paymentChunk, scheduleChunk;
			while (customers.length > 0) {
				if (timeBudget < 0) {
					await new Promise(resolve => setTimeout(resolve, -timeBudget));
					timeBudget = 0;
				}
				let chunkSize = 1;
				if (timeBudget > timePerRequest) {
					chunkSize = Math.floor(timeBudget / timePerRequest);
				}
				const numberOfRemainingRequests = customers.length + sumBy(payments, 'length') + sumBy(schedules, 'length');
				if (timeBudget > 0 && numberOfRemainingRequests < numberOfRequestsAvailableInTimePeriod) {
					chunkSize = Math.min(customers.length, 50); // Have to make sure we don't experience port exhaustion or something
				}
				[customerChunk, customers] = this.splitAt(customers, chunkSize);
				[paymentChunk, payments] = this.splitAt(payments, chunkSize);
				[scheduleChunk, schedules] = this.splitAt(schedules, chunkSize);
				const start = Date.now();
				const chunkResponses = await makePendingRequest(
					Promise.all(
						map(customerChunk, async (customer, i) => {
							return await this.saveCustomer(customer, paymentChunk[i], scheduleChunk[i]);
						})
					),
					requestKeys.SAVE
				);
				responses.push(...chunkResponses);
				timeBudget -= sumBy(chunkResponses, ({ numberOfRequests }) => numberOfRequests * timePerRequest);
				const elapsed = Date.now() - start;
				timeBudget += elapsed;
			}

			this.handleResponses(responses);
			await this.processRemovals();
			if (isEmpty(this.failedUploadData)) {
				this.props.handleBlockChange(false);
			}
		} catch (e) {
			handleError(e);
		} finally {
			resumeIdleTimer();
			this.setState({
				progress: {
					...this.state.progress,
					isUploading: false,
				},
			});
			showLoader();
		}
	};

	constructErrorMessage = (item, errorHeaderMessage) => {
		if (!item) return null;
		const { message: error, refNum, ref, customerId } = item;
		const lowercasedError = error && toLower(error);

		if (!lowercasedError && !get(item, 'ex.message', false)) return null;

		let errorMessage = lowercasedError || get(item, 'ex.message', '');

		if (startsWith(errorMessage, 'invalid token')) {
			errorMessage = 'Invalid Card or Check data';
		} else if (lowercasedError === 'expired card') {
			errorMessage = 'Payment Method Expired';
		} else {
			errorMessage += ' please try again later.';
		}

		const trueRef = refNum || ref;
		const refNumPart = trueRef ? <div className="type--color--text--regular">({trueRef})</div> : '';

		// Add customerId to the error message if it exists
		let errorMessageElement;
		if (customerId) {
			errorMessageElement = (
				<p>
					{errorHeaderMessage}: {errorMessage} (Customer ID:{' '}
					<a
						className="btn btn--link align--v--baseline"
						href={`/customers/report?customerId=${customerId}&expandedRow=${customerId}`}
						target="_blank"
					>
						{customerId}
					</a>{' '}
					)
				</p>
			);
		} else {
			errorMessageElement = (
				<div>
					{errorHeaderMessage}: {errorMessage}
				</div>
			);
		}

		return (
			<div>
				{errorMessageElement}
				{refNumPart}
			</div>
		);
	};
	checkForErrors(result, response) {
		return toLower(result) !== 's' || response.paymentError || response.scheduleError;
	}

	mergeCustomers = customers => {
		const mergedCustomers = {};
		const paymentFields = [
			'paymentType',
			'cardNumber',
			'expDate',
			'paymentName',
			'cardholderName',
			'accountName',
			'routingNumber',
			'accountNumber',
		];
		for (let i = 0; i < customers.length; i++) {
			const customer = customers[i];
			if (customer.customerNumber) {
				if (!mergedCustomers[customer.customerNumber]) {
					mergedCustomers[customer.customerNumber] = { ...customer };
				} else {
					const indices = {
						cc: 1,
						check: 1,
					};
					for (const key in customer) {
						if (customer[key]) {
							let baseKey = key.replace(/\d+$/, '');
							let paymentType = customer['paymentType' + key.match(/\d+$/)];
							if (paymentFields.includes(baseKey)) {
								paymentType = paymentType.toLowerCase();
								while (mergedCustomers[customer.customerNumber][baseKey + indices[paymentType]]) {
									indices[paymentType]++;
								}
								mergedCustomers[customer.customerNumber][baseKey + indices[paymentType]] = customer[key];
							} else if (!mergedCustomers[customer.customerNumber][key]) {
								mergedCustomers[customer.customerNumber][key] = customer[key];
							}
						}
					}
				}
			} else {
				mergedCustomers[`${Date.now()}-${i}`] = { ...customer };
			}
		}

		return Object.values(mergedCustomers);
	};
	handleResponses = responses => {
		const failedResponses = filter(
			responses,
			res => res.message || res.paymentError || res.scheduleError || get(res, 'ex.message')
		);
		if (isEmpty(failedResponses)) {
			this.setState({ isOpenSuccessModal: true });
			return;
		}
		const mappedErrors = map(failedResponses, item => {
			return (
				<React.Fragment key={item.lineNumber}>
					<tr className="error-row">
						<td>
							<div className="flex flex--primary flex--nowrap">
								<i className="icon icon--sml icon--warning-circle spc--right--xsml"></i>
								<span className="type--wgt--bold">{item.lineNumber}</span>
							</div>
						</td>
						<td>
							<div className="error-list">
								{this.constructErrorMessage(item, 'Customer Error')}
								{this.constructErrorMessage(item.paymentError, 'Payment Method Error')}
								{this.constructErrorMessage(item.scheduleError, 'Schedule Error')}
							</div>
						</td>
					</tr>
				</React.Fragment>
			);
		});
		this.setState({ mappedErrors, failedResponses });
	};
	getListOfPaymentMethods = (mappedData, prefix) => {
		return compact(
			flatMap(mappedData, customer => {
				const numberKeys = filter(keys(customer), key => key.startsWith(prefix));
				return map(numberKeys, key => customer[key]);
			})
		);
	};
	mapCustomersForProcessing = async () => {
		const data = cloneDeep(this.state.data);
		let mappedData = map(data, (customer, i) =>
			transform(customer, (acc, { value }, key) => {
				acc[key] = trim(value);
				acc.index = i;
			})
		);
		mappedData = this.mergeCustomers(mappedData);
		const listOfCardNumbers = this.getListOfPaymentMethods(mappedData, 'cardNumber');
		const listOfAccountNumbers = this.getListOfPaymentMethods(mappedData, 'accountNumber');
		if (isEmpty(listOfCardNumbers) && isEmpty(listOfAccountNumbers)) {
			return Promise.resolve(mappedData);
		}

		let cardNumberTokens = [];
		let accountNumberTokens = [];

		if (!isEmpty(listOfCardNumbers)) {
			const cardNumbersResponse = await ifieldService.transformCardNumbers(listOfCardNumbers, 'card');
			cardNumberTokens = cardNumbersResponse.Data;
		}

		if (!isEmpty(listOfAccountNumbers)) {
			const accountNumberResponse = await ifieldService.transformCardNumbers(listOfAccountNumbers, 'ach');
			accountNumberTokens = accountNumberResponse.Data;
		}

		let cardIndex = 0; // Initialize card index
		let accountIndex = 0; // Initialize account index

		each(mappedData, customer => {
			const cardNumberKeys = filter(keys(customer), key => key.startsWith('cardNumber'));
			forEach(cardNumberKeys, key => {
				if (!customer[key]) return;
				customer[`original${key}`] = customer[key];
				customer[key] = cardNumberTokens[cardIndex];
				cardIndex++;
			});

			const accountNumberKeys = filter(keys(customer), key => key.startsWith('accountNumber'));
			forEach(accountNumberKeys, key => {
				if (!customer[key]) return;
				customer[`original${key}`] = customer[key];
				customer[key] = accountNumberTokens[accountIndex];
				accountIndex++;
			});
		});

		return mappedData;
	};

	handleSchedule = async (customer, customerData, schedules, payments, response) => {
		const firstSchedule = head(schedules);
		pull(schedules, firstSchedule);
		const firstPaymentMethod = pullAt(payments, 0);
		set(firstSchedule, 'newPaymentMethod', head(firstPaymentMethod).payment);
		set(firstSchedule, 'newPaymentMethod.name', get(firstSchedule, 'newPaymentMethod.xName'));
		unset(firstSchedule.newPaymentMethod, 'xName');
		set(firstSchedule, 'newCustomer', customerData);

		let result,
			customerId,
			refNum,
			hasSchedule = true;

		try {
			const customResponse = await this.saveSchedules([firstSchedule], null, response);
			response.refNum = customResponse.refNum;

			if (customResponse.success) {
				result = 's';
				customerId = customResponse.customerId;
				refNum = response.refNum;
			} else {
				if (customResponse.message !== 'Expired Card') {
					payments.unshift(head(firstPaymentMethod));
				}
				hasSchedule = false;
				response.scheduleError = customResponse;
			}
		} catch (err) {
			this.updateProgress(true);
		}

		return { hasSchedule, result, customerId, refNum };
	};
	saveCustomer = async (customer, payments, schedules) => {
		let response = {
			numberOfRequests: 0,
		};
		let customerId = '';
		const { index } = customer;
		const customerData = this.createCustomer(customer);
		let hasSchedule = !isEmpty(schedules);
		let xReportData, result, refNum;

		let addCustomerResponse = null;
		if (hasSchedule) {
			const scheduleResponse = await this.handleSchedule(customer, customerData, schedules, payments, response);
			hasSchedule = scheduleResponse.hasSchedule;
			result = scheduleResponse.result;
			customerId = scheduleResponse.customerId;
			refNum = scheduleResponse.refNum;
		}

		try {
			response.numberOfRequests++;
			if (!hasSchedule) {
				addCustomerResponse = await customerService.addCustomer(customerData);
				xReportData = addCustomerResponse.xReportData;
				result = addCustomerResponse.result;
				refNum = addCustomerResponse.refNum;
			}

			if (toLower(result) === 's') {
				if (!customerId) {
					customerId = get(head(xReportData), 'customerId');
				}
				if (!response.scheduleError) {
					response.scheduleError = await this.saveSchedules(schedules, customerId, response);
				}
				response.paymentError = await this.savePaymentMethods(payments, customerId, response);
				response.refNum = refNum;
			}

			const hasErrors = this.checkForErrors(result, response);
			if (hasErrors) {
				if (response.paymentError) {
					response.paymentError = {
						message: response.paymentError.message,
						customerId: customerId,
					};
				}
				if (response.scheduleError) {
					response.scheduleError = {
						message: response.scheduleError.message,
						customerId: customerId,
					};
				}
			}
			if (!hasErrors) {
				await this.removeCustomer(index);
			}

			this.updateProgress(hasErrors);
		} catch (e) {
			response = e;
			this.updateProgress(true);
		}
		response.lineNumber = index + 1;
		return response;
	};

	updateProgress = hasErrors => {
		this.setState(({ progress }) => ({
			progress: {
				...progress,
				completed: hasErrors ? progress.completed : progress.completed + 1,
				errored: hasErrors ? progress.errored + 1 : progress.errored,
			},
		}));
	};

	saveSchedules = async (schedules, customerId, response) => {
		try {
			for (const schedule of schedules) {
				if (!schedule) continue;
				response.numberOfRequests++;
				if (!schedule.newCustomer) {
					schedule.customerId = customerId;
				}
				const scheduleResponse = await customerService.addCustomerRecurringSchedule(schedule);
				if (schedule.newCustomer) {
					return scheduleResponse;
				}
			}
		} catch (e) {
			return e;
		}
	};

	createSchedules = customer => {
		const maxIndex = Math.max(
			...Object.keys(customer)
				.filter(key => key.startsWith('amount'))
				.map(key => Number(key.replace('amount', '')))
		);

		return compact(
			times(maxIndex, i => {
				const index = i + 1;
				const untilValue = customer[`until${index}`];
				const startDate = moment(customer[`startDate${index}`], displayDateFormat, true).format(apiDateFormat);
				const schedule = {
					_meta: {},
					amount: customer[`amount${index}`],
					calendarCulture: calendarTypes.GREGORIAN,
					description: customer[`description${index}`],
					intervalCount: customer[`frequency${index}`],
					intervalType: customer[`intervalType${index}`],
					numberOfPayments: validators.number(untilValue)
						? parseInt(untilValue)
						: customer[`remainingPayments${index}`] || '',
					totalPayments: customer[`remainingPayments${index}`],
					scheduleName: customer[`scheduleName${index}`],
					startDate,
					useDefaultPaymentMethodOnly: true,
					allowInitialTransactionToDecline: startDate === today[calendarTypes.GREGORIAN],
				};

				this.mapScheduleUntil(schedule, untilValue);
				this.mapScheduleRemainingPayments(schedule, customer[`remainingPayments${index}`]);

				if (schedule.amount && schedule.startDate) {
					return schedule;
				}
			})
		);
	};

	mapScheduleUntil = (schedule, untilValue) => {
		schedule._meta.until = schedule.numberOfPayments ? until.PAYMENTS : until.NEVER;

		if (validators.date(untilValue)) {
			schedule._meta.until = until.ENDDATE;
			schedule.endDate = moment(untilValue, displayDateFormat, true).format(apiDateFormat);
		}
	};

	mapScheduleRemainingPayments = (schedule, remainingPayments) => {
		if (get(schedule, '_meta.until') === until.PAYMENTS && remainingPayments) {
			schedule.numberOfPayments = remainingPayments;
		}
	};

	savePaymentMethods = async (payments, customerId, response) => {
		try {
			for (const data of payments) {
				if (!data) continue;
				data.payment.customerId = customerId;
				response.numberOfRequests++;
				if (data.payment.tokenType === 'check') {
					await customerService.addCustomerCheckPaymentMethod(data.accountNumber, data.routing, data.payment);
				} else {
					await customerService.addCustomerCcPaymentMethod(data.cardNumber, data.exp, data.payment);
				}
			}
		} catch (e) {
			return e;
		}
	};

	createPaymentMethods = customer => {
		const maxIndex = Math.max(
			...Object.keys(customer)
				.filter(key => key.startsWith('paymentType'))
				.map(key => Number(key.replace('paymentType', '')))
		);

		return compact(
			times(maxIndex, i => {
				const index = i + 1;
				const paymentType = toLower(customer[`paymentType${index}`]);
				const data = {
					xName: toLower(paymentType) === 'cc' ? customer[`cardholderName${index}`] : customer[`accountName${index}`],
					setAsDefault: index === 1,
					tokenAlias: customer[`paymentName${index}`],
					tokenType: paymentType,
					token: customer[`token${index}`],
				};

				if (paymentType) {
					const exp = customer[`expDate${index}`];
					const cardNumber = customer[`cardNumber${index}`];
					const routing = customer[`routingNumber${index}`];
					const accountNumber = customer[`accountNumber${index}`];

					if (cardNumber) {
						data.token = cardNumber;
					}
					data.exp = exp;

					if (paymentType === 'check') {
						if (accountNumber) {
							delete data.token;
							data.token = accountNumber;
							data.routing = routing;
							return { accountNumber, routing, payment: data };
						} else {
							const checkTokenData = {
								tokenType: data.tokenType,
								xName: data.xName,
								token: data.token,
								routing: '1',
							};
							return { payment: checkTokenData };
						}
					}

					return { cardNumber, exp, payment: data };
				}
			})
		);
	};

	createCustomer = ({
		billingCity: BillCity,
		billingCompany: BillCompany,
		billingFirstName: BillFirstName,
		billingLastName: BillLastName,
		billingPhone: BillPhone,
		billingState: BillState,
		billingAddress: BillStreet,
		billingAddress2: BillStreet2,
		billingZip: BillZip,
		customerNotes: CustomerNotes,
		customerNumber: CustomerNumber,
		email: Email,
		...rest
	}) => {
		const customer = {
			BillCity,
			BillCompany,
			BillFirstName,
			BillLastName,
			BillPhone,
			BillState,
			BillStreet,
			BillStreet2,
			BillZip,
			CustomerNotes,
			CustomerNumber,
			Email,
			...this.createCustomerCustomFields({ ...rest }),
		};

		each(customer, (value, key) => {
			customer[key] = trim(value);
		});

		return customer;
	};

	createCustomerCustomFields = data => {
		return transform(data, (acc, value, key) => {
			const loweredKey = toLower(key);

			if (startsWith(loweredKey, 'custom') && !startsWith(loweredKey, 'customer')) {
				const fieldNumber = split(loweredKey, 'custom')[1];
				acc[`CustomerCustom${fieldNumber}`] = value;
			}
		});
	};
	renderPciNotice = () => {
		return (
			<div className="notes notes--primary spc--bottom--lrg" id="displayCloseMessage">
				<i className="icon" />
				<div>
					<p className="type--p2 type--p2--medium spc--bottom--sml--alt">Card Upload – Security Notice</p>
					<p className="type--p3 type--color--text--secondary">
						Cardknox has taken the steps to ensure data security for uploaded cards by meeting all applicable Payment
						Card Industry (PCI) compliance requirements. Payment Card Industry compliance requirements include the Data
						Security Standards (PCI DSS) and Point-to-Point Encryption Standards (P2PE). Cardknox is PCI Level 1
						Compliant. This compliance governs payment card handling, processing, transmission, storage, and disposal of
						cardholder data transactions within the Cardknox network. Third-parties who have access to cardholder data
						are responsible for complying with PCI Standards to ensure the protection of such data.
					</p>
				</div>
			</div>
		);
	};

	renderExportButton = () => {
		const { headers } = this.state;
		const data = cloneDeep(headers);
		delete data.customerLine;
		delete data.status;
		const csv = {};
		const columns = map(data, header => {
			return { key: header.title, name: header.title };
		});
		const fieldsToUpdate = [
			'Payment Type',
			'Payment Name',
			'Cardholder Name',
			'Card Number',
			'Token',
			'Exp Date',
			'Account Name',
			'Routing Number',
			'Account Number',
			'Schedule Name',
			'Description',
			'Every',
			'Frequency',
			'Amount',
			'Start Date',
			'Remaining Payments',
			'Until',
		];
		const fieldCount = {};

		const filteredColumns = reject(columns, column => includes(['Status', 'Line Number'], column.name));

		forEach(filteredColumns, column => {
			forEach(fieldsToUpdate, field => {
				if (column.key === field) {
					const count = get(fieldCount, field, 0) + 1;
					set(fieldCount, field, count);
					column.name += ` ${count}`;
					column.key += count;
				}
			});
		});

		return (
			<ExportComponent
				data={[csv]}
				columns={columns}
				visibleColumns={columns}
				exportType="transactions"
				showLoaderMethod={() => false}
				showDropdown={false}
				fetchExportData={{ current: exportService.mapUploadCustomerData }}
				hideTooltip={true}
				customLabel="Download"
				primaryButton={true}
			/>
		);
	};

	renderProcessDataButton = () => {
		const { isLoading } = this.props;
		const { data, fieldsWithErrors } = this.state;

		return (
			<div className="flex--primary flex--gap--sml--alt">
				<button className="btn btn--med btn--link btn--link--tertiary" onClick={this.handleCloseRequirements}>
					<i className="icon icon--sml icon--info--light"></i>
					<span>Requirements</span>
				</button>
				<button
					className="btn btn--med btn--primary"
					disabled={isLoading || isEmpty(data) || !isEmpty(fieldsWithErrors)}
					onClick={this.processData}
				>
					<i className="icon icon--sml icon--upload--white" />
					<span>Upload Data</span>
				</button>
			</div>
		);
	};

	onDrop = (acceptedFiles, rejectedFiles) => {
		if (rejectedFiles.length) {
			this.setState({
				errorMessages: ['Invalid file type'],
			});
			return false;
		}
		this.handleUpload(acceptedFiles);
	};

	hasPaymentMethod = (customer, fields) => {
		return some([1, 2, 3], paymentNumber => some(fields, field => !!get(customer, [field + paymentNumber, 'value'])));
	};

	validateEndDate = startDateMoment => endDate => {
		return moment(endDate, displayDateFormat, true).isAfter(startDateMoment);
	};

	validateScheduleInfo = (hasValue, scheduleFields, customer, hasPaymentMethod, ccFields) => {
		each(scheduleFields, field => {
			set(customer, [field, 'disabled'], !hasPaymentMethod);
		});

		if (!hasValue) {
			each(scheduleFields, field => {
				set(customer, [field, 'valid'], true);
			});
			return;
		}

		const untilKey = findKey(scheduleFields, key => startsWith(toLower(key), 'until'));
		const isUntilDate = validators.date(customer[untilKey]);

		if (isUntilDate) {
			const startDate = findKey(scheduleFields, key => startsWith(toLower(key), 'startdate'));
			const startDateMoment = moment(customer[startDate], displayDateFormat, true);

			customer[untilKey].validators.push(this.validateEndDate(startDateMoment));
		}

		each(scheduleFields, field => {
			set(customer, [field, 'valid'], this.isValid(customer, field));
		});

		if (hasValue && !hasPaymentMethod) {
			each(ccFields, item => {
				const field = item + 1;
				set(customer, [field, 'valid'], this.isValid(customer, field));
			});
		}
	};
	setPaymentMethodFieldDisabled = (customer, field, isPaymentTypeField, isEnabled, isToken) => {
		const isSharedField = ccFields.includes(field.slice(0, -1)) && checkFields.includes(field.slice(0, -1));
		let disabled;

		if (isSharedField) {
			disabled = false;
		} else if (isToken && startsWith(field, 'expDate')) {
			disabled = true;
		} else if (isPaymentTypeField) {
			disabled = false;
		} else {
			disabled = !isEnabled;
		}

		set(customer, `${field}.disabled`, disabled);
	};

	validatePaymentMethod = (customer, number) => {
		const paymentType = get(customer, `paymentType${number}.value`);
		const isCcPayment = toLower(paymentType) === 'cc';
		const isCheckPayment = toLower(paymentType) === 'check';
		const isToken = get(customer, `token${number}.value`);

		each(ccFields, item => {
			const field = item + number;
			const isPaymentTypeField = item === 'paymentType';
			this.setPaymentMethodFieldDisabled(customer, field, isPaymentTypeField, isCcPayment, isToken);
			customer[field].valid = isCcPayment ? this.isValid(customer, field) : true;
			if (isToken && startsWith(field, 'expDate')) {
				customer[field].valid = true;
			}
		});

		each(checkFields, item => {
			const field = `${item}${number}`;
			const isPaymentTypeField = item === 'paymentType';
			this.setPaymentMethodFieldDisabled(customer, field, isPaymentTypeField, isCheckPayment);
			customer[field].valid = isCheckPayment ? this.isValid(customer, field) : true;

			if (isToken && !some(fieldPrefixesToIgnoreWhenToken, start => startsWith(field, start))) {
				set(customer, `${field}.valid`, true);
				set(customer, `${field}.disabled`, true);
			}
		});

		// Additional validation for paymentType
		let isPaymentTypeValid = validators.paymentType(paymentType) || paymentType === '';
		if (!isCcPayment && !isCheckPayment) {
			isPaymentTypeValid = validators.paymentTypeOptions(paymentType, customer, `paymentType${number}`);
		}
		if (!isPaymentTypeValid) {
			customer[`paymentType${number}`].valid = false;
			customer[`paymentType${number}`].disabled = false;
		}
	};

	isValid = (form, fieldKey) => {
		const field = form[fieldKey];
		if (!field) return true;
		const value = isString(field.value) ? replace(field.value, /\s/g, '') : field.value;
		return overEvery(field.validators)(value, form, fieldKey);
	};

	handleUpload = async files => {
		if (isEmpty(files)) {
			this.setState(this.initialState);
			this.failedUploadData = [];
			this.props.handleBlockChange(false);
		} else {
			try {
				this.props.handleBlockChange(true);
				const { pageSize } = this.state;

				this.props.showLoader(true);

				const { data } = await parseCsv(files[0]);

				const noHeadersData = [];
				each(slice(data, 1), (customer, key) => {
					if (every(customer, field => field === '')) {
						return;
					} else {
						noHeadersData[key] = customer;
					}
				});

				const numberOfPages = Math.ceil(
					filter(noHeadersData, data => some(data, value => value !== '')).length / pageSize
				);

				const noCustomerLineHeaders = cloneDeep(columns);

				delete noCustomerLineHeaders.customerLine;
				const mappedData = compact(
					map(noHeadersData, customer => {
						return this.validateFields(customer);
					})
				);

				this.modifyData(mappedData, numberOfPages, pageSize, files[0].name);
				this.props.handleBlockChange(false);
			} catch (e) {
				this.setState(this.initialState);
				this.failedUploadData = [];
				this.props.handleBlockChange(false);
				this.props.handleError(e);
			} finally {
				this.props.showLoader(false);
			}
		}
	};

	validateFields = customer => {
		const noCustomerLineHeaders = cloneDeep(columns);
		delete noCustomerLineHeaders.customerLine;
		delete noCustomerLineHeaders.status;
		if (!isEmpty(customer)) {
			let index = 0;
			return transform(
				noCustomerLineHeaders,
				(acc, { validators }, headerKey) => {
					const validateBilling = some(customerRequiredFields, item => item === headerKey);
					acc[headerKey] = createField(customer[index], ...validators);
					acc[headerKey].valid = validateBilling || !!customer[index] ? this.isValid(acc, headerKey) : true;
					index++;
				},
				{}
			);
		}
	};

	renderHeaders = () => {
		const { headers } = this.state;
		return (
			<React.Fragment>
				{map(headers, header => {
					if (!header.title) return;
					return (
						<th
							key={`${header.group.title}.${header.title}`}
							className={`datatooltip--v--bottom ${header.group.className}`}
						>
							{header.title}{' '}
							{header.tooltip ? (
								<i
									className="icon icon--nano icon--info spc--left--tny cursor--pointer"
									data-tooltip={header.tooltip}
								/>
							) : null}
						</th>
					);
				})}
			</React.Fragment>
		);
	};

	createScheduleFields = (baseFields, number) => {
		return map(baseFields, i => i + number);
	};

	scheduleHasValue = (customer, scheduleFields) => {
		const scheduleNameField = scheduleFields[0];
		if (!customer[scheduleNameField].value) {
			return some(scheduleFields, item => !!customer[item].value && !startsWith(item, 'scheduleName'));
		}
		return some(scheduleFields, item => !!customer[item].value);
	};

	validateAndSetupFields = (customer, customerKey, fileName, mappedData) => {
		if (!fileName) {
			this.validateFields(customer);
		}
		if (!isEmpty(customer)) {
			const scheduleFields = map([1, 2, 3], i => this.createScheduleFields(baseScheduleFields, i));
			const hasPaymentMethod = this.hasPaymentMethod(customer, concat(ccFields, checkFields));
			if (hasPaymentMethod) {
				this.validatePaymentMethods(customer);
			} else {
				forEach([...ccFields, ...checkFields], field => {
					forEach([1, 2, 3], number => {
						set(customer, `${field}${number}.valid`, true);
						set(customer, `${field}${number}.disabled`, false);
						set(customer, `${field}${number}.dirty`, false);
					});
				});
			}
			this.validateBillingAndScheduleInfo(
				customer,
				scheduleFields,
				hasPaymentMethod,
				ccFields,
				mappedData,
				customerKey
			);
		}
	};

	validateBillingInfo = (customer, mappedData) => {
		const billingFields = ['billingFirstName', 'billingLastName', 'billingCompany'];
		const hasPaymentMethod = this.hasPaymentMethod(customer, concat(ccFields, checkFields));
		// Find other customer with the same customer number and at least one billing field filled in
		const otherCustomer = find(
			mappedData,
			c =>
				c.customerNumber.value === customer.customerNumber.value &&
				c !== customer &&
				some(billingFields, item => c[item].value)
		);

		// Check if the other customer exists
		const otherCustomerExists = !!otherCustomer;

		// Check if at least one billing field is filled
		const atLeastOneFieldFilled = some(billingFields, field => customer[field].value);

		// Loop through all billing fields
		forEach(billingFields, fieldKey => {
			// If the other customer exists or if at least one billing field is filled in, set its validity to true
			if (otherCustomerExists || atLeastOneFieldFilled) {
				customer[fieldKey].valid = true;
			} else {
				// Otherwise, set its validity to false
				customer[fieldKey].valid = customer[fieldKey].value !== '';
			}
		});
		// Check if the customer doesn't have a payment method, other customer exists and there's no at least one field filled
		if (!hasPaymentMethod && otherCustomerExists && !atLeastOneFieldFilled) {
			// Set the first ccFields for that customer as invalid
			customer[`${head(ccFields)}1`].valid = false;
		}
	};

	validatePaymentMethods = customer => {
		forEach(Array(3), (_, i) => {
			this.validatePaymentMethod(customer, i + 1);
		});
	};

	validateBillingAndScheduleInfo = (customer, scheduleFields, hasPaymentMethod, ccFields, mappedData) => {
		// Validate billing info
		this.validateBillingInfo(customer, mappedData);

		// Loop through schedule fields
		forEach(Array(3), (_, i) => {
			// Check if the schedule has value
			const scheduleHasValue = this.scheduleHasValue(customer, scheduleFields[i]);

			// If schedule has value, validate schedule info
			if (scheduleHasValue) {
				this.validateScheduleInfo(scheduleHasValue, scheduleFields[i], customer, hasPaymentMethod, ccFields);
			}
		});
	};

	checkCustomerRequiredFields = (customer, customerRequiredFields, customerKey, pageSize) => {
		if (every(customerRequiredFields, item => !get(customer, `${item}.value`))) {
			const lineNumber = customerKey + 1; // Calculate the line number
			const pageNumber = Math.floor(customerKey / pageSize) + 1; // Calculate the page number
			return {
				message: `The Billing First Name, Last Name or Company is required on customer line ${lineNumber} on page ${pageNumber}.`,
				lineNumber: lineNumber,
				pageNumber: pageNumber,
			};
		}
	};
	createErrorMessage = (field, fieldKey, customerKey, pageSize, columns) => {
		if (!field.valid) {
			const lineNumber = customerKey + 1; // Calculate the line number
			const pageNumber = Math.floor(customerKey / pageSize) + 1; // Calculate the page number
			return {
				message: `The ${columns[fieldKey].title} field on customer line ${lineNumber} on page ${pageNumber} is ${
					!field.value ? 'required' : 'not valid'
				}.`,
				lineNumber: lineNumber,
				pageNumber: pageNumber,
			};
		}
	};
	createFieldsWithErrors = (mappedData, pageSize) => {
		return flatMap(mappedData, (customer, customerKey) => {
			this.validateBillingInfo(customer, mappedData);
			const errors = compact(
				map(customer, (field, fieldKey) => {
					if (!customer[fieldKey].valid) {
						return this.createErrorMessage(field, fieldKey, customerKey, pageSize, columns);
					}
				})
			);
			const customerRequiredFieldsError = this.checkCustomerRequiredFields(
				customer,
				customerRequiredFields,
				customerKey,
				pageSize
			);
			if (customerRequiredFieldsError) {
				errors.unshift(customerRequiredFieldsError);
			}
			return errors;
		});
	};

	createNewData = (mappedData, paginatedData, fieldsWithErrors, fileName, numberOfPages) => {
		let newData = {
			data: mappedData,
			paginatedData,
			fieldsWithErrors,
			errorMessages: fieldsWithErrors,
			page: this.state.page || 1,
			documentUploaded: true,
			uniqueErrorMessages: uniqBy(fieldsWithErrors, ({ lineNumber }) => lineNumber),
		};

		if (fileName && numberOfPages) {
			newData.fileName = fileName;
			newData.numberOfPages = numberOfPages;
		} else {
			newData.fileName = this.state.fileName;
			newData.numberOfPages = this.state.numberOfPages;
		}

		return newData;
	};

	handleEmptyPaginatedData = paginatedData => {
		if (paginatedData.length === 0) {
			this.setState(this.initialState);
			this.props.handleBlockChange(false);
			const addNotification = get(this.notificationRef, 'current.addNotification', noop);
			addNotification({
				message: "The imported file doesn't have a customer",
			});
			return true;
		}
		return false;
	};
	modifyData = (mappedData, numberOfPages = null, pageSize = null, fileName = null) => {
		if (!pageSize) {
			pageSize = this.state.pageSize;
		}
		let customerMap = {};
		const paymentAndScheduleFields = [...baseScheduleFields, ...ccFields, ...checkFields, 'customerNumber'];
		each(mappedData, (customer, customerKey) => {
			this.validateAndSetupFields(customer, customerKey, fileName, mappedData);
			let customerIdentifier =
				customer.customerNumber.value ||
				customer.billingFirstName.value + customer.billingLastName.value + customer.billingCompany.value;
			if (customerMap[customerIdentifier]) {
				let existingCustomer = customerMap[customerIdentifier];
				let isDuplicate = false;
				for (let key in customer) {
					let keyWithoutNumberPrefix = key.replace(/\d+$/, ''); // Remove number prefix from key
					if (
						!paymentAndScheduleFields.includes(keyWithoutNumberPrefix) &&
						customer[key].value &&
						existingCustomer[key].value // Check if both fields are filled in
					) {
						customer.customerNumber.valid = false; // Set valid to false
						isDuplicate = true;
						break;
					}
				}
				if (!isDuplicate) {
					customer.customerNumber.valid = true; // Set valid to true if not a duplicate
				}
			} else {
				customerMap[customerIdentifier] = customer;
			}
		});
		const fieldsWithErrors = this.createFieldsWithErrors(mappedData, pageSize);
		const paginatedData = !isEmpty(this.state.paginatedData)
			? [...this.state.paginatedData]
			: slice(mappedData, 0, pageSize);
		const newData = this.createNewData(mappedData, paginatedData, fieldsWithErrors, fileName, numberOfPages);
		if (!this.handleEmptyPaginatedData(paginatedData)) {
			this.setState(newData);
		}
	};
	renderCustomers = () => {
		const { paginatedData, headers, data } = this.state;
		const displayHeaders = cloneDeep(headers);
		delete displayHeaders.lineNumber;
		delete displayHeaders.status;

		return (
			<tbody>
				{map(paginatedData, (customer, customerKey) => {
					let invalidFields = [];

					const index = findIndex(data, i => i === customer);

					const customers = map(customer, ({ value, valid, isValid, disabled }, fieldKey) => {
						let tooltip = null;
						const isInvalid = !valid && !isValid;

						if (isInvalid) {
							const fieldTitle = get(headers, `${fieldKey}.title`, '');
							tooltip = `This field (${fieldTitle}) is ${value ? 'invalid' : 'required'}.`;
							invalidFields = concat(invalidFields, fieldTitle);
						}
						const fieldClassName = get(headers, `${fieldKey}.group.className`, '');
						return (
							<td key={fieldKey} className={`${!valid ? ' type--color--error' : ''} color--${fieldClassName}`}>
								<div data-tooltip={tooltip} className="fullwidth">
									<input
										className={`input input--sml ${isInvalid ? 'is-invalid type--color--error' : ''}`}
										onChange={e => this.handleChange(e, customer, fieldKey, index)}
										value={value}
										style={{ width: 'auto' }}
										disabled={disabled}
										name={get(headers, `${fieldKey}.title`, '')}
									/>
								</div>
							</td>
						);
					});

					const invalidFieldsTooltip =
						invalidFields.length > 5
							? 'Some fields are invalid'
							: `The following field(s) are invalid: ${invalidFields.join(', ')}`;
					const tableDataProps = {};
					if (!isEmpty(invalidFields)) {
						tableDataProps['data-tooltip'] = invalidFieldsTooltip;
					}
					this.customerRowRefs[index + 1] = createRef();
					return (
						<tr key={index + customerKey} ref={this.customerRowRefs[index + 1]}>
							<td className="status-cell">
								<div className="flex--primary flex--gap--sml flex--nowrap">
									<span className="type--p3 type--color--text--light" data-linenumber={index + 1}>
										{index + 1}.
									</span>
									<div className="datatooltip--right" {...tableDataProps}>
										{invalidFields.length === 1 ? (
											<span className="badge badge--start-icon badge--error">1 problem</span>
										) : invalidFields.length > 1 ? (
											<span className="badge badge--start-icon badge--error">{`${size(invalidFields)} problems`}</span>
										) : (
											<span className="badge badge--start-icon badge--success">Complete</span>
										)}
									</div>
								</div>
							</td>
							{customers}
						</tr>
					);
				})}
			</tbody>
		);
	};

	handleChange = ({ target: { value } }, customer, fieldKey, index) => {
		const data = [...this.state.data];
		if (data[index] && data[index][fieldKey]) {
			data[index][fieldKey].value = value;
			data[index][fieldKey].valid = this.isValid(data[index], fieldKey);
			data[index][fieldKey].dirty = true;
		}
		if (this.customerRowRefs[this.state.currentLineNumber]) {
			const rowRef = this.customerRowRefs[this.state.currentLineNumber].current;
			if (rowRef) {
				rowRef.classList.remove('is-highlighted');
			}
		}

		this.modifyData(data);
	};

	setStateAsync = async newState => {
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};
	processRemovals = async () => {
		await this.setStateAsync(prevState => {
			const { data, customersToRemove, page, pageSize } = prevState;

			const updatedData = filter(data, (customer, index) => {
				// Keep only the customers whose indexes are not in the customersToRemove array
				return !includes(customersToRemove, index);
			});

			// Calculate the new numberOfPages based on the updated data and pageSize
			const updatedNumberOfPages = Math.ceil(updatedData.length / pageSize);

			// Determine the new page value
			let updatedPage = page;
			if (updatedPage > updatedNumberOfPages) {
				updatedPage = updatedNumberOfPages;
			}

			// Calculate the new paginatedData
			const startIndex = (updatedPage - 1) * pageSize;
			const endIndex = startIndex + pageSize;
			const updatedPaginatedData = slice(updatedData, startIndex, endIndex);

			return {
				data: updatedData,
				customersToRemove: [], // Clear the tracked removals
				numberOfPages: updatedNumberOfPages,
				page: updatedPage,
				paginatedData: updatedPaginatedData,
			};
		});
	};
	removeCustomer = async customerIndex => {
		await this.setStateAsync(prevState => ({
			customersToRemove: [...prevState.customersToRemove, customerIndex],
		}));
	};

	handleSwitchPage = (page, type) => {
		const { data, pageSize } = this.state;
		if (type === 'data') {
			const paginatedData = slice(data, (page - 1) * pageSize, page * pageSize);
			this.setState({
				page,
				paginatedData,
			});
		}
	};

	handleDelete = () => {
		this.setState({
			modal: {
				name: modalNames.confirmAction,
				data: {
					loadingMessage: 'Deleting Uploaded File',
					question: `Are you sure you want to delete the uploaded file?`,
					onConfirm: () => this.handleUpload([]),
				},
			},
		});
	};

	onModalClose = () => this.setState({ modal: null });
	closeSuccessModal = () => this.setState({ isOpenSuccessModal: false });

	renderHeader = () => (
		<header className="header">
			<div className="header__breadcrumbs">
				<Link className="item" to="/customers">
					Customers
				</Link>
				<div className="item">Upload Customers and Schedules</div>
			</div>
			<div className="header__menu">
				<UserAccountPanel />
			</div>
		</header>
	);

	scrollToError = (isUp = false) => {
		const { errorMessages, uniqueErrorMessages } = this.state;
		let { currentLineNumber, isInitialErrorPaginate } = this.state;
		let previousLineNumber = currentLineNumber;
		const totalErrors = uniqueErrorMessages.length;
		let activeIndex = 0;
		if (isInitialErrorPaginate) {
			currentLineNumber = head(errorMessages).lineNumber;
			isInitialErrorPaginate = false;
		} else {
			if (!isUp) {
				// Find the index of the current line number in uniqueErrorMessages
				const currentIndex = findIndex(uniqueErrorMessages, { lineNumber: currentLineNumber });

				// Calculate the next index (circular)
				const nextIndex = (currentIndex + 1) % totalErrors;
				activeIndex = nextIndex;
				// Get the next line number if it exists
				const nextErrorMessage = uniqueErrorMessages[nextIndex];
				if (nextErrorMessage) {
					currentLineNumber = nextErrorMessage.lineNumber;
				}
			} else {
				// Find the index of the current line number in uniqueErrorMessages
				const currentIndex = findIndex(uniqueErrorMessages, { lineNumber: currentLineNumber });

				// Calculate the previous index (circular)
				const previousIndex = (currentIndex - 1 + totalErrors) % totalErrors;
				activeIndex = previousIndex;

				// Get the previous line number if it exists
				const previousErrorMessage = uniqueErrorMessages[previousIndex];
				if (previousErrorMessage) {
					currentLineNumber = previousErrorMessage.lineNumber;
				}
			}
		}

		const errorContent = find(uniqueErrorMessages, message => message.lineNumber === currentLineNumber);
		const previousErrorContent = find(uniqueErrorMessages, message => message.lineNumber === previousLineNumber);

		if (errorContent) {
			this.handleSwitchPage(errorContent.pageNumber, 'data');

			setTimeout(() => {
				const targetRow = this.customerRowRefs[errorContent.lineNumber]
					? this.customerRowRefs[errorContent.lineNumber].current
					: null;

				if (targetRow) {
					// Scroll to the target span
					targetRow.scrollIntoView({
						behavior: 'smooth',
						block: activeIndex < 7 ? 'end' : 'start',
					});

					// Reset styles for the previous target span
					if (
						!this.state.isInitialErrorPaginate &&
						previousErrorContent &&
						previousErrorContent.lineNumber !== currentLineNumber
					) {
						const previousElement = this.customerRowRefs[previousErrorContent.lineNumber]
							? this.customerRowRefs[previousErrorContent.lineNumber].current
							: null;
						if (previousElement) {
							// Check if the element has the 'is-highlighted' class before removing it
							if (previousElement.classList.contains('is-highlighted')) {
								previousElement.classList.toggle('is-highlighted');
							}
						}
					}

					// Apply styles to the current target span
					targetRow.classList.toggle('is-highlighted');
				}
			}, 100);

			this.setState({ currentLineNumber, isInitialErrorPaginate, currentErrorPage: activeIndex + 1 });
		}
	};
	renderSuccess = () => {
		const { uniqueErrorMessages, documentUploaded } = this.state;

		if (isEmpty(uniqueErrorMessages) && documentUploaded) {
			return (
				<div className={`customer__upload__validation success spc--bottom--lrg`}>
					<div className="flex--primary flex--gap--sml--alt">
						<div className="customer__upload__validation__icon">
							<i className="icon icon--sml icon--info--white" />
						</div>
						<p className="type--p1 type--color--white">
							<span className="type--p1--medium s">Your file is ready to upload.</span> Your file does not contain any
							errors.
						</p>
					</div>
				</div>
			);
		}
	};

	renderErrors = () => {
		const { uniqueErrorMessages, currentErrorPage } = this.state;

		if (!isEmpty(uniqueErrorMessages)) {
			const totalErrors = uniqueErrorMessages.length;
			return (
				<div className={`customer__upload__validation ${!totalErrors ? 'success' : ''} spc--bottom--lrg`}>
					<div className="flex--primary flex--gap--sml--alt">
						<div className="customer__upload__validation__icon">
							<i className="icon icon--sml icon--info--white" />
						</div>
						<p className="type--p1 type--color--white">
							<span className="type--p1--medium">
								Unable to upload file, fix {totalErrors === 1 ? 'error' : 'errors'} below.
							</span>{' '}
							{`Your file contains ${totalErrors} ${totalErrors === 1 ? 'error' : 'errors'}`}
						</p>
					</div>
					<div className="flex--primary flex--gap--sml--alt">
						<span className="type--p1 type--p1--medium">{`${currentErrorPage || 0 + 1}/${totalErrors}`}</span>
						<div className="flex--primary flex--gap--sml">
							<button
								className="btn btn--action btn--action--secondary"
								disabled={currentErrorPage === 1}
								onClick={() => this.scrollToError(true)}
							>
								<i className="icon icon--sml icon--chevron--top" />
							</button>
							<button
								className="btn btn--action btn--action--secondary"
								disabled={currentErrorPage === totalErrors}
								onClick={() => this.scrollToError()}
							>
								<i className="icon icon--sml icon--chevron--down" />
							</button>
						</div>
					</div>
				</div>
			);
		} else {
			return this.renderSuccess();
		}
	};
	renderPagination = () => {
		const { numberOfPages, page } = this.state;
		const pages = range(1, numberOfPages + 1);
		const pageChunks = chunk(pages, 10);
		const currentPageChunkIndex = Math.floor((page - 1) / 10);
		return numberOfPages > 1 ? (
			<div className="table--secondary__footer">
				<ul className="table--secondary__footer__pagination">
					<li>
						<button disabled={currentPageChunkIndex === 0} onClick={() => this.handleSwitchPage(page - 10, 'data')}>
							<i className="icon icon--sml icon--first-page"></i>
						</button>
					</li>
					<li>
						<button disabled={page === 1} onClick={() => this.handleSwitchPage(page - 1, 'data')}>
							<i className="icon icon--sml icon--chevron--left"></i>
						</button>
					</li>
					<li className="page">{page}</li>
					<li>
						<button disabled={page === numberOfPages} onClick={() => this.handleSwitchPage(page + 1, 'data')}>
							<i className="icon icon--sml icon--chevron--right"></i>
						</button>
					</li>
					<li>
						<button
							disabled={currentPageChunkIndex === size(pageChunks) - 1}
							onClick={() => this.handleSwitchPage(page + 10, 'data')}
						>
							<i className="icon icon--sml icon--last-page"></i>
						</button>
					</li>
				</ul>
			</div>
		) : null;
	};

	renderTable = () => {
		const { numberOfPages } = this.state;
		if (numberOfPages === 0) return;
		return (
			<div className="table--customer-upload">
				<table className="table table--primary">
					<thead>
						<tr>
							<th className="status-cell"></th>
							{map(groupBy(this.state.headers, 'group.title'), items => {
								const { className, title } = items[0].group;
								return (
									<th
										colSpan={title === 'Customer Information' ? items.length - 2 : items.length}
										key={title}
										className={className}
									>
										{title}
									</th>
								);
							})}
						</tr>
						<tr>{this.renderHeaders()}</tr>
					</thead>
					{numberOfPages > 0 ? (
						this.renderCustomers()
					) : (
						<tbody>
							{times(19, i => (
								<tr key={i}>
									{map(this.state.headers, (header, key) => (
										<td key={key} className={`color--${header.group.className}`}>
											-
										</td>
									))}
								</tr>
							))}
						</tbody>
					)}
				</table>
			</div>
		);
	};

	renderRemoveModule = () => {
		const { numberOfPages, fileName } = this.state;

		return numberOfPages > 0 ? (
			<div className="spc--bottom--lrg spc--top--xlrg">
				<div className="flex--tertiary flex--gap--med spc--bottom--med">
					<h3>Uploaded Customer</h3>
					{this.renderProcessDataButton()}
				</div>
				<div className="flex--primary">
					<p className="type--p2 type--color--text--light">
						Uploaded File: <span className="type--color--text type--wgt--medium">{fileName}</span>
					</p>
					<button
						data-tooltip="Delete"
						className="btn btn--link spc--left--xsml datatooltip--auto"
						onClick={() => this.handleUpload([])}
					>
						<i className="icon icon--sml icon--delete--light" />
					</button>
				</div>
			</div>
		) : null;
	};

	renderButtonsModule = documentUploaded => (
		<Fragment>
			{documentUploaded ? null : (
				<Fragment>
					<div className="flex--tertiary flex--gap--med spc--bottom--lrg spc--top--xlrg">
						<h3>Download Template File</h3>
						<div className="flex--primary flex--gap--sml--alt">
							<button className="btn btn--med btn--link btn--link--tertiary" onClick={this.handleCloseRequirements}>
								<i className="icon icon--sml icon--info--light"></i>
								<span>Instructions</span>
							</button>
							{this.renderExportButton()}
						</div>
					</div>
					{this.renderPciNotice()}
				</Fragment>
			)}
		</Fragment>
	);

	renderUploadDocumentDropzone = () => {
		if (this.state.documentUploaded) return;
		return (
			<Dropzone onDrop={this.onDrop} accept={'.csv'}>
				{({ getRootProps, getInputProps, isDragActive }) => {
					return (
						<div className="card">
							<div className="card__header">
								<h5>Upload File</h5>
							</div>
							<div className="card__body">
								<div
									{...getRootProps()}
									className={classNames('upload', {
										draggOver: isDragActive,
									})}
								>
									<input {...getInputProps()} />
									{isDragActive ? (
										<Fragment>
											<div className="upload__icon"></div>
											<div className="upload__text">
												<p className="type--p3 type--p3--medium spc--bottom--tny">Drop file here</p>
											</div>
										</Fragment>
									) : (
										<Fragment>
											<div className="upload__icon"></div>
											<div className="upload__text">
												<p className="type--p3 type--p3--medium spc--bottom--tny">Click to upload or drag and drop</p>
											</div>
										</Fragment>
									)}
								</div>
							</div>
						</div>
					);
				}}
			</Dropzone>
		);
	};
	handleCloseRequirements = e => {
		e.preventDefault();
		this.setState({ showRequirements: !this.state.showRequirements });
	};
	onCloseUploadModal = () => {
		this.failedUploadData = [];
		this.setState(this.initialState);
	};
	render() {
		const {
			modal,
			progress,
			documentUploaded,
			isOpenSuccessModal,
			mappedErrors,
			failedResponses,
			showRequirements,
		} = this.state;
		const isUploadModalOpen = progress.isUploading || progress.errored || !isEmpty(mappedErrors);
		const showSuccessModal = isOpenSuccessModal && !progress.errored;
		return (
			<React.Fragment>
				{showRequirements && <Requirements handleClose={this.handleCloseRequirements} />}

				<SuccessModal isOpen={showSuccessModal} onClose={this.closeSuccessModal} />
				<ActionsModal modal={modal} onModalClose={this.onModalClose} isLoading={this.props.isLoading} />
				<UploadModal
					isOpen={isUploadModalOpen}
					onClose={this.onCloseUploadModal}
					progress={progress}
					mappedErrors={mappedErrors}
					failedResponses={failedResponses}
				/>
				<Notification ref={this.notificationRef} />
				{this.renderHeader()}
				<div className={`l--content l--content--centered ${documentUploaded ? 'customer-upload' : ''}`}>
					{this.renderRemoveModule()}
					{this.renderErrors()}
					{this.renderButtonsModule(documentUploaded)}
					{this.renderTable()}
					{this.renderPagination()}
					{this.renderUploadDocumentDropzone()}
				</div>
			</React.Fragment>
		);
	}
}

UploadCustomerPreview.propTypes = {
	isLoading: bool,
	showLoader: func.isRequired,
	handleError: func.isRequired,
	makePendingRequest: func.isRequired,
	handleBlockChange: func.isRequired,
	tabs: array.isRequired,
};

export default withBlock(withCancelable(withError(withLoader(UploadCustomerPreview))));
