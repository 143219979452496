export const generalErrorMessages = {
	billCity: 'City is required',
	billCompany: 'Company is required',
	billCountry: 'Country is required',
	billFirstName: 'First name is required',
	billLastName: 'Last name is required',
	billMiddleName: 'Middle name is required',
	mobilePhoneNumber: 'Mobile Phone number is required',
	billName: 'Bill Name is required',
	phoneNumber: 'Phone number is required',
	billState: 'State is required',
	billStreet: 'Address is required',
	billStreet2: 'Address 2 is required',
	billZip: 'ZIP is required',
	customerNumber: 'Customer number is required',
	defaultPaymentMethodId: 'Default payment method is required',
	email: 'Email is required',
	fax: 'Fax is required',
	customerNotes: 'Note is required',
};

export const scheduleErrorMessages = {
	invoice: 'Invoice is required',
	scheduleName: 'Schedule name is required',
	description: 'Description is required',
};

export const paymentErrorMessages = {
	cardNumber: 'Please enter Card number',
	invalidCardNumber: 'Please enter a valid Card number',
	expDate: 'Please enter Exp Date',
	invalidExpDate: 'Exp Date is not valid',
	accountType: 'Please select an Account Type',
	routingNumber: 'Please enter Routing number',
	accountNumber: 'Please enter Account number',
	invalidAccountNumber: 'Please enter a valid Account number',
	accountName: 'Please enter Account name',
	address: 'Please enter Address',
	zip: 'Please enter Zip',
};

export const untilTypes = {
	NEVER: 'never',
	PAYMENTS: 'payments',
	ENDDATE: 'endDate',
};

export const requestKeys = {
	FETCH: 'fetch',
	SAVE: 'save',
	REQUIRED: 'required',
	KVAAS: 'kvaas',
	FLAGS: 'flags',
	CANADIAN: 'canadian',
	TOKEN: 'token',
	REMOVE: 'remove',
	DEACTIVATE: 'deactivate',
};

export const validationSections = {
	GENERAL: 'general',
	PAYMENTS: 'payments',
	SCHEDULES: 'schedules',
	TRANSACTIONS: 'transactions',
};
