import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { toLower, round, get } from 'lodash';
import FormGroupPreview from 'common/components/form-group-preview/FormGroupPreview';

const CustomerSchedulePreview = ({
	schedule,
	customDisplayLabels,
	customFields,
	currency,
	convenienceFees,
	convenienceFeeEnabled,
	paymentMethod,
	children,
}) => {
	const [isScheduleExpanded, setIsScheduleExpanded] = useState(true);
	const [isCustomExpanded, setIsCustomExpanded] = useState(true);

	const toggleSection = section => {
		if (section === 'schedule') {
			setIsScheduleExpanded(!isScheduleExpanded);
		} else if (section === 'custom') {
			setIsCustomExpanded(!isCustomExpanded);
		}
	};

	const descriptionLabel = get(customDisplayLabels, 'description', 'Description');
	const invoiceLabel = get(customDisplayLabels, 'invoice', 'Invoice');
	const amountLabel = get(customDisplayLabels, 'amount', 'Amount');

	const roundedAmount = round(get(schedule, 'amount', 0), 2);
	const convenienceCustomKey = toLower(get(convenienceFees, 'convenienceCustomKey', ''));
	const roundedConvenienceFee = round(get(schedule, 'convenienceFee', get(schedule, convenienceCustomKey, 0)), 2);
	const paymentsLabel = schedule.totalPayments > 1 ? ' payments' : ' payment';

	return (
		<Fragment>
			<button className="info-panel__heading__wrapper is-expandable" onClick={() => toggleSection('schedule')}>
				<div className="flex--primary flex--gap--med">
					{children}
					<h6 className="info-panel__heading">{get(schedule, 'scheduleName', '---')}</h6>
					<div className={`badge badge--${schedule.isActive ? 'success' : 'default'} flex--no-shrink`}>
						{schedule.isActive ? 'Active' : 'Inactive'}
					</div>
				</div>
				<div className="flex--primary flex--gap--med">
					<i className={`icon icon--sml icon--chevron--${isScheduleExpanded ? 'top' : 'down'}--primary`}></i>
				</div>
			</button>
			{isScheduleExpanded && (
				<Fragment>
					<div className="info-panel__section">
						<FormGroupPreview label={amountLabel} value={`${currency}${roundedAmount}` || '---'} />
						{convenienceFeeEnabled && convenienceFees && (
							<FormGroupPreview
								label="Electronic Transfer fee"
								value={`${currency}${roundedConvenienceFee}` || '---'}
							/>
						)}
						<FormGroupPreview label="Payment Method" value={paymentMethod} />
						<FormGroupPreview label={descriptionLabel} value={get(schedule, 'description', '---')} />
						<FormGroupPreview label={invoiceLabel} value={get(schedule, 'invoice', '---')} />
						<FormGroupPreview
							label="Frequency"
							value={`Every ${get(schedule, 'intervalCount', 1)} ${get(schedule, 'intervalType', 'time')}${
								get(schedule, 'intervalCount') > 1 ? 's' : ''
							}`}
						/>
						<FormGroupPreview label="Start Date" value={get(schedule, 'startDate', '---')} />
						<FormGroupPreview
							label={`${toLower(get(schedule, '_meta.until')) === 'date' ? 'End Date' : 'End'}`}
							value={
								toLower(get(schedule, '_meta.until')) === 'date'
									? get(schedule, 'endDate', 'Indefinite')
									: `${schedule.totalPayments || 'Indefinite'}${paymentsLabel}`
							}
						/>
					</div>
					<button className="info-panel__heading__wrapper is-expandable" onClick={() => toggleSection('custom')}>
						<h6 className="info-panel__heading">Custom Fields</h6>
						<i className={`icon icon--sml icon--chevron--${isCustomExpanded ? 'top' : 'down'}--primary`}></i>
					</button>
					<div className="info-panel__section">
						{isCustomExpanded && <div className="spc--top--sml--alt">{customFields}</div>}
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

CustomerSchedulePreview.propTypes = {
	schedule: PropTypes.object,
	customDisplayLabels: PropTypes.object,
	customFields: PropTypes.array,
	currency: PropTypes.string,
	convenienceFees: PropTypes.object,
	convenienceFeeEnabled: PropTypes.bool,
	paymentMethod: PropTypes.string,
	children: PropTypes.node,
};

export default CustomerSchedulePreview;
